/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import RootLayout from './layouts/RootLayout';
const ProtectedRoute = (props) => {
  const navigate = useNavigate();
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const checkUserToken = () => {
    const userToken = localStorage.getItem('userAuthToken');
    if (!userToken || userToken === 'undefined') {
      setIsLoggedIn(false);
      localStorage.removeItem('locationIp');
      return navigate('/seva-login');
    } else {
      return setIsLoggedIn(true);
    }
  };
  useEffect(() => {
    checkUserToken();
  }, [isLoggedIn]);

  return (
    <React.Fragment>
      {isLoggedIn ? <RootLayout>{props.children}</RootLayout> : null}
    </React.Fragment>
  );
};
export default ProtectedRoute;
