import * as React from 'react';
import { useLocation } from 'react-router';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import TicketCharter from '../Ticket-Notes/TicketCharter';
import ApplicantDetails from './ApplicantDetails';
import Logs from './Logs';

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <div>{children}</div>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const ApplicationTabs = ({ ticketNo, isView, task }) => {
  const [value, setValue] = useState(0);

  console.log('value------------------>', value);
  const user = useSelector((state) => state.user);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{ borderTop: 1, borderColor: 'divider' }}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="scrollable auto tabs example"
          variant="scrollable"
          scrollButtons="auto"
          textColor="primary"
          indicatorColor="primary"
        >
          <Tab
            label="Notes/Attachments"
            className="font-bold text-gray-700 m-4"
            {...a11yProps(0)}
          />

          <Tab
            label="Applicant Details"
            className="font-bold text-gray-700 m-4"
            {...a11yProps(1)}
          />

          <Tab
            label="Logs"
            className="font-bold text-gray-700 m-4"
            {...a11yProps(2)}
          />

          {/* <Tab
            label=" Approval Notes"
            className="font-bold text-gray-700 m-4"
            {...a11yProps(2)}
          />

          <Tab
            label="Rejected Notes"
            className="font-bold text-gray-700 m-4"
            {...a11yProps(3)}
          />
          <Tab
            label="Ticket Resolution Notes"
            className="font-bold text-gray-700 m-4"
            {...a11yProps(4)}
          /> */}
        </Tabs>
      </Box>
      <Box sx={{ borderTop: 1, borderColor: 'divider' }}>
        <TabPanel value={value} index={0}>
          <TicketCharter ticketNo={ticketNo} isView={isView} tabValue={value} />
        </TabPanel>

        <TabPanel value={value} index={1}>
          <ApplicantDetails task={task} />
        </TabPanel>
        <TabPanel value={value} index={2}>
          <Logs ticketNo={ticketNo} />
        </TabPanel>

        {/* <TabPanel value={value} index={2}>
          <TicketCharter
            ticketNo={ticketNo}
            noteStatus="approved"
            isView={isView}
            tabValue={value}
          />
        </TabPanel>
        <TabPanel value={value} index={3}>
          <TicketCharter
            ticketNo={ticketNo}
            noteStatus="rejected"
            isView={isView}
            tabValue={value}
          />
        </TabPanel>
        <TabPanel value={value} index={4}>
          <TicketCharter
            ticketNo={ticketNo}
            noteStatus="resolved"
            isView={isView}
            tabValue={value}
          />
        </TabPanel> */}
      </Box>
    </Box>
  );
};

export default ApplicationTabs;
