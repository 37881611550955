import React from "react";

const CheckboxField = ({
  fieldId,
  fieldName,
  fieldLabel,
  register,
  onChange,
  onBlur,
  className = 'form-checkbox h-5 w-5 text-blue-600 rounded-md disabled:bg-gray-200',
  errors,
  disabled,
  value,
  children,
}) => {
  return (
    <div className="mb-4 flex items-center">
      <input
        id={fieldName}
        name={fieldName}
        type="checkbox"
        {...register(fieldName, {
          onChange: (e) => {
            const isChecked = e.target.checked;
            if (onChange) onChange(isChecked);
          },
          onBlur: (e) => onBlur && onBlur(e),
        })}
        className={className}
        disabled={disabled}
      />
      {errors[fieldName] && (
        <p className="py-2 text-danger">
          {errors[fieldName].message}
        </p>
      )}
    </div>
  );
};

export default CheckboxField;
