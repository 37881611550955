import React from "react";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import ImageUploadAdapter from "./ImageUploadAdapter";
// import ImageUpload from '@ckeditor/ckeditor5-image/src/imageupload'

export default function CkEditorComponent({
  fieldName,
  control,
  data,
  setValue,
}) {
  return (
    <>
      <CKEditor
        editor={ClassicEditor}
        data={data}
        control={control}
        config={{
          image: {
            toolbar: [
              "imageStyle:full",
              "imageStyle:side",
              "|",
              "imageTextAlternative",
            ],
            upload: { types: ["jpeg", "pdf", "docx"] },
          },
          extraPlugins: [ImageUploadAdapter],
        }}
        onReady={(editor) => {
          editor.plugins.get("FileRepository").createUploadAdapter = (
            loader
          ) => {
            console.log("loader", loader);
            return new ImageUploadAdapter(loader);
          };
          console.log("Editor is ready to use!", editor);
        }}
        onChange={(event, editor) => {
          const data = editor.getData();
          setValue(fieldName, data);
        }}
      />
    </>
  );
}
