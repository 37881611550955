import { get } from "lodash";
import moment from "moment/moment";
import {
  FaEdit,
  FaRegFilePdf,
  FaRegFileWord,
  FaRegFilePowerpoint,
  FaRegFileImage,
  FaDownload,
} from "react-icons/fa";
import { useSelector } from "react-redux";

const setImageOrPdf = (uploadFile, uploadFileName) => {
  const fileExtension = uploadFile.split(".").pop().toLowerCase();

  return (
    <div
      className="flex flex-row justify-between cursor-pointer"
      onClick={() => window.open(uploadFile)}
    >
      <div>
        {fileExtension === "pdf" ? (
          <FaRegFilePdf className="text-5xl text-red-600" />
        ) : fileExtension === "jpg" ||
          fileExtension === "jpeg" ||
          fileExtension === "png" ||
          fileExtension === "gif" ? (
          <FaRegFileImage className="text-2xl text-blue-500" />
        ) : fileExtension === "doc" || fileExtension === "docx" ? (
          <FaRegFileWord className="text-2xl text-blue-500" />
        ) : fileExtension === "pptx" ? (
          <FaRegFilePowerpoint className="text-2xl text-blue-500" />
        ) : null}
      </div>
      <div>
        {fileExtension === "jpg" ||
        fileExtension === "jpeg" ||
        fileExtension === "png" ||
        fileExtension === "gif" ? (
          <div className="flex flex-row justify-between">
            <img src={uploadFile} key={uploadFile} width="100px" alt="img" />{" "}
            <FaDownload className="ml-2 text-xl text-blue-500" />
          </div>
        ) : (
          <div className="flex flex-row justify-between">
            {" "}
            {uploadFileName}{" "}
            <FaDownload className="ml-2 text-xl text-blue-500" />
          </div>
        )}
      </div>
    </div>
  );
};

const NotesDisplay = ({ notesData, onEditNote, type, isView }) => {
  console.log("notesData", notesData);
  const { user } = useSelector((state) => state);
  return (
    <>
      {notesData &&
        notesData.length > 0 &&
        notesData.map((singleNote) => {
          console.log("singleNote", singleNote);
          return (
            <div className="flex flex-col md:flex-row justify-between p-3 border-2 border-[#eee] shadow-lg rounded-xl m-2 my-3 w-full hover:bg-gray-100">
              <div className="ml-5">
                <div className="flex text-blue-500 font-semibold">
                  {/* {singleNote && singleNote.user && (
                    <div className='mr-2'>
                      {get(singleNote, 'user.firstName') +
                        ' ' +
                        get(singleNote, 'user.lastName')}
                    </div>)} */}
                  <div className="mr-2">{singleNote?.createdByEmail}</div>

                  <div className="mx-2">
                    ({moment(singleNote.createdAt).format("DD-MM-YYYY")})
                  </div>
                </div>
                {type === "dailyNotes" ? (
                  <div
                    dangerouslySetInnerHTML={{
                      __html: singleNote.note,
                    }}
                    className="flex justify-center"
                  />
                ) : (
                  <div className="font-extrabold">{singleNote.comment}</div>
                )}
                <div className="flex flex-row">
                  {singleNote.attachment[0].url &&
                    // singleNote.uploadFileName &&
                    setImageOrPdf(
                      singleNote.attachment[0].url,
                      // singleNote.uploadFileName
                      singleNote.attachment[0].fileName
                    )}
                </div>
              </div>
              {onEditNote && singleNote?.createdBy === user?.userId && (
                <div className="flex justify-end items-end md:items-center md:justify-center text-xl mr-10">
                  {!isView && (
                    <div
                      className="hover:text-blue-700 cursor-pointer"
                      onClick={(e) => onEditNote(e, singleNote)}
                    >
                      <FaEdit />
                    </div>
                  )}
                </div>
              )}
            </div>
          );
        })}
    </>
  );
};

export default NotesDisplay;
