const SecondFooter = () => {
  const date = new Date();
  return (
    <div className="footer-wrapper">
      <div className="footer">
        <div className="panel border-right">
          <div className="text-white">
            <h2>&copy; SEVA {date.getFullYear()}</h2>
          </div>
        </div>
      </div>
      <div className="clear" />
    </div>
  );
};
export default SecondFooter;
