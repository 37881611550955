import React from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { faker } from '@faker-js/faker';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

function BarChart({ newTickets, inProgress, resolved }) {
  const options = {
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: 'top',
      },
      title: {
        display: true,
        text: '',
      },
    },
  };
  const labels = ['New', 'In Progress', 'Resolved'];
  const data = {
    labels,
    datasets: [
      {
        label: 'Total Tickets',
        data: [newTickets, inProgress, resolved],
        backgroundColor: 'rgba(54, 162, 235, 1)',
      },
    ],
  };
  return (
    <div>
      <Bar options={options} data={data} />
    </div>
  );
}

export default BarChart;
