import { useEffect, useState } from 'react';
import { get } from 'lodash';
import { Controller, useForm } from 'react-hook-form';
import InputField from '../../../Common/InputField';
import CheckboxField from '../../../Common/CheckboxField';
import { Grid, Button } from '@mui/material';
import TicketCharter from '../../Ticket-Notes/TicketCharter';
import DateField from '../../../Common/DateField';
import { State, City } from 'country-state-city';
import PhoneInput from 'react-phone-number-input/react-hook-form-input';
import { isValidPhoneNumber } from 'react-phone-number-input';
import {
  postRequest,
  putRequest,
  getRequest,
} from '../../interceptor/interceptor';
import FullScreenLoader from '../../Loader/FullScreenLoader';
import {
  UPDATE_TICKET,
  ADD_TICKET,
  UNIQUE_ID,
  REFERRAL_DATA_BY_CONTACT,
  TTD_DATES,
} from '../../../apiConst';
import { useLocation, useNavigate } from 'react-router';
import SelectDropdown from '../../../Common/SelectDropdown';
import {
  grievanceTicketFields,
  grievanceApplicantFields,
  referalFields,
  grievanceRequestFields,
} from '../../../Fields/grievanceFields';
import CkEditorComponent from '../../../Common/CkEditor';
import moment from 'moment';
import { FaChevronUp, FaChevronDown } from 'react-icons/fa';
import { toast } from 'react-toastify';

const AddGms = () => {
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(false);
  const fields = [
    ...grievanceTicketFields,
    ...grievanceApplicantFields,

    ...grievanceRequestFields,
  ];
  const [id, setId] = useState(null);
  const [isDisabledTicket, setIsDisabledTicket] = useState(false);
  const [ticketNo, setTicketNo] = useState(
    location?.state?.data?.task?.ticketNo || null
  );
  const [isEdit, setIsEdit] = useState(null);
  const [requestDetails, setRequestDetails] = useState(false);
  const [contactDetails, setContactDetails] = useState(false);
  const [detailsKnown, setDetailsKnown] = useState(false);
  const [other, setOther] = useState('');
  const [qualification, setQualification] = useState('');
  const [currentLocation, setCurrentLocation] = useState('');
  const [requestedLocation, setRequestedLocation] = useState('');
  const [referral, setReferral] = useState(false);
  const [ticket, setTicket] = useState(null);
  const [disableAllFields, setDisableAllFields] = useState(false);
  const [ttdDatesApproved, setTtdDatesApproved] = useState([]);
  const [ttdDatesInProgress, setTtdDatesInProgress] = useState([]);
  const [minDate, setMinDate] = useState('');
  const [issueOpen, setIssueOpen] = useState(moment().format('YYYY-MM-DD'));
  const [targetResolution, setTargetResolution] = useState(
    moment().format('YYYY-MM-DD')
  );

  const [states, setStates] = useState([]);
  const [state, setState] = useState('');
  const [cities, setCities] = useState([]);

  const [referralType, setRefferalType] = useState([
    { label: 'Self', value: 'self' },
    { label: 'TDP Cadre', value: 'tdpCadre' },
    { label: 'Janasena Cadre', value: 'janasenaCadre' },
    { label: 'Family', value: 'family' },
    { label: 'Friends', value: 'friends' },
    { label: 'Other', value: 'other' },
  ]);
  const [referralMode, setRefferalMode] = useState([
    { label: 'Letter', value: 'letter' },
    { label: 'Phone Call', value: 'phoneCall' },
    { label: 'Text Message', value: 'textMessage' },
    { label: 'In Person', value: 'inPerson' },
  ]);

  const [requestType, setRequestType] = useState('');
  const [personalRequestType, setPersonalRequestType] = useState('');
  const [department, setDepartment] = useState('');
  const [ttdAvailableSlot, setTtdAvailableSlot] = useState('');
  const [medicalRequestType, setMedicalRequestType] = useState('');
  const [communityRequestDepartment, setCommunityRequestDepartment] =
    useState('');
  // const [states, setStates] = useState([
  //   { label: 'Andhra Pradesh', value: 'andhraPradesh' },
  //   { label: 'Other', value: 'other' },
  // ]);

  // const [districts, setDistricts] = useState([
  //   { label: 'Srikakulam', value: 'srikakulam' },
  //   { label: 'Vizianagaram', value: 'vizianagaram' },
  //   { label: 'Visakhapatnam', value: 'visakhapatnam' },
  //   { label: 'Anakapalli', value: 'anakapalli' },
  // ]);
  // const [constituencies, setConstituencies] = useState([
  //   { label: 'Anakapalli', value: 'anakapalli' },
  //   { label: 'Chodavaram', value: 'chodavaram' },
  //   { label: 'Madugula', value: 'madugula' },
  //   { label: 'Narsipatnam', value: 'narsipatnam' },
  // ]);
  // const [mandals, setMandals] = useState([
  //   { label: 'Makavaripallem', value: 'makavaripallem' },
  //   { label: 'Narsipatnam', value: 'narsipatnam' },
  //   { label: 'Nathavaram', value: 'nathavaram' },
  // ]);
  const [isTicketToggle, setIsTicketToggle] = useState(true); // State to toggle the accordion
  const [isApplicantToggle, setIsApplicantToggle] = useState(false); // State to toggle the accordion
  const [isRequestToggle, setIsRequestToggle] = useState(false); // State to toggle the accordion
  const [isRefferalToggle, setIsRefferalToggle] = useState(false); // State to toggle the accordion

  const ticketToggleAccordion = () => {
    setIsTicketToggle(!isTicketToggle);
    setIsApplicantToggle(false);
    setIsRequestToggle(false);
    setIsRefferalToggle(false);
  };
  const applicantToggleAccordion = () => {
    setIsApplicantToggle(!isApplicantToggle);
    setIsRequestToggle(false);
    setIsRefferalToggle(false);
    setIsTicketToggle(false);
  };
  const requestToggleAccordion = () => {
    setIsRequestToggle(!isRequestToggle);
    setIsRefferalToggle(false);
    setIsTicketToggle(false);
    setIsApplicantToggle(false);
  };
  const refferalToggleAccordion = () => {
    setIsRefferalToggle(!isRefferalToggle);
    setIsApplicantToggle(false);
    setIsRequestToggle(false);
    setIsTicketToggle(false);
  };
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    setValue,
    reset,
    getValues,
    control,
    formState: { errors },
  } = useForm({});

  useEffect(() => {
    if (location?.state?.isEdit || location?.state?.isView) {
      location?.state?.isEdit && setIsEdit(location?.state?.isEdit);
      const { task } = location?.state?.data;
      console.log('task', task);
      // Set form fields using setValue
      fields.map((field) => setValue(field.fieldName, task[field.fieldName]));
      setTicketNo(task.ticketNo);
      setReferral(task.referral);
      setRequestDetails(task.requestDetails);
      // miscellaneous
      setOther(task.miscellaneous);
      //requestType
      setRequestType(task.requestType);
      // personalRequestType
      setPersonalRequestType(task.personalRequestType);
      setDepartment(task.issueDepartment);
      setTtdAvailableSlot(task.ttdAvailableSlot);

      // medicalRequestType
      setMedicalRequestType(task.medicalRequest);

      // Set individual form fields
      setValue('state', task?.state);
      setValue('district', task?.district);
      setValue('constituency', task?.constituency);
      setValue('mandal', task?.mandal);
      setValue('issuePOC', task?.issuePOC);
      setValue('issueContact', task?.issueContact);
      setValue('issueDepartment', task?.issueDepartment);
      setValue('referralType', task?.referralType);
      setValue('referralContact', task?.referralContact);
      setValue('referralName', task?.referralName);
      setValue('referralDesignation', task?.referralDesignation);
      setValue('referralMode', task?.referralMode);
      setValue('requestDetails', task?.requestDetails);
      setValue('medicalRequestType', task?.medicalRequestType);
      setValue('personalRequestType', task?.personalRequestType);
      setValue('ttdAvailableSlot', task?.ttdAvailableSlot);
      setValue('miscellaneous', task?.miscellaneous);
      setValue('qualification', task?.qualification);
      setValue('currentLocation', task?.currentLocation);
      setValue('requestedLocation', task?.requestedLocation);
      setValue('contactDetails', task?.contactDetails);
      setValue('detailsKnown', task?.detailsKnown);
      setValue('contactName', task?.contactName);
      setValue('contactNumber', task?.contactNumber);
      setValue('designation', task?.designation);
      setValue('hospitalName', task?.hospitalName);
      setValue('typeOfIllness', task?.typeOfIllness);
      setValue('estimateAmount', task?.estimateAmount);
      setValue('cost', task?.cost);
      setValue('grant', task?.grant);
      setValue('communityRequestDepartment', task?.communityRequestDepartment);
      setValue('department', task?.department);
      setValue('requestMode', task?.requestMode);
      // if (location?.state?.isView) {
      //   setDisableAllFields(true); // Disable fields for view mode
      // }
    } else {
      // If neither edit nor view, generate unique ID or perform other logic
      getUniqueId();
    }
  }, []);

  useEffect(() => {
    if (ttdDatesInProgress.length === 0 && ttdDatesApproved.length === 0) {
      getExcludedDates();
    }
  }, []);

  useEffect(() => {
    let today = new Date();
    setMinDate(new Date(today.setDate(today.getDate() + 1)));
    const indiaStates = State.getStatesOfCountry('IN');
    setStates(indiaStates);
  }, []);

  useEffect(() => {
    const indiaCities = City.getCitiesOfState('IN', state);
    setCities(indiaCities);
  }, [state]);

  const onCancel = () => {
    navigate('/gms', {});
  };
  const getUniqueId = () => {
    setIsLoading(true);
    getRequest(`${UNIQUE_ID}${'REQ'}`)
      .then((res) => {
        console.log('res', res);
        const { data } = res;
        if (data) {
          setValue('ticketNo', data);
          setTicketNo(data);
          setIsDisabledTicket(true);
          setIsLoading(false);
        } else {
          setIsLoading(true);
        }
      })
      .catch(() => {
        setIsLoading(false);
      });
  };

  const getExcludedDates = () => {
    let url = '';
    url = getRequest(`${TTD_DATES}`);
    url
      .then((res) => {
        setTtdDatesApproved(res.approved);
        setTtdDatesInProgress(res.inprogress);
      })
      .catch((error) => {
        console.log('error ttd', error);
      });
  };

  const onSubmit = (data, e) => {
    console.log('e', e.target.value);
    console.log('data', data);
    let url = '';
    setIsLoading(true);

    let phoneNumber = data?.applicantMobile;
    let referalNumber = data?.referralContact;

    if (referalNumber) {
      const isPhoneNumber = isValidPhoneNumber(phoneNumber);
      const isReferralContact = isValidPhoneNumber(referalNumber);
      if (!isPhoneNumber) {
        setIsLoading(false);
        return toast.error('Enter a valid applicant phone number!');
      } else if (!isReferralContact) {
        setIsLoading(false);
        return toast.error('Enter a valid referral phone number!');
      }

      // if (data?.assignedTo === '') {
      //   setIsLoading(false);
      //   return toast.error('Assigned to is required!');
      // }

      // if (data?.priority === '') {
      //   setIsLoading(false);
      //   return toast.error('Priority is required!');
      // }

      if (e.target.value === 'submit') {
        data = {
          ...data,
          department: department,
          miscellaneous: other,
          personalRequestType: personalRequestType,
          medicalRequestType: medicalRequestType,
          qualification: qualification,
          currentLocation: currentLocation,
          requestedLocation: requestedLocation,
          contactDetails: contactDetails,
          detailsKnown: detailsKnown,
          communityRequestDepartment: communityRequestDepartment,
          // ttdAvailableSlot: moment(data.ttdAvailableSlot).format("YYYY-MM-DD"),
          targetResolution: moment(data.targetResolution).format('YYYY-MM-DD'),
          issueOpen: moment(data.issueOpen).format('YYYY-MM-DD'),
          ticketStatus: 'inprogress',
        };

        if (isEdit) {
          url = putRequest(`${UPDATE_TICKET}`, data);
        } else {
          url = postRequest(`${ADD_TICKET}`, data);
        }
        url
          .then((res) => {
            console.log('res', res);
            toast.info(res);
            setIsLoading(false);
            navigate('/gms');
          })
          .catch(() => {
            setIsLoading(false);
          });
        setIsLoading(false);
      } else {
        data = {
          ...data,
          department: department,
          miscellaneous: other,
          personalRequestType: personalRequestType,
          medicalRequestType: medicalRequestType,
          //  ttdAvailableSlot: moment(data.ttdAvailableSlot).format("YYYY-MM-DD"),
          targetResolution: moment(data.targetResolution).format('YYYY-MM-DD'),
          issueOpen: moment(data.issueOpen).format('YYYY-MM-DD'),
        };

        if (isEdit) {
          url = putRequest(`${UPDATE_TICKET}`, data);
        } else {
          url = postRequest(`${ADD_TICKET}`, data);
        }
        url
          .then((res) => {
            setIsLoading(false);
            navigate('/gms');
          })
          .catch(() => {
            setIsLoading(false);
          });
        setIsLoading(false);
      }
    } else {
      const isPhoneNumber = isValidPhoneNumber(phoneNumber);
      //const isReferralContact = isValidPhoneNumber(referalNumber);
      if (!isPhoneNumber) {
        setIsLoading(false);
        return toast.error('Enter a valid applicant phone number!');
      }

      if (e.target.value === 'submit') {
        data = {
          ...data,
          department: department,
          miscellaneous: other,
          personalRequestType: personalRequestType,
          medicalRequestType: medicalRequestType,
          qualification: qualification,
          currentLocation: currentLocation,
          requestedLocation: requestedLocation,
          contactDetails: contactDetails,
          detailsKnown: detailsKnown,
          communityRequestDepartment: communityRequestDepartment,
          // ttdAvailableSlot: moment(data.ttdAvailableSlot).format("YYYY-MM-DD"),
          targetResolution: moment(data.targetResolution).format('YYYY-MM-DD'),
          issueOpen: moment(data.issueOpen).format('YYYY-MM-DD'),
          ticketStatus: 'inprogress',
        };

        if (isEdit) {
          url = putRequest(`${UPDATE_TICKET}`, data);
        } else {
          url = postRequest(`${ADD_TICKET}`, data);
        }
        url
          .then((res) => {
            console.log('res', res);
            toast.info(res);
            setIsLoading(false);
            navigate('/gms');
          })
          .catch(() => {
            setIsLoading(false);
          });
        setIsLoading(false);
      } else {
        data = {
          ...data,
          department: department,
          miscellaneous: other,
          personalRequestType: personalRequestType,
          medicalRequestType: medicalRequestType,
          //  ttdAvailableSlot: moment(data.ttdAvailableSlot).format("YYYY-MM-DD"),
          targetResolution: moment(data.targetResolution).format('YYYY-MM-DD'),
          issueOpen: moment(data.issueOpen).format('YYYY-MM-DD'),
        };

        if (isEdit) {
          url = putRequest(`${UPDATE_TICKET}`, data);
        } else {
          url = postRequest(`${ADD_TICKET}`, data);
        }
        url
          .then((res) => {
            setIsLoading(false);
            navigate('/gms');
          })
          .catch(() => {
            setIsLoading(false);
          });
        setIsLoading(false);
      }
    }
  };

  const fetchReferralDetails = (contactNumber) => {
    setIsLoading(true);
    setIsLoading(true);
    getRequest(`${REFERRAL_DATA_BY_CONTACT}${contactNumber}`)
      .then((res) => {
        if (res) {
          // Assuming the API response contains `referralName` and `referralDesignation`
          setValue('referralName', res.referralName || '');
          setValue('referralDesignation', res.referralDesignation || '');
          setValue('referralMode', res.referralMode || '');
          setValue('referralType', res.referralType || '');
          setIsLoading(false);
        } else {
          setIsLoading(true);
        }
      })
      .catch(() => {
        setIsLoading(false);
      });
  };

  const handleBlur = (e) => {
    const contactNumber = '+91' + e.target.value.replace(/[^\d\+]/g, '');
    if (contactNumber) {
      fetchReferralDetails(contactNumber);
    }
  };

  console.log('requestType', requestType);

  const conditionalFields = [
    {
      fieldName: 'personalRequestType',
      fieldTitle: 'Personal Request Type',
      validationObj: {
        required: 'Please Select Request Type',
      },
      fieldType: 'select',
      options: [
        {
          label: 'Employment Request',
          value: 'Employment Request',
        },
        {
          label: 'Transfer Request',
          value: 'Transfer Request',
        },
        {
          label: 'TTD Darshan',
          value: 'TTD Darshan',
        },
        {
          label: 'Other',
          value: 'other',
        },
      ], // Populate with request type options
    },
    {
      fieldName: 'department',
      fieldTitle: 'Department',
      validationObj: {
        required: 'Please Select Department',
      },
      fieldType: 'select',
      options: [
        {
          label: 'R&B',
          value: 'R&B',
        },
        {
          label: 'Housing',
          value: 'Housing',
        },
        {
          label: 'PR',
          value: 'PR',
        },
        {
          label: 'Agriculture',
          value: 'Agriculture',
        },
        {
          label: 'Police',
          value: 'Police',
        },
        {
          label: 'Fisheries',
          value: 'Fisheries',
        },
        {
          label: 'Forest',
          value: 'Forest',
        },
      ], // Populate with request type options
    },

    {
      fieldName: 'medicalRequestType',
      fieldTitle: 'Medical Request Type',
      validationObj: {
        required: 'Please Select Request Type',
      },
      fieldType: 'select',
      options: [
        {
          label: 'CMRF',
          value: 'CMRF',
        },
        {
          label: 'PMRF',
          value: 'PMRF',
        },
        {
          label: 'Medical Aid',
          value: 'Medical Aid',
        },
      ], // Populate with request type options
    },
    {
      fieldName: 'ttdAvailableSlot',
      fieldTitle: 'TTD Available Slot',
      fieldType: 'date',
    },
    {
      fieldName: 'miscellaneous',
      fieldTitle: 'Miscellaneous',
      fieldType: 'text',
    },
    {
      fieldName: 'qualification',
      fieldTitle: 'Qualification',
      fieldType: 'text',
    },
    {
      fieldName: 'department',
      fieldTitle: 'Department',
      fieldType: 'text',
    },
    {
      fieldName: 'currentLocation',
      fieldTitle: 'Current Location',
      fieldType: 'text',
    },
    {
      fieldName: 'requestedLocation',
      fieldTitle: 'Requested Location',
      fieldType: 'text',
    },
    {
      fieldName: 'communityRequestDepartment',
      fieldTitle: 'Community Request Department',
      validationObj: {
        required: 'Please Select Department',
      },
      fieldType: 'select',
      options: [
        {
          label: 'Education',
          value: 'Education',
        },
        {
          label: 'R&B',
          value: 'R&B',
        },
        {
          label: 'Housing',
          value: 'Housing',
        },
        {
          label: 'PR',
          value: 'PR',
        },
        {
          label: 'Agriculture',
          value: 'Agriculture',
        },
        {
          label: 'Police',
          value: 'Police',
        },
        {
          label: 'Fisheries',
          value: 'Fisheries',
        },
        {
          label: 'Forest',
          value: 'Forest',
        },
      ], // Populate with request type options
    },
    {
      fieldName: 'contactDetails',
      fieldTitle: 'Contact Details',
      fieldType: 'checkbox', // This seems like a checkbox based on the image
    },
    {
      fieldName: 'detailsKnown',
      fieldTitle: 'Details Known',
      fieldType: 'checkbox', // This seems like a checkbox based on the image
    },
  ];

  const transferContactDetails = [
    {
      fieldTitle: 'Contact Name',
      fieldName: 'contactName',
      fieldType: 'text',
    },
    {
      fieldTitle: 'Contact Number',
      fieldName: 'contactNumber',
      fieldType: 'text',
    },
    {
      fieldTitle: 'Designation',
      fieldName: 'designation',
      fieldType: 'text',
    },
  ];

  const communityContactDetails = [
    {
      fieldTitle: 'Cost',
      fieldName: 'cost',
      fieldType: 'text',
    },
    {
      fieldTitle: 'Grant',
      fieldName: 'grant',
      fieldType: 'text',
    },
    {
      fieldTitle: 'Contact Name',
      fieldName: 'contactName',
      fieldType: 'text',
    },
    {
      fieldTitle: 'Contact Number',
      fieldName: 'contactNumber',
      fieldType: 'text',
    },
  ];

  const officialContactDetails = [
    {
      fieldTitle: 'Contact Name',
      fieldName: 'contactName',
      fieldType: 'text',
    },
    {
      fieldTitle: 'Contact Number',
      fieldName: 'contactNumber',
      fieldType: 'text',
    },
  ];

  const medicalDetails = [
    {
      fieldTitle: 'Hospital Name',
      fieldName: 'hospitalName',
      fieldType: 'text',
    },
    {
      fieldTitle: 'Type Of Illness',
      fieldName: 'typeOfIllness',
      fieldType: 'text',
    },
    {
      fieldTitle: 'Estimate Amount',
      fieldName: 'estimateAmount',
      fieldType: 'text',
    },
  ];

  const onPinCodeBlur = (e) => {
    console.log('e', e);
    const { value } = e.target;
    console.log('pincode++++11=+++', value);
    if (value) {
      fetch(
        `https://maps.googleapis.com/maps/api/geocode/json?components=postal_code:${value}&key=${process.env.REACT_APP_GEOLOCATION_API}`
      )
        .then((response) => response.json())
        .then((res) => {
          if (res?.results && res?.results.length > 0) {
            const data = res.results[0];
            let obj = {};
            get(data, 'address_components').forEach((addr) => {
              if (get(addr, 'types').includes('locality')) {
                obj['city'] = get(addr, 'long_name');
              } else if (
                get(addr, 'types').includes('administrative_area_level_3')
              ) {
                obj['city'] = get(addr, 'long_name');
              } else if (
                get(addr, 'types').includes('administrative_area_level_1')
              ) {
                obj['state'] = get(addr, 'short_name');
              } else if (get(addr, 'types').includes('country')) {
                obj['country'] = get(addr, 'short_name');
              }
            });
            let countryVal = '';
            countryVal = obj.country;
            setStates(State.getStatesOfCountry(countryVal));
            let stateObj = obj.state === 'TS' ? 'TG' : obj.state;
            setCities(City.getCitiesOfState(countryVal, stateObj));
            setTimeout(() => {
              setValue('district', obj.city);
              setValue('state', stateObj);
            }, 500);
          }
        });
    }
  };

  return (
    <>
      {isLoading && <FullScreenLoader />}
      <div className="block rounded-lg bg-white p-6 shadow-[0_2px_15px_-3px_rgba(0,0,0,0.07),0_10px_20px_-2px_rgba(0,0,0,0.04)] m-4 overflow-auto">
        <div className="flex mb-2 text-xl font-extrabold text-primary-600">
          {isEdit ? 'Edit' : location?.state?.isView ? 'View' : 'Add'} Ticket
        </div>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <div
                className="flex justify-between items-center text-white rounded-md bg-gray-700 p-2 mb-1 cursor-pointer"
                onClick={ticketToggleAccordion} // Toggle on click
              >
                <h2>Ticket Information</h2>
                {/* Render the icon based on the toggle state */}
                {isTicketToggle ? <FaChevronUp /> : <FaChevronDown />}
              </div>
            </Grid>
            {isTicketToggle &&
              grievanceTicketFields?.map((field) => {
                const {
                  fieldTitle,
                  fieldName,
                  fieldType,
                  validationObj,
                  options,
                } = field;

                return (
                  <>
                    <Grid
                      item
                      xs={12}
                      lg={
                        fieldName === 'additionalInformation' ||
                        fieldName === 'requestDescription'
                          ? 12
                          : 2
                      }
                      key={fieldName}
                    >
                      {fieldType === 'date' && fieldName === 'issueOpen' ? (
                        <>
                          <label className="block text-sm text-gray-800 font-bold mb-3 pr-4">
                            {fieldTitle}
                          </label>
                          <DateField
                            fieldName={fieldName}
                            validationObj={validationObj}
                            control={control}
                            Controller={Controller}
                            minDate={new Date()}
                            onChange={(e) =>
                              setIssueOpen(
                                moment(e.target.value).format('mm-dd-yyyy')
                              )
                            }
                            errors={errors}
                            defaultValue={getValues(fieldName)}
                            disabled={location?.state?.isView}
                          />
                        </>
                      ) : fieldType === 'date' &&
                        fieldName === 'targetResolution' ? (
                        <>
                          <label className="block text-sm text-gray-800 font-bold mb-3 pr-4">
                            {fieldTitle}
                          </label>
                          <DateField
                            fieldName={fieldName}
                            validationObj={validationObj}
                            control={control}
                            Controller={Controller}
                            minDate={issueOpen}
                            onChange={(e) =>
                              setTargetResolution(
                                moment(e.target.value).format('mm-dd-yyyy')
                              )
                            }
                            errors={errors}
                            defaultValue={getValues(fieldName)}
                            disabled={location?.state?.isView}
                          />
                        </>
                      ) : (
                        <>
                          <label className="block text-gray-800 text-sm font-bold mb-3 pr-4">
                            {fieldTitle}
                          </label>
                          <InputField
                            fieldName={fieldName}
                            validationObj={validationObj}
                            register={register}
                            disabled={
                              fieldName === 'ticketNo'
                                ? true
                                : location?.state?.isView
                            }
                            errors={errors}
                            fieldType={fieldType}
                          />
                        </>
                      )}
                    </Grid>
                  </>
                );
              })}
          </Grid>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <div
                className="flex justify-between items-center text-white rounded-md bg-gray-700 p-2 mb-1 cursor-pointer"
                onClick={applicantToggleAccordion} // Toggle on click
              >
                <h2> Applicant Information</h2>
                {/* Render the icon based on the toggle state */}
                {isApplicantToggle ? <FaChevronUp /> : <FaChevronDown />}
              </div>
            </Grid>
            {isApplicantToggle &&
              grievanceApplicantFields?.map((field) => {
                const {
                  fieldTitle,
                  fieldName,
                  fieldType,
                  validationObj,
                  options,
                } = field;

                return (
                  <Grid
                    item
                    xs={12}
                    lg={
                      fieldName === 'additionalInformation' ||
                      fieldName === 'requestDescription'
                        ? 12
                        : 2
                    }
                    key={fieldName}
                  >
                    {fieldType === 'select' ? (
                      fieldName === 'state' ? (
                        <>
                          <label className="block text-sm text-gray-800 font-bold mb-3 pr-4">
                            {fieldTitle}
                          </label>
                          <SelectDropdown
                            fieldName="state"
                            options={states.map((state) => ({
                              label: state.name,
                              value: state.isoCode,
                            }))}
                            register={register}
                            validationObj={{ required: 'Please Select State' }}
                            onChange={(e) => setState(e.target.value)}
                            errors={errors}
                            val={getValues(fieldName)}
                            disabled={location?.state?.isView}
                          />
                        </>
                      ) : fieldName === 'district' ? (
                        <>
                          <label className="block text-sm text-gray-800 font-bold mb-3 pr-4">
                            {fieldTitle}
                          </label>
                          <SelectDropdown
                            fieldName="district"
                            options={cities.map((city) => ({
                              label: city.name,
                              value: city.name,
                            }))}
                            register={register}
                            validationObj={{
                              required: 'Please Select District',
                            }}
                            errors={errors}
                            val={getValues(fieldName)}
                            disabled={location?.state?.isView}
                          />
                        </>
                      ) : null
                    ) : fieldName === 'applicantMobile' ? (
                      <>
                        <label className="block text-gray-800 text-sm font-bold mb-3 pr-4">
                          {fieldTitle}
                        </label>
                        <PhoneInput
                          className="shadow appearance-none border border-solid border-gray-700 rounded-xl w-full py-2.5 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-blue-500 disabled:bg-gray-200"
                          country="IN"
                          name={fieldName}
                          defaultValue={getValues(fieldName)}
                          control={control}
                          placeholder="Phone Number"
                        />
                      </>
                    ) : (
                      <>
                        <label className="block text-gray-800 text-sm font-bold mb-3 pr-4">
                          {fieldTitle}
                        </label>
                        <InputField
                          fieldName={fieldName}
                          validationObj={validationObj}
                          register={register}
                          disabled={
                            fieldName === 'ticketNo'
                              ? true
                              : location?.state?.isView
                          }
                          onBlur={fieldName === 'pincode' && onPinCodeBlur}
                          errors={errors}
                          fieldType={fieldType}
                        />
                      </>
                    )}
                  </Grid>
                );
              })}
          </Grid>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <div
                className="flex justify-between items-center text-white rounded-md bg-gray-700 p-2 mb-1 cursor-pointer"
                onClick={requestToggleAccordion} // Toggle on click
              >
                <h2> Request Details</h2>
                {/* Render the icon based on the toggle state */}
                {isRequestToggle ? <FaChevronUp /> : <FaChevronDown />}
              </div>
            </Grid>
            {isRequestToggle &&
              grievanceRequestFields.map((field) => {
                const {
                  fieldTitle,
                  fieldName,
                  fieldType,
                  validationObj,
                  options,
                } = field;

                return (
                  <Grid
                    item
                    xs={12}
                    lg={
                      fieldName === 'additionalInformation' ||
                      fieldName === 'requestDescription'
                        ? 6
                        : 2
                    }
                    key={fieldName}
                  >
                    {fieldType === 'date' ? (
                      <>
                        <label className="block text-sm text-gray-800 font-bold mb-3 pr-4">
                          {fieldTitle}
                        </label>
                        <DateField
                          fieldName={fieldName}
                          validationObj={validationObj}
                          control={control}
                          Controller={Controller}
                          minDate={new Date()}
                          errors={errors}
                          defaultValue={getValues(fieldName)}
                          disabled={location?.state?.isView}
                        />
                      </>
                    ) : fieldType === 'editor' ? (
                      <>
                        <label className="block text-sm text-gray-800 font-bold mb-3 pr-4">
                          {fieldTitle}
                        </label>
                        <CkEditorComponent
                          register={register}
                          fieldName={fieldName}
                          control={control}
                          data={getValues(fieldName) || ''}
                          setValue={setValue}
                          disabled={location?.state?.isView}
                        />
                      </>
                    ) : fieldType === 'select' ? (
                      fieldName === 'requestMode' ? (
                        <>
                          <label className="block text-sm text-gray-800 font-bold mb-3 pr-4">
                            {fieldTitle}
                          </label>
                          <SelectDropdown
                            fieldName={fieldName}
                            options={options}
                            register={register}
                            validationObj={{
                              required: 'Please Select Request Node',
                            }}
                            errors={errors}
                            val={getValues(fieldName)}
                            disabled={location?.state?.isView}
                          />
                        </>
                      ) : fieldName === 'requestType' ? (
                        <>
                          <label className="block text-sm text-gray-800 font-bold mb-3 pr-4">
                            {fieldTitle}
                          </label>
                          <SelectDropdown
                            fieldName={fieldName}
                            options={options}
                            register={register}
                            validationObj={{
                              required: 'Please Select Request Type',
                            }}
                            errors={errors}
                            onChange={(e) => {
                              setRequestType(e.target.value);
                              setPersonalRequestType('');
                            }}
                            val={getValues(fieldName)}
                            disabled={location?.state?.isView}
                          />
                          <>
                            {requestType === 'personal' && (
                              <Grid item xs={12}>
                                <label className="block text-sm text-gray-800 font-bold mb-3 pr-4">
                                  {conditionalFields[0].fieldTitle}
                                </label>
                                <SelectDropdown
                                  fieldName={conditionalFields[0].fieldName}
                                  options={conditionalFields[0]?.options}
                                  register={register}
                                  validationObj={{
                                    required: 'Please Select Request Type',
                                  }}
                                  errors={errors}
                                  onChange={(e) =>
                                    setPersonalRequestType(e.target.value)
                                  }
                                  val={getValues(
                                    conditionalFields[0].fieldName
                                  )}
                                  disabled={location?.state?.isView}
                                />
                              </Grid>
                            )}
                            {requestType === 'personal' &&
                              personalRequestType === 'TTD Darshan' && (
                                <Grid item xs={12}>
                                  <>
                                    <label className="block text-sm text-gray-800 font-bold mb-3 pr-4">
                                      {conditionalFields[3].fieldTitle}
                                    </label>
                                    <DateField
                                      fieldName={conditionalFields[3].fieldName}
                                      validationObj={validationObj}
                                      control={control}
                                      Controller={Controller}
                                      minDate={new Date()}
                                      disabled={location?.state?.isView}
                                      onChange={(e) =>
                                        setTtdAvailableSlot(
                                          moment(e.target.value).format(
                                            'YYYY-MM-DD'
                                          )
                                        )
                                      }
                                      excluded={ttdDatesApproved}
                                      highlighted={ttdDatesInProgress}
                                      errors={errors}
                                      defaultValue={getValues(
                                        conditionalFields[3].fieldName
                                      )}
                                    />
                                  </>
                                </Grid>
                              )}
                            {requestType === 'personal' &&
                              personalRequestType === 'other' && (
                                <Grid item xs={12}>
                                  <>
                                    <label className="block text-sm text-gray-800 font-bold mb-3 pr-4">
                                      {conditionalFields[4].fieldTitle}
                                    </label>
                                    <InputField
                                      fieldName={conditionalFields[4].fieldName}
                                      validationObj={validationObj}
                                      register={register}
                                      errors={errors}
                                      fieldType={conditionalFields[4].fieldType}
                                      onChange={(e) => setOther(e.target.value)}
                                      val={getValues(
                                        conditionalFields[4].fieldName
                                      )}
                                      disabled={location?.state?.isView}
                                    />
                                  </>
                                </Grid>
                              )}
                            {requestType === 'personal' &&
                              personalRequestType === 'Employment Request' && (
                                <Grid item xs={12}>
                                  <>
                                    <label className="block text-sm text-gray-800 font-bold mb-3 pr-4">
                                      {conditionalFields[5].fieldTitle}
                                    </label>
                                    <InputField
                                      fieldName={conditionalFields[5].fieldName}
                                      validationObj={validationObj}
                                      register={register}
                                      errors={errors}
                                      fieldType={conditionalFields[5].fieldType}
                                      onChange={(e) =>
                                        setQualification(e.target.value)
                                      }
                                      val={getValues(
                                        conditionalFields[5].fieldName
                                      )}
                                      disabled={location?.state?.isView}
                                    />
                                  </>
                                </Grid>
                              )}
                            {requestType === 'personal' &&
                              personalRequestType === 'Transfer Request' && (
                                <Grid item xs={12}>
                                  <>
                                    <label className="block text-sm text-gray-800 font-bold mb-3 pr-4">
                                      {conditionalFields[6].fieldTitle}
                                    </label>
                                    <InputField
                                      fieldName={conditionalFields[6].fieldName}
                                      validationObj={validationObj}
                                      register={register}
                                      errors={errors}
                                      fieldType={conditionalFields[6].fieldType}
                                      onChange={(e) =>
                                        setDepartment(e.target.value)
                                      }
                                      val={getValues(
                                        conditionalFields[6].fieldName
                                      )}
                                      disabled={location?.state?.isView}
                                    />
                                    <label className="block text-sm text-gray-800 font-bold mb-3 pr-4">
                                      {conditionalFields[7].fieldTitle}
                                    </label>
                                    <InputField
                                      fieldName={conditionalFields[7].fieldName}
                                      validationObj={validationObj}
                                      register={register}
                                      errors={errors}
                                      fieldType={conditionalFields[7].fieldType}
                                      onChange={(e) =>
                                        setCurrentLocation(e.target.value)
                                      }
                                      val={getValues(
                                        conditionalFields[7].fieldName
                                      )}
                                      disabled={location?.state?.isView}
                                    />
                                    <label className="block text-sm text-gray-800 font-bold mb-3 pr-4">
                                      {conditionalFields[8].fieldTitle}
                                    </label>
                                    <InputField
                                      fieldName={conditionalFields[8].fieldName}
                                      validationObj={validationObj}
                                      register={register}
                                      errors={errors}
                                      fieldType={conditionalFields[8].fieldType}
                                      onChange={(e) =>
                                        setRequestedLocation(e.target.value)
                                      }
                                      val={getValues(
                                        conditionalFields[8].fieldName
                                      )}
                                      disabled={location?.state?.isView}
                                    />
                                    <label className="block text-sm text-gray-800 font-bold mb-3 pr-4">
                                      {conditionalFields[10].fieldTitle}
                                    </label>
                                    <CheckboxField
                                      fieldName={
                                        conditionalFields[10].fieldName
                                      }
                                      register={register}
                                      validationObj={validationObj}
                                      onChange={(checked) =>
                                        setContactDetails(checked)
                                      }
                                      errors={errors}
                                      disabled={location?.state?.isView}
                                    />
                                  </>
                                  <>
                                    {contactDetails && (
                                      <>
                                        {transferContactDetails.map(
                                          (details) => (
                                            <Grid item xs={12}>
                                              <label className="block text-sm text-gray-800 font-bold mb-3 pr-4">
                                                {details.fieldTitle}
                                              </label>
                                              <InputField
                                                fieldName={details.fieldName}
                                                validationObj={{
                                                  required: false,
                                                }}
                                                register={register}
                                                errors={errors}
                                                value={getValues(
                                                  details.fieldName
                                                )}
                                                disabled={
                                                  location?.state?.isView
                                                }
                                              />
                                            </Grid>
                                          )
                                        )}
                                      </>
                                    )}
                                  </>
                                </Grid>
                              )}
                          </>
                          <>
                            {requestType === 'official' && (
                              <Grid item xs={12}>
                                <label className="block text-gray-800 text-sm font-bold mb-3 pr-4">
                                  {conditionalFields[1].fieldTitle}
                                </label>
                                <SelectDropdown
                                  fieldName={conditionalFields[1].fieldName}
                                  options={conditionalFields[1]?.options}
                                  register={register}
                                  validationObj={{
                                    required: 'Please Select Request Type',
                                  }}
                                  errors={errors}
                                  onChange={(e) => {
                                    setDepartment(e.target.value);
                                  }}
                                  val={getValues(
                                    conditionalFields[1].fieldName
                                  )}
                                  disabled={location?.state?.isView}
                                />
                              </Grid>
                            )}

                            {requestType === 'official' &&
                              officialContactDetails.map((details) => (
                                <Grid item xs={12}>
                                  <>
                                    <label className="block text-sm text-gray-800 font-bold mb-3 pr-4">
                                      {details.fieldTitle}
                                    </label>
                                    <InputField
                                      fieldName={details.fieldName}
                                      validationObj={validationObj}
                                      register={register}
                                      errors={errors}
                                      fieldType={details.fieldType}
                                      val={getValues(details.fieldName)}
                                      disabled={location?.state?.isView}
                                    />
                                  </>
                                </Grid>
                              ))}
                          </>
                          <>
                            {requestType === 'community' && (
                              <Grid item xs={12}>
                                <label className="block text-sm text-gray-800 font-bold mb-3 pr-4">
                                  {conditionalFields[1].fieldTitle}
                                </label>
                                <SelectDropdown
                                  fieldName={conditionalFields[9].fieldName}
                                  options={conditionalFields[9]?.options}
                                  register={register}
                                  validationObj={{
                                    required: 'Please Select Request Type',
                                  }}
                                  errors={errors}
                                  onChange={(e) => {
                                    setCommunityRequestDepartment(
                                      e.target.value
                                    );
                                  }}
                                  val={getValues(
                                    conditionalFields[9].fieldName
                                  )}
                                  disabled={location?.state?.isView}
                                />
                                <label className="block text-gray-800 text-sm font-bold mb-3 pr-4">
                                  {conditionalFields[11].fieldTitle}
                                </label>
                                <CheckboxField
                                  fieldName={conditionalFields[11].fieldName}
                                  register={register}
                                  validationObj={validationObj}
                                  onChange={(checked) =>
                                    setDetailsKnown(checked)
                                  }
                                  errors={errors}
                                  disabled={location?.state?.isView}
                                />
                              </Grid>
                            )}

                            {requestType === 'community' &&
                              detailsKnown &&
                              communityContactDetails.map((details) => (
                                <Grid item xs={12}>
                                  <>
                                    <label className="block text-sm text-gray-800 font-bold mb-3 pr-4">
                                      {details.fieldTitle}
                                    </label>
                                    <InputField
                                      fieldName={details.fieldName}
                                      validationObj={validationObj}
                                      register={register}
                                      errors={errors}
                                      fieldType={details.fieldType}
                                      val={getValues(details.fieldName)}
                                      disabled={location?.state?.isView}
                                    />
                                  </>
                                </Grid>
                              ))}
                          </>
                          <>
                            {requestType === 'medical' && (
                              <Grid item xs={12}>
                                <label className="block text-sm text-gray-800 font-bold mb-3 pr-4">
                                  {conditionalFields[2].fieldTitle}
                                </label>
                                <SelectDropdown
                                  fieldName={conditionalFields[2].fieldName}
                                  options={conditionalFields[2]?.options}
                                  register={register}
                                  validationObj={{
                                    required: 'Please Select Request Type',
                                  }}
                                  errors={errors}
                                  onChange={(e) => {
                                    setMedicalRequestType(e.target.value);
                                    setPersonalRequestType('');
                                  }}
                                  val={getValues(
                                    conditionalFields[2].fieldName
                                  )}
                                  disabled={location?.state?.isView}
                                />

                                {medicalDetails.map((details) => (
                                  <>
                                    <label className="block text-sm text-gray-800 font-bold mb-3 pr-4">
                                      {details.fieldTitle}
                                    </label>
                                    <InputField
                                      fieldName={details.fieldName}
                                      validationObj={validationObj}
                                      register={register}
                                      errors={errors}
                                      fieldType={details.fieldType}
                                      val={getValues(details.fieldName)}
                                      disabled={location?.state?.isView}
                                    />
                                  </>
                                ))}
                              </Grid>
                            )}
                          </>
                          {requestType === 'other' && (
                            <Grid item xs={12}>
                              <>
                                <label className="block text-sm text-gray-800 font-bold mb-3 pr-4">
                                  {conditionalFields[4].fieldTitle}
                                </label>
                                <InputField
                                  fieldName={conditionalFields[4].fieldName}
                                  validationObj={validationObj}
                                  register={register}
                                  errors={errors}
                                  fieldType={conditionalFields[4].fieldType}
                                  val={getValues(
                                    conditionalFields[4].fieldName
                                  )}
                                  disabled={location?.state?.isView}
                                />
                              </>
                            </Grid>
                          )}
                        </>
                      ) : null
                    ) : (
                      <>
                        <label className="block text-sm text-gray-800 font-bold mb-3 pr-4">
                          {fieldTitle}
                        </label>
                        <InputField
                          fieldName={fieldName}
                          validationObj={validationObj}
                          register={register}
                          disabled={
                            fieldName === 'ticketNo'
                              ? true
                              : location?.state?.isView
                          }
                          errors={errors}
                          fieldType={fieldType}
                        />
                      </>
                    )}
                  </Grid>
                );
              })}
          </Grid>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <div
                className="flex justify-between items-center text-white rounded-md bg-gray-700 p-2 mb-1 cursor-pointer"
                onClick={refferalToggleAccordion} // Toggle on click
              >
                <h2> Refferal Details</h2>
                {/* Render the icon based on the toggle state */}
                {isRefferalToggle ? <FaChevronUp /> : <FaChevronDown />}
              </div>
            </Grid>

            {isRefferalToggle &&
              referalFields.map((field) => {
                const {
                  fieldTitle,
                  fieldName,
                  fieldType,
                  validationObj,
                  options,
                } = field;

                return (
                  <Grid
                    item
                    xs={12}
                    key={fieldName}
                    lg={
                      fieldName === 'additionalInformation' ||
                      fieldName === 'requestDescription'
                        ? 6
                        : 2
                    }
                  >
                    <label className="block text-sm text-gray-800 font-bold mb-3 pr-4">
                      {fieldTitle}
                    </label>

                    {fieldType === 'select' ? (
                      <SelectDropdown
                        fieldName={fieldName}
                        register={register}
                        options={
                          fieldName === 'referralType'
                            ? referralType
                            : referralMode
                        }
                        validationObj={validationObj}
                        errors={errors}
                        disabled={location?.state?.isView}
                        val={getValues(
                          fieldName === 'referralType'
                            ? 'referralType'
                            : 'referralMode'
                        )}
                      />
                    ) : fieldName === 'referralContact' ? (
                      <>
                        {/* <label className="block text-gray-800 text-sm font-bold mb-3 pr-4">
                          {fieldTitle}
                        </label> */}
                        <PhoneInput
                          className="shadow appearance-none border border-solid border-gray-700 rounded-xl w-full py-2.5 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-blue-500 disabled:bg-gray-200"
                          country="IN"
                          name={fieldName}
                          defaultValue={getValues(fieldName)}
                          control={control}
                          placeholder="Phone Number"
                          onBlur={handleBlur}
                        />
                      </>
                    ) : (
                      <InputField
                        fieldName={fieldName}
                        validationObj={validationObj}
                        register={register}
                        errors={errors}
                        fieldType={fieldType}
                        disabled={location?.state?.isView}
                        value={getValues(fieldName)}
                      />
                    )}
                  </Grid>
                );
              })}
          </Grid>

          <TicketCharter
            taskId={id}
            ticketNo={ticketNo}
            isView={location?.state?.isView}
            noteStatus="inprogress"
          />

          <div className="flex flex-col lg:flex-row items-center justify-center space-x-4">
            <Grid item>
              <Button
                variant="contained"
                color="error"
                onClick={onCancel}
                className="mt-5"
              >
                {location?.state?.isView ? 'Back' : 'Cancel'}
              </Button>
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                color="secondary"
                type="submit"
                className="mt-5"
                disabled={location?.state?.isView}
                value="save"
                onClick={handleSubmit(onSubmit)}
              >
                Save
              </Button>
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                color="primary"
                type="submit"
                className="mt-5"
                disabled={location?.state?.isView}
                value="submit"
                onClick={handleSubmit(onSubmit)}
              >
                {isEdit ? 'Update' : 'Save & Submit'}
              </Button>
            </Grid>
          </div>
        </form>
      </div>
    </>
  );
};

export default AddGms;
