import React from 'react';
import SecondHomePage from './Components/HomePage/SecondHomePage';

function App() {
  return (
    <>
      <SecondHomePage />
    </>
  );
}

export default App;
