import Navbar from '../Navbar/navbar';
const Scorecard = () => {
  return (
    <>
      <Navbar />
      <div className="flex justify-center p-4 lg:p-12 flex-col mt-2 lg:mt-4 lg:mb-10 lg:col-span-5 font-manrope">
        <h2 className="text-2xl lg:text-4xl mb-4">Achievements</h2>
      </div>
    </>
  );
};
export default Scorecard;
