import { useEffect, useState } from 'react';
import { postRequest } from '../interceptor/interceptor';
import moment from 'moment';
import {
  VerticalTimeline,
  VerticalTimelineElement,
} from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import { GET_AUDIT_HISTORY } from '../../apiConst';
import { FaCalendarDays, FaRegStar } from 'react-icons/fa6';

const Logs = ({ ticketNo }) => {
  const [logs, setLogs] = useState([]);

  useEffect(() => {
    fetchLogs();
  }, []);

  const fetchLogs = () => {
    let payload = {
      filter: { ticketNo: ticketNo },
    };

    postRequest(GET_AUDIT_HISTORY, payload)
      .then((res) => {
        setLogs(res.data.data.result);
      })
      .catch((error) => {
        console.log('🚀 ~ file: Logs.jsx:17 ~ fetcLogs ~ error:', error);
      });
  };

  return (
    <div>
      <VerticalTimeline lineColor={'#2196f3'}>
        {logs.map((item) => {
          const { createdAt } = item;

          // Format the createdAt date for display
          const formattedDate = moment(createdAt).format('DD-MM-YYYY, h:mm a');

          return (
            <VerticalTimelineElement
              key={item._id}
              className="vertical-timeline-element--work"  
              contentStyle={{
                background: '#2196f3',
                color: '#000',
              }}
              contentArrowStyle={{
                borderRight: '7px solid #2196f3',
              }}
              date={formattedDate}
              iconStyle={{ background: '#2196f3', color: '#fff' }}
              icon={<FaCalendarDays />}
            >
              {/* Dynamically render each key-value pair */}
              {Object.keys(item).map((key) => {
                // Skip the 'createdAt', '_id', '__v', and 'ticketNo' fields
                if (
                  key === 'createdAt' ||
                  key === '_id' ||
                  key === '__v' ||
                  key === 'ticketNo' ||
                  key === 'updatedAt'
                ) {
                  return null;
                }

                const value = item[key];

                // Handle nested objects (like 'ticketStatus', 'referralType', etc.)
                if (typeof value === 'object' && value !== null) {
                  return (
                    <div key={key}>
                      <strong>
                        {key.charAt(0).toUpperCase() + key.slice(1)}:
                      </strong>{' '}
                      {Object.entries(value).map(([nestedKey, nestedValue]) => (
                        <div key={nestedKey}>
                          <strong>
                            {nestedKey.charAt(0).toUpperCase() +
                              nestedKey.slice(1)}
                            :
                          </strong>{' '}
                          {nestedValue}
                        </div>
                      ))}
                    </div>
                  );
                }

                // For regular key-value pairs
                return (
                  <div key={key}>
                    <strong>
                      {key.charAt(0).toUpperCase() + key.slice(1)}:
                    </strong>{' '}
                    {value}
                  </div>
                );
              })}
            </VerticalTimelineElement>
          );
        })}
        <VerticalTimelineElement
          iconStyle={{ background: 'red', color: '#fff' }}
          icon={<FaRegStar />}
        />
      </VerticalTimeline>
    </div>
  );
};
export default Logs;
