import React from 'react';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));

export default function CustomModal({
    heading,
    pressCloseButton,
    body,
    modal,
}) {
    return (
        <div>
            <React.Fragment>
                <BootstrapDialog
                    onClose={pressCloseButton}
                    aria-labelledby="customized-dialog-title"
                    open={modal}
                    fullWidth
                >
                    <DialogTitle sx={{ m: 0, p: 1, backgroundColor: '#20488a', color: 'white' }} id="customized-dialog-title">
                        {heading}
                    </DialogTitle>
                    <IconButton
                        aria-label="close"
                        onClick={() => pressCloseButton()}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 4,
                            color: 'white',
                        }}
                    >
                        X
                    </IconButton>
                    <DialogContent dividers>
                        {body}
                    </DialogContent>

                </BootstrapDialog>
            </React.Fragment>


        </div>
    );
}
