import { useState, useEffect, useCallback } from 'react';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { Button } from '@mui/material';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import {
  getRequest,
  postRequest,
  putRequest,
  deleteRequest,
} from '../interceptor/interceptor';
import {
  GET_CALENDAR_EVENTS,
  CREATE_CALENDAR_EVENT,
  UPDATE_CALENDAR_EVENT,
  DELETE_CALENDAR_EVENT,
} from '../../apiConst';
import FullScreenLoader from '../Loader/FullScreenLoader';
import CustomModal from '../../Common/CustomModal';
import { SiGooglemeet } from 'react-icons/si';
import { FaClock, FaUsers, FaUser } from 'react-icons/fa6';
import { MdEventNote } from 'react-icons/md';
import { eventFields } from '../../Fields/eventFields';
import { Controller, useForm } from 'react-hook-form';
import InputField from '../.././Common/InputField';
import DateField from '../../Common/DateField';

const PortalCalendar = () => {
  const localizer = momentLocalizer(moment);
  const [isLoading, setIsLoading] = useState(false);
  const [eventsList, setEventsList] = useState([]);
  const [viewEventModal, setViewEventModal] = useState(false);
  const [createEventModal, setCreateEventModal] = useState(false);
  const [eventDetails, setEventDetails] = useState();
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [guestsList, setGuestsList] = useState([]);
  const [isEdit, setIsEdit] = useState(false);

  const {
    register,
    handleSubmit,
    setValue,
    reset,
    resetField,
    getValues,
    control,
    formState: { errors },
  } = useForm({});

  const pressViewEventCloseButton = () => {
    setViewEventModal(!viewEventModal);
  };

  const pressCreateEventCloseButton = () => {
    setCreateEventModal(!createEventModal);
    reset();
    setGuestsList([]);
  };

  const handleDelete = async (id) => {
    setIsLoading(true);
    deleteRequest(`${DELETE_CALENDAR_EVENT}${id}`)
      .then((res) => {
        if (res) {
          setIsLoading(false);
          setViewEventModal(false);
          fetchEvents();
        }
      })
      .catch(() => {
        setIsLoading(false);
      });
    setIsLoading(false);
  };

  const views = {
    month: true,
    week: true,
    day: true,
    agenda: true,
  };

  useEffect(() => {
    fetchEvents();
  }, []);

  const fetchEvents = () => {
    setIsLoading(true);

    getRequest(GET_CALENDAR_EVENTS)
      .then((res) => {
        if (res) {
          const events = res.map((item) => ({
            ...item,
            start: new Date(item.start),
            end: new Date(item.end),
          }));
          setEventsList(events);
        }
        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
      });
  };

  const handleSelectSlot = () => {
    console.log('hello');
    setCreateEventModal(true);
  };

  const handleSelectEvent = useCallback(
    (event) => (setViewEventModal(true), setEventDetails(event)),
    []
  );

  const handleAddGuest = (data) => {
    //console.log('data+++++', data);
    // let event = new Date(data?.startDate);
    // console.log('ISO---->', event.toISOString());
    // console.log('startDate', moment(data?.startDate).format());
    const newArray = [...guestsList];
    let updatedGuests = newArray.concat({ email: data?.attendees });
    const uniqueGuests = [
      ...new Set(updatedGuests.map((guest) => JSON.stringify(guest))),
    ].map((uniqueGuests) => JSON.parse(uniqueGuests));
    console.log('uniqueGuests----', uniqueGuests);
    setGuestsList(uniqueGuests);
    resetField('attendees');
  };

  const onSubmit = (data) => {
    let sDate = new Date(data?.startDate);
    let eDate = new Date(data?.endDate);

    setIsLoading(true);

    data = {
      ...data,
      startDate: sDate.toISOString(),
      endDate: eDate.toISOString(),
      attendees: guestsList,
    };

    let url = '';

    if (isEdit) {
      url = putRequest(`${UPDATE_CALENDAR_EVENT}`, data);
    } else {
      url = postRequest(`${CREATE_CALENDAR_EVENT}`, data);
    }
    url
      .then((res) => {
        setIsLoading(false);
        reset();
        setCreateEventModal(false);
        fetchEvents();
      })
      .catch((e) => {
        console.log(e);
        setIsLoading(false);
      });
    setIsLoading(false);
  };

  const ViewEvent = () => {
    return (
      <div className="flex flex-col justify-between font-manrope">
        <span className="flex flex-row">
          <MdEventNote size={24} className="mr-4" />
          <span className="ml-4 text-xl">{eventDetails.title}</span>
        </span>
        <span className="flex flex-row mt-2">
          <FaUser size={24} className="mr-4" />
          <span className="ml-4">
            <Link
              className="text-blue-500"
              to={`mailTo:${eventDetails?.organizer?.email}`}
            >
              {eventDetails?.organizer?.email}
            </Link>
          </span>
        </span>
        <span className="flex flex-row mt-2">
          <FaClock size={24} className="mr-4" />
          <p className="ml-4">
            {moment(eventDetails.start).format('LLL')} -{' '}
            {moment(eventDetails.end).format('LLL')}
          </p>
        </span>
        <span className="flex flex-row mt-2">
          <SiGooglemeet size={24} className="mr-4" />
          <div class="flex items-center justify-center">
            <Link
              className="ml-4 inline-block"
              to={eventDetails.meetingLink}
              target="_blank"
            >
              <div class="flex items-center justify-between h-12 text-white bg-purple-600 rounded-lg shadow-md">
                <div class="flex flex-col px-2">
                  <img
                    src={
                      eventDetails?.logo
                        ? eventDetails?.logo
                        : `${process.env.REACT_APP_S3_URL}/googleMeet.png`
                    }
                    alt="meet"
                    className="h-12 w-12"
                  />
                </div>
                <span className="py-2 pr-2 ml-4">Join Now!</span>
              </div>
            </Link>
          </div>
        </span>
        <span className="flex flex-row mt-2">
          <FaUsers size={24} className="mr-4" />
          <span className="ml-4 flex flex-wrap justify-start">
            {eventDetails.guests &&
              eventDetails.guests.map((guest) => (
                <Link
                  className="text-blue-500 ml-2"
                  key={guest.email}
                  to={`mailTo:${guest.email}`}
                >
                  {guest.email}
                </Link>
              ))}
          </span>
        </span>
        <div className="flex justify-center">
          {/* <Button
            type="button"
            variant="contained"
            color="info"
            className="mt-4 px-6 py-2 rounded-md mr-2 text-white transition duration-150"
            onClick={pressViewEventCloseButton}
          >
            Cancel
          </Button> */}
          <Button
            type="button"
            variant="contained"
            color="error"
            className="mt-4 px-6 py-2 rounded-md ml-2 text-white transition duration-150 "
            onClick={() => handleDelete(eventDetails?.id)}
          >
            Delete
          </Button>
        </div>
      </div>
    );
  };

  const CreateEvent = () => {
    return (
      <div className="flex flex-col justify-between font-manrope">
        <form className="w-full flex justify-center items-center flex-col p-2">
          <div className="w-full grid md:grid-cols-1 gap-1">
            {eventFields?.map((field) => {
              const {
                fieldTitle,
                fieldName,
                fieldType,
                validationObj,
                disabled,
              } = field;
              return (
                <div className="flex flex-col w-full" key={fieldName}>
                  <label className="block text-gray-800 font-bold mb-1 pr-4">
                    {fieldTitle}
                  </label>
                  <div className="w-full flex items-center">
                    <div className="w-full">
                      {fieldType === 'date' ? (
                        <>
                          <DateField
                            fieldName={fieldName}
                            validationObj={validationObj}
                            control={control}
                            Controller={Controller}
                            minDate={new Date()}
                            errors={errors}
                            timerequired={true}
                          />
                        </>
                      ) : fieldName === 'attendees' ? (
                        <div className="flex flex-col lg:grid lg:grid-cols-2 lg:gap-1 justify-between">
                          <InputField
                            fieldName={fieldName}
                            validationObj={validationObj}
                            register={register}
                            errors={errors}
                            fieldType={fieldType}
                            disabled={disabled}
                            placeholder={fieldTitle}
                            className="border border-gray-300 rounded-md p-2 w-full focus:outline-none focus:ring focus:ring-primary-300"
                          />
                          <Button
                            type="button"
                            variant="contained"
                            color="primary"
                            className="mt-4 lg:mt-0 lg:h-10 lg:w-48  rounded-md ml-2 text-white transition duration-150"
                            onClick={handleSubmit(handleAddGuest)}
                          >
                            Add Guest
                          </Button>
                        </div>
                      ) : (
                        <InputField
                          fieldName={fieldName}
                          validationObj={validationObj}
                          register={register}
                          errors={errors}
                          fieldType={fieldType}
                          disabled={disabled}
                          placeholder={fieldTitle}
                          className="border border-gray-300 rounded-md p-2 w-full focus:outline-none focus:ring focus:ring-primary-300"
                        />
                      )}
                    </div>
                  </div>
                </div>
              );
            })}
            <span>
              <label className="block text-gray-800 font-bold mb-1 pr-4">
                Guests List
              </label>
              {guestsList &&
                guestsList?.map((guest) => (
                  <div className="flex flex-row justify-start flex-wrap">
                    <span key={guest.email}>{guest.email}</span>
                  </div>
                ))}
            </span>
            <div className="flex justify-center">
              <Button
                type="button"
                variant="contained"
                color="error"
                className="mt-4 px-6 py-2 rounded-md mr-2 text-white transition duration-150"
                onClick={pressCreateEventCloseButton}
              >
                Cancel
              </Button>
              <Button
                type="button"
                variant="contained"
                color="primary"
                className="mt-4 px-6 py-2 rounded-md ml-2 text-white transition duration-150 "
                onClick={handleSubmit(onSubmit)}
              >
                Create
              </Button>
            </div>
          </div>
        </form>
      </div>
    );
  };

  return (
    <>
      <CustomModal
        modal={viewEventModal}
        body={<ViewEvent />}
        heading="Event Details"
        pressCloseButton={pressViewEventCloseButton}
      />
      <CustomModal
        modal={createEventModal}
        body={<CreateEvent />}
        heading="Create Event"
        pressCloseButton={pressCreateEventCloseButton}
      />
      {isLoading && <FullScreenLoader />}
      <div
        className="flex flex-col"
        style={{
          width: '90%',
          paddingTop: '50px',
          paddingBottom: '50px',
          margin: 'auto',
          maxWidth: '1200px',
        }}
      >
        <div className="self-end">
          <Button
            className="lg:w-48 mb-2"
            type="button"
            onClick={() => handleSelectSlot()}
            variant="contained"
            color="info"
          >
            Create Event
          </Button>
        </div>
        <Calendar
          localizer={localizer}
          events={eventsList}
          startAccessor="start"
          endAccessor="end"
          views={views}
          style={{ height: 500 }}
          onSelectEvent={handleSelectEvent}
          selectable
          popup
        />
      </div>
    </>
  );
};
export default PortalCalendar;
