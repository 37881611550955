import React from 'react';
import { LinkedInCallback } from 'react-linkedin-login-oauth2';
import { Route, Routes, BrowserRouter, Navigate } from 'react-router-dom';
import App from './App';
import Login from './Components/Login';
import TicketsGms from './Components/Tasks';
import Dashboard from './Components/Dashboard';
import AddGms from './Components/Tasks/AddGms';
import ProtectedRoute from './protectedRoutes';
import { useSelector } from 'react-redux';
import StepperForm from './Components/Tasks/Stepper';
import PortalCalendar from './Components/PortalCalendar/PortalCalendar';
import SecondHomePage from './Components/HomePage/SecondHomePage';
const Routing = (props) => {
  const userToken = useSelector((state) => state.user.token);
  const allRoutes = () => {
    return (
      <BrowserRouter>
        <Routes>
          <Route
            path="/gms"
            element={
              userToken ? (
                <ProtectedRoute>
                  <TicketsGms />
                </ProtectedRoute>
              ) : (
                <Login />
              )
            }
          />
          <Route
            path="/dashboard"
            element={
              userToken ? (
                <ProtectedRoute>
                  <Dashboard />
                </ProtectedRoute>
              ) : (
                <Login />
              )
            }
          />
          <Route
            path="/calendar"
            element={
              userToken ? (
                <ProtectedRoute>
                  <PortalCalendar />
                </ProtectedRoute>
              ) : (
                <Login />
              )
            }
          />
          <Route
            path="/AddGms"
            element={
              userToken ? (
                <ProtectedRoute>
                  <AddGms />
                </ProtectedRoute>
              ) : (
                <Login />
              )
            }
          />
          <Route
            path="/stepper-form"
            element={
              userToken ? (
                <ProtectedRoute>
                  <StepperForm />
                </ProtectedRoute>
              ) : (
                <Login />
              )
            }
          />
          <Route path="/linkedIn" element={<LinkedInCallback />} />
          <Route path="/seva-login" element={<Login />} />
          {/* <Route path="/homepage-1" element={<SecondHomePage />} /> */}
          <Route path="/" element={<App />} />
          <Route path="*" element={<Navigate to="/" replace />} />
          <Route path="/home" element={<App />} />
        </Routes>
      </BrowserRouter>
    );
  };

  return <>{allRoutes(props)}</>;
};

export default Routing;
