import { useEffect, useState } from 'react';
import { get } from 'lodash';
import { Controller, useForm } from 'react-hook-form';
import InputField from '../.././Common/InputField';
import CheckboxField from '../.././Common/CheckboxField';
import { Grid, Button } from '@mui/material';
import TicketCharter from '.././Ticket-Notes/TicketCharter';
import DateField from '../../Common/DateField';
import { State, City } from 'country-state-city';

import {
  postRequest,
  putRequest,
  getRequest,
} from '.././interceptor/interceptor';
import FullScreenLoader from '.././Loader/FullScreenLoader';
import {
  UPDATE_TICKET,
  ADD_TICKET,
  UNIQUE_ID,
  REFERRAL_DATA_BY_CONTACT,
} from '../.././apiConst';
import ApplicationTabs from './ApplicationTabs';
import { useLocation, useNavigate } from 'react-router';
import SelectDropdown from '../.././Common/SelectDropdown';
import {
  grievanceStepperFields,
  referalFields,
  applicantDetailsFields,
  approvalActionFields,
} from '../.././Fields/grievanceFields';
import CkEditorComponent from '../.././Common/CkEditor';
import moment from 'moment';

const ApplicantDetails = ({ task }) => {
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(false);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const fields = applicantDetailsFields;
  const [id, setId] = useState(null);
  const [isDisabledTicket, setIsDisabledTicket] = useState(false);
  const [ticketNo, setTicketNo] = useState(
    location?.state?.data?.task?.ticketNo || null
  );
  const [isEdit, setIsEdit] = useState(null);
  const [requestDetails, setRequestDetails] = useState(false);
  const [other, setOther] = useState('');
  const [referral, setReferral] = useState(false);
  const [ticket, setTicket] = useState(null);
  const [disableAllFields, setDisableAllFields] = useState(false);

  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    setValue,
    reset,
    getValues,
    control,
    formState: { errors },
  } = useForm({});

  useEffect(() => {
    console.log('task', task);
    // Set form fields using setValue
    fields.map((field) => setValue(field.fieldName, task[field.fieldName]));
    setTicketNo(task.ticketNo);

    // Set individual form fields
    setValue('state', task?.state);
    setValue('district', task?.district);
    setValue('constituency', task?.constituency);
    setValue('mandal', task?.mandal);
  }, []);

  useEffect(() => {
    const indiaStates = State.getStatesOfCountry('IN');
    const indiaCities = City.getCitiesOfCountry('IN');
    setStates(indiaStates);
    setCities(indiaCities);
  }, []);

  const onSubmit = (data) => {
    console.log('data', data);
    let url = '';
    setIsLoading(true);
    data = {
      ...data,
    };
    if (isEdit) {
      url = putRequest(`${UPDATE_TICKET}`, data);
    } else {
      url = postRequest(`${ADD_TICKET}`, data);
    }
    url
      .then((res) => {
        setIsLoading(false);
        navigate('/gms');
      })
      .catch(() => {
        setIsLoading(false);
      });
  };

  return (
    <>
      {isLoading && <FullScreenLoader />}
      <div className="block rounded-lg bg-white p-6 shadow-[0_2px_15px_-3px_rgba(0,0,0,0.07),0_10px_20px_-2px_rgba(0,0,0,0.04)] m-4 overflow-auto">
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={3}>
            {fields.map((field) => {
              const {
                fieldTitle,
                fieldName,
                fieldType,
                validationObj,
                options,
              } = field;

              return (
                <Grid item xs={12} sm={4} key={fieldName}>
                  {fieldType === 'date' ? (
                    <>
                      <label className="block text-gray-800 font-bold mb-3 pr-4">
                        {fieldTitle}
                      </label>
                      <DateField
                        fieldName={fieldName}
                        validationObj={validationObj}
                        control={control}
                        Controller={Controller}
                        minDate={new Date()}
                        errors={errors}
                        defaultValue={getValues(fieldName)}
                        disabled={true}
                      />
                    </>
                  ) : fieldType === 'editor' ? (
                    <>
                      <label className="block text-gray-800 font-bold mb-3 pr-4">
                        {fieldTitle}
                      </label>
                      <CkEditorComponent
                        register={register}
                        fieldName={fieldName}
                        control={control}
                        data={getValues(fieldName) || ''}
                        setValue={setValue}
                        disabled={true}
                      />
                    </>
                  ) : fieldType === 'select' && fieldName === 'state' ? (
                    <>
                      <label className="block text-gray-800 font-bold mb-3 pr-4">
                        {fieldTitle}
                      </label>
                      <SelectDropdown
                        fieldName="state"
                        options={states.map((state) => ({
                          label: state.name,
                          value: state.isoCode,
                        }))}
                        register={register}
                        validationObj={{ required: 'Please Select State' }}
                        errors={errors}
                        val={getValues(fieldName)}
                        disabled={true}
                      />
                    </>
                  ) : fieldName === 'district' ? (
                    <>
                      <label className="block text-gray-800 font-bold mb-3 pr-4">
                        {fieldTitle}
                      </label>
                      <SelectDropdown
                        fieldName="district"
                        options={cities.map((city) => ({
                          label: city.name,
                          value: city.name,
                        }))}
                        register={register}
                        validationObj={{ required: 'Please Select District' }}
                        errors={errors}
                        val={getValues(fieldName)}
                        disabled={true}
                      />
                    </>
                  ) : (
                    <>
                      <label className="block text-gray-800 font-bold mb-3 pr-4">
                        {fieldTitle}
                      </label>
                      <InputField
                        fieldName={fieldName}
                        validationObj={validationObj}
                        register={register}
                        disabled={true}
                        errors={errors}
                        fieldType={fieldType}
                      />
                    </>
                  )}
                </Grid>
              );
            })}
          </Grid>
        </form>
      </div>
    </>
  );
};

export default ApplicantDetails;
