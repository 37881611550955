import { capitalize } from 'lodash';
import React, { useState, useEffect } from 'react';

const SelectDropdown = ({
  fieldId,
  selected,
  fieldName,
  fieldLabel,
  fieldType,
  register,
  validationObj,
  placeholder,
  onChange,
  className = 'shadow appearance-none border border-solid border-gray-700 rounded-xl w-full py-2.5 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-blue-500 disabled:bg-gray-200',
  errors,
  children,
  multiple,
  required,
  disabled,
  value,
  onClick,
  options,
  optionsLabelKey = 'label',
  optionsValueKey = 'value',
  emptyOption = true,
  val
}) => {
  const [defaultValue, setDefaultValue] = useState(selected || []);
  const isProjectField = fieldName === 'project';

  useEffect(() => {
    if (isProjectField && multiple && options) {
      const allOptionValues = options.map(option => option[optionsValueKey]);
      setDefaultValue(allOptionValues);
      register(fieldName, { value: allOptionValues });
      if (onChange) {
        onChange({
          target: {
            name: fieldName,
            value: allOptionValues
          }
        });
      }
    }
  }, [fieldName, multiple, options, register, isProjectField, onChange]);

  const handleChange = (e) => {
    const value = Array.from(e.target.selectedOptions, option => option.value);
    setDefaultValue(value);
    if (onChange) onChange(e);
  };

  return (
    <div className="mb-4 w-full">
      <select
        key={fieldName}
        id={fieldId}
        name={fieldName}
        {...register(fieldName, {
          onChange: handleChange,
          ...validationObj,
        })}
        type={fieldType}
        className={className}
        placeholder={placeholder}
        disabled={disabled}
        multiple={multiple}
        value={val?val:defaultValue}
        
      >
        {emptyOption && (
          <option value="">{placeholder ? placeholder : 'Select'}</option>
        )}
        {options && Array.isArray(options)
          ? options.map((option, index) => (
            <React.Fragment key={index}>
              {Array.isArray(option) ? (
                option.map((data, key) => (
                  <option
                    key={data[optionsValueKey] + key}
                    label={data[optionsLabelKey]}
                    value={data[optionsValueKey]}
                    selected={selected}
                  >
                    {data[optionsLabelKey]}
                  </option>
                ))
              ) : (
                <option
                  key={option[optionsLabelKey] + index}
                  data={option.data}
                  value={option[optionsValueKey]}
                  selected={selected}
                >
                  {option[optionsLabelKey]}
                </option>
              )}
            </React.Fragment>
          ))
          : options
            ? Object.keys(options).map((option, index) => (
              <option key={options[option]} value={option}  selected={selected}>
                {capitalize(options[option])}
              </option>
            ))
            : null}
      </select>
      {errors[fieldName] && (
        <p className="py-2 text-danger">
          {errors[fieldName].message}
        </p>
      )}
    </div>
  );
};

export default SelectDropdown;
