import { Step, Stepper } from 'react-form-stepper';
import { useDispatch, useSelector } from 'react-redux';
import { setTrackingStateIndex } from '../../redux/actions/trackingState.action';
import { useEffect } from 'react';
import { isEmpty } from 'lodash';
import { Grid, Button } from '@mui/material';
import DoneIcon from '@mui/icons-material/Done';
import { Navigate } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import AddGms from './AddGms';
import Form from './Form';
const steps = [
  { label: 'New' },
  { label: 'In Progress' },
  { label: 'Approved/Rejected' },
  { label: 'Resolved' },
];

const StepperForm = () => {
  const trackingState = useSelector((state) => state.tracking.trackingState);
  console.log('trackingState', trackingState);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const onSubmit = () => {
    dispatch(setTrackingStateIndex(Number(trackingState) + 1));
  };
  const onStpesClick = (index) => {
    dispatch(setTrackingStateIndex(index));
  };

  return (
    <div>
      <div className="w-full">
        <Stepper
          activeStep={trackingState}
          steps={steps}
          styleConfig={{
            activeBgColor: '#189620',
            activeTextColor: '#fff',
            completedBgColor: '#1D4ED8',
            completedTextColor: '#fff',
            inactiveBgColor: '#eee',
            inactiveTextColor: '#4a6cc9',
            fontWeight: 600,
          }}
        >
          {steps.map((item, index) => {
            const isCompleted = index < trackingState;
            return (
              <>
                <Step onClick={() => onStpesClick(index)} key={item.label}>
                  {isCompleted ? <DoneIcon /> : index + 1}
                </Step>
              </>
            );
          })}
        </Stepper>
      </div>
      <form className="w-full flex justify-center items-center flex-col border-2 border-gray-200 rounded-2xl shadow-xl">
        <div className={`${trackingState === 0 ? 'block w-full' : 'hidden'}`}>
          <Form />
        </div>
        <div className={`${trackingState === 1 ? 'block w-full' : 'hidden'}`}>
          <Form />
        </div>
        <div className={`${trackingState === 2 ? 'block w-full' : 'hidden'}`}>
          <Form />
        </div>
        <div className={`${trackingState >= 3 ? 'block w-full' : 'hidden'}`}>
          <Form disabled={true} />
        </div>
      </form>
    </div>
  );
};

export default StepperForm;
