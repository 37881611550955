import ImageCard from '../CardComponent/ImageCard';
import { Grid, Paper, Container } from '@mui/material';

const Section = () => {
  const newsData = [
    {
      title: "Rammohan Naidu is India's new minister of civil aviation",
      photo: 'https://taskresultspro.s3.amazonaws.com/slide8.png',
      link: 'https://www.stattimes.com/aviation/rammohan-naidu-is-indias-new-minister-of-civil-aviation-1352357',
      description: '',
    },
    {
      title:
        "'Ease of flying' top priority, will work to make airfares affordable, says Aviation Minister Ram Mohan Naidu",
      photo: 'https://taskresultspro.s3.amazonaws.com/slide3.png',
      link: 'https://indianexpress.com/article/business/aviation/kinjarapu-ram-mohan-naidu-civil-aviation-ministry-airfares-9390392/',
      description: '',
    },
    {
      title:
        'India envisions 350 to 400 airports in coming decades, says civil aviation minister Naidu',
      photo: 'https://taskresultspro.s3.amazonaws.com/slide9.png',
      link: 'https://timesofindia.indiatimes.com/business/india-business/india-envisions-350-to-400-airports-in-coming-decades-says-civil-aviation-minister-naidu/articleshow/113254485.cms',
      description: '',
    },
    {
      title: "India's New Aviation Minister",
      photo: 'https://taskresultspro.s3.amazonaws.com/slide4.png',
      link: 'https://www.business-standard.com/about/who-is-kinjarapu-ram-mohan-naidu',
      description: '',
    },
    {
      title: 'India to spend over Rs 92,000 cr to build and revamp airports',
      photo: 'https://taskresultspro.s3.amazonaws.com/slide2.png',
      link: 'https://economictimes.indiatimes.com/industry/transportation/airlines-/-aviation/india-to-spend-over-rs-92000-cr-to-build-and-revamp-airports-aviation-minister-ram-mohan-naidu/articleshow/114019335.cms?from=mdr',
      description: '',
    },
    {
      title: 'UDAAN scheme to continue for 10 more years',
      photo: 'https://taskresultspro.s3.amazonaws.com/slide6.png',
      link: 'https://economictimes.indiatimes.com/industry/transportation/airlines-/-aviation/udaan-scheme-to-continue-for-10-more-years-civil-aviation-minister-ram-mohan-naidu/articleshow/113195146.cms?from=mdr',
      description: '',
    },
  ];

  const goToLink = (link) => {
    window.open(link, '_blank');
  };
  return (
    <div
      id="news"
      className="flex justify-center p-4 lg:p-4 flex-col mb-2 lg:col-span-5 bg-[#1c263c] font-manrope"
    >
      <Container maxWidth="xl" sx={{ py: 1 }}>
        <h2 className="text-base mt-2 mb-2 lg:text-xl text-white font-manrope">
          News
        </h2>
        <Grid container spacing={2} className="mb-2" alignItems="stretch">
          {newsData.map((news) => (
            <Grid item xs={12} lg={4} className="flex w-1/3">
              <ImageCard
                title={news.title}
                photo={news.photo}
                description={news.description}
                goToLink={() => goToLink(news.link)}
              />
            </Grid>
          ))}
        </Grid>
      </Container>
    </div>
  );
};
export default Section;
