export const eventFields = [
  {
    fieldName: 'summary',
    fieldTitle: 'Event Title',
    validationObj: {
      required: 'Please Enter Event Title',
    },
    fieldType: 'text',
  },
  {
    fieldName: 'description',
    fieldTitle: 'Event Description',
    fieldType: 'textarea',
  },
  {
    fieldName: 'startDate',
    fieldTitle: 'Start Date',
    validationObj: {
      required: 'Please Enter Event Start Date',
    },
    fieldType: 'date',
  },
  {
    fieldName: 'endDate',
    fieldTitle: 'End Date',
    validationObj: {
      required: 'Please Enter Event End Date',
    },
    fieldType: 'date',
  },
  {
    fieldName: 'attendees',
    fieldTitle: 'Add Guest Email',
    fieldType: 'email',
  },
];
