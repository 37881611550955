import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import reportWebVitals from './reportWebVitals';
import Routing from './routes';
import store from './redux/store';
import { GoogleOAuthProvider } from '@react-oauth/google';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';
import './css/styles.css';
import './index.css';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { StyledEngineProvider } from '@mui/material/styles';
import { ThemeProvider } from '@material-tailwind/react';
import './i18n.js';

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <GoogleOAuthProvider clientId={process.env.REACT_APP_WEB_CLIENT_ID}>
    <Provider store={store}>
      <StyledEngineProvider injectFirst>
        <ThemeProvider>
          <Routing />
        </ThemeProvider>
        <ToastContainer theme="colored" />
      </StyledEngineProvider>
    </Provider>
  </GoogleOAuthProvider>
);

reportWebVitals();
