import LanguageSelector from '../../Common/LangauageSelector';
import About from '../About/About';
import Gallery from '../Gallery/Gallery';
import SecondFooter from '../Footer/SecondFooter';
import HeroBanner from '../Hero/HeroBanner';
import Section from '../Section/Section';
import Navbar from '../Navbar/navbar';

const SecondHomePage = () => {
  return (
    <div className="bg-[#455268]">
      <LanguageSelector />
      <div className="clear"></div>
      <Navbar />
      <HeroBanner />
      <Section />
      <Gallery />
      <About />
      <SecondFooter />
    </div>
  );
};
export default SecondHomePage;
