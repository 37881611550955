import React from 'react';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import 'react-datepicker/dist/react-datepicker.css';

const DateField = ({
  showTimeSelect,
  fieldName,
  control,
  Controller,
  dateFormat = 'dd/MM/yyyy',
  defaultValue,
  maxDate,
  minDate,
  placeholderText = 'Select Date',
  onBlur,
  className = 'shadow appearance-none border border-gray-700 rounded-xl w-full  py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-primary-300 disabled:bg-gray-200',
  errors,
  disabled,
  validationObj = {},
  onChange,
  excluded,
  highlighted,
  timerequired,
}) => {
  return (
    <Controller
      control={control}
      defaultValue={
        timerequired
          ? moment(defaultValue).format()
          : moment(defaultValue).format('YYYY-MM-DD') || ''
      }
      name={fieldName}
      rules={{ ...validationObj }}
      render={({
        field: { onChange, value },
        fieldState: { invalid, error },
      }) => {
        console.log('value', moment(value).format('L'));
        return (
          <div>
            {timerequired ? (
              <DatePicker
                selected={new Date(value)}
                onChange={onChange}
                onBlur={onBlur}
                showTimeSelect
                timeFormat="HH:mm"
                timeIntervals={15}
                timeCaption="Time"
                dateFormat="MMMM d, yyyy h:mm aa"
                className={className}
                minDate={minDate}
                maxDate={maxDate}
                showMonthDropdown
                showYearDropdown
                dropdownMode="select"
                defaultValue={new Date(defaultValue)}
                disabled={disabled}
                popperPlacement="bottom-end"
              />
            ) : (
              <DatePicker
                selected={moment(value).format('YYYY-MM-DD')}
                onChange={onChange}
                onBlur={onBlur}
                dateFormat={dateFormat}
                placeholderText={placeholderText}
                className={className}
                maxDate={maxDate}
                minDate={minDate}
                showMonthDropdown
                showYearDropdown
                dropdownMode="select"
                defaultValue={moment(defaultValue).format('YYYY-MM-DD')}
                disabled={disabled}
                highlightDates={highlighted?.map((date) => new Date(date))}
                excludeDates={excluded?.map((date) => new Date(date))}
              />
            )}
            <p className="py-2 text-danger">
              {errors[fieldName] && errors[fieldName].message}
            </p>
          </div>
        );
      }}
    />
  );
};

export default DateField;
