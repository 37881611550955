import { useTranslation } from 'react-i18next';

const HeroBanner = () => {
  const { t } = useTranslation();
  return (
    <div className="banner-wrapper">
      <div className="row" />
      <div className="clear"></div>
      <div className="banner">
        <div className="banner-bg bg-[#bdc6d5] flex flex-col lg:flex-row justify-evenly">
          <div className="panel w-full lg:w-[950px] pt-4 lg:pt-24">
            <div className="title mb-6">
              <h1 className="text-black font-playwrite text-xl md:text-2xl leading-tight sm:leading-tight md:leading-tight mb-2 lg:text-justify">
                Welcome to The Ministry of Civil Aviation, Government of India.
              </h1>
            </div>
            <div className="title mb-6">
              <h2 className="text-black text-base md:text-xl leading-tight sm:leading-tight md:leading-tight mb-2 lg:text-justify">
                {t('heroWelcome')}
              </h2>
            </div>
          </div>
          <div className="flex flex-col">
            <img
              className="object-center object-scale-down rounded lg:h-96"
              alt="hero"
              src={`${process.env.REACT_APP_S3_URL}/hero.webp`}
            />
            <span className="self-center lg:text-2xl font-playwrite whitespace-nowrap dark:text-white">
              Rammohan Naidu Kinjarapu
            </span>
            <span className="self-center lg:text-xl font-semibold whitespace-nowrap dark:text-white font-manrope">
              Honourable Minister of Civil Aviation
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};
export default HeroBanner;
