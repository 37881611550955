import React from 'react';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';

ChartJS.register(ArcElement, Tooltip, Legend);

function CircularChart({ newTickets, inProgress, onHold, resolved }) {
  const options = {
    maintainAspectRatio: false,
  };
  const data = {
    labels: [
      'Tickets New',
      'Tickets In Progress',
      'On Hold',
      'Tickets Resolved',
    ],
    datasets: [
      {
        label: 'Total Tickets',
        data: [newTickets, inProgress, onHold, resolved],
        backgroundColor: ['#f84450','#3457D5', '#FFBF00', '#22b573'],
        borderColor: [
          'rgba(255, 99, 132, 1)',
          'rgba(54, 162, 235, 1)',
          'rgba(255, 206, 86, 1)',
        ],
        borderWidth: 1,
      },
    ],
  };

  return (
    <div className="block rounded-lg bg-white p-6 shadow-[0_2px_15px_-3px_rgba(0,0,0,0.07),0_10px_20px_-2px_rgba(0,0,0,0.04)] overflow-auto">
      {newTickets > 0 || inProgress > 0 || resolved > 0 ? (
        <Doughnut options={options} data={data} />
      ) : (
        <p className="">No tickets on selected date!</p>
      )}
    </div>
  );
}

export default CircularChart;
