import { postRequest } from '../../Components/interceptor/interceptor';
import { GENERATE_OTP, VERIFY_OTP } from '../../apiConst';

export const generateOTPAction = (data) => ({
  type: 'GENERATE_OTP',
  payload: postRequest(`${GENERATE_OTP}`, data),
});

export const verifyOTPAction = (data) => ({
  type: 'VERIFY_OTP',
  payload: postRequest(`${VERIFY_OTP}`, data),
});

export const setUser = (data) => ({
  type: 'SET_USER',
  payload: { ...data },
});
export const removeUser = () => ({
  type: 'REMOVE_USER',
  payload: {},
});

export const setLogin = (data) => ({
  type: 'LOGIN',
  payload: { ...data },
});

export const setLogOut = () => {
  return async (dispatch) => {
    await dispatch({ type: 'LOGOUT' });
  };
};
