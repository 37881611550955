import React from "react";

const InputField = ({
  fieldId,
  fieldName,
  fieldLabel,
  fieldType,
  register,
  validationObj,
  placeholder,
  onChange,
  onBlur,
  className = 'shadow appearance-none border border-solid border-gray-700 rounded-xl w-full py-2.5 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-blue-500 disabled:bg-gray-200',
  errors,
  ref,
  children,
  multiple,
  required,
  disabled,
  value,
  onClick,
}) => {
  console.log('disabled',disabled)
  return (
    <div className="mb-4 w-full">
      {fieldType === "textarea" ? (
        <textarea
          name={fieldName}
          id={fieldId}
          {...register(fieldName, {
            onChange: (e) => onChange && onChange(e),
            onBlur: (e) => onBlur && onBlur(e),
            ...validationObj,
          })}
          className={className}
          placeholder={placeholder}
          disabled={disabled}
        />
      ) : (
        <input
          id={fieldId}
          name={fieldName}
          ref={ref}
          {...register(fieldName, {
            onChange: (e) => onChange && onChange(e),
            onBlur: (e) => onBlur && onBlur(e),
            ...validationObj,
          })}
          type={fieldType}
          className={className}
          placeholder={placeholder}
          disabled={disabled}
        />
      )}
      {errors[fieldName] && (
        <p className="py-2 text-danger">
          {errors[fieldName].message}
        </p>
      )}
    </div>
  );
};

export default InputField;
