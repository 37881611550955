export const centralTeam = [
  {
    Name: 'Shri Kinjarapu Rammohan Naidu',
    Designation: "Hon'ble Minister",
    Email: 'hm.moca@gov.in',
    Phone: ['24610350', '24632991'],
  },
  {
    Name: 'Shri Naupada Satyanarayana',
    Designation: 'Addl. PS to HMCA',
    Email: 'satya.naupada@gov.in',
    Phone: ['24610350'],
  },
  {
    Name: 'Shri Menda Latchanna',
    Designation: '1st PA to HMCA',
    Email: 'latchanna.menda@gov.in',
    Phone: ['24610350'],
  },
  {
    Name: 'Mr. Dhan Singh Bisht',
    Designation: 'LPA to HMCA',
    Email: 'dsbisht.moca@gov.in',
    Phone: ['2461035'],
  },
];

export const stateTeam = [
  {
    Name: 'Shri Vumlunmang Vualnam',
    Designation: 'Secretary',
    Email: 'secy.moca@nic.in',
    Phone: ['24610358'],
  },
  {
    Name: 'Shri P M Muralidharen',
    Designation: 'PSO to Secy (CA)',
    Email: 'secy.moca@nic.in',
    Phone: ['24610368'],
  },
  {
    Name: 'Shri Azeez Baig',
    Designation: 'PPS to Secy.',
    Email: 'secy.moca@nic.in',
    Phone: ['24610358'],
  },
  {
    Name: 'Shri Mahendra Singh Rawat',
    Designation: 'PPS to Secy.',
    Email: 'secy.moca@nic.in',
    Phone: ['24610358'],
  },

  {
    Name: 'Shri Padam Lal Negi',
    Designation: 'Joint Secretary and Financial Advisor',
    Email: 'faoffice-moca@gov.in',
    Phone: ['24643246'],
  },
  {
    Name: 'Smt. Manju Sharma',
    Designation: 'PSO to JS&FA',
    Email: 'manjukriti.edu@nic.in',
    Phone: ['24643246'],
  },
  {
    Name: 'Smt. Rubina Ali',
    Designation: 'JS(RA)',
    Email: 'rubina.ali@nic.in',
    Phone: ['24628012'],
  },
  {
    Name: 'Shri Satesh Kumar',
    Designation: 'PA to JS (RA)',
    Email: 'satesh.kumar9594@nic.in',
    Phone: ['24628012'],
  },
  {
    Name: 'Shri Shivraj Singh',
    Designation: 'PA to JS (RA)',
    Email: 'singh.shivraj55@gov.in',
    Phone: ['24628012'],
  },
  {
    Name: 'Shri Asangba Chuba Ao',
    Designation: 'Joint Secy. (ACA)',
    Email: 'jsdt-moca@gov.in',
    Phone: ['24617692'],
  },
  {
    Name: 'Shri Yogesh Kumar Butta',
    Designation: 'PPS to JS (AC)',
    Email: 'yogesh.butta@nic.in',
    Phone: ['24617692'],
  },
  {
    Name: 'Shri Pramod Kumar Thakur',
    Designation: 'Deputy Director General',
    Email: 'pk.thakur@nic.in',
    Phone: ['24610372'],
  },
  {
    Name: 'Shri Rajendra Kumar',
    Designation: 'PPS to DDG(PKT)',
    Email: 'rajender.k@nic.in',
    Phone: ['24610372'],
  },
  {
    Name: 'Shri. Shobhit Gupta',
    Designation: 'JS (SG)',
    Email: 'jsdg-moca@gov.in',
    Phone: ['24616303'],
  },
  {
    Name: 'Shri. U Murugan',
    Designation: 'PS to JS (SG)',
    Email: 'jsdg-moca@gov.in',
    Phone: ['24616303'],
  },
  {
    Name: 'Shri. Madhu Sudana Sankar',
    Designation: 'JS (MSS)',
    Email: 'madhu.sankar@gov.in',
    Phone: ['24610360'],
  },
  {
    Name: 'Smt. Neelam Grover',
    Designation: 'PPS to JS (MSS)',
    Email: 'neelu.grover@gov.in',
    Phone: ['24610360'],
  },
  {
    Name: 'Sh. Avtar Singh Sandhu',
    Designation: 'C.F.C.',
    Email: 'cfc.moca@gov.in',
    Phone: ['24629552'],
  },
  {
    Name: 'Shri. Sanjeev Kumar Singh',
    Designation: 'PA to CFC',
    Email: 'cfc.moca@gov.in',
    Phone: ['24629552'],
  },
  {
    Name: 'Shri. Rohit Raj',
    Designation: 'Director',
    Email: 'rohit.raj@gov.in',
    Phone: ['24649891'],
  },
  {
    Name: 'Ms. Shalini',
    Designation: 'Steno to Dir. (RR)',
    Email: 'shalini.1993@nic.in',
    Phone: ['24649891'],
  },
  {
    Name: 'Shri Joyanta Chakraborty',
    Designation: 'Director',
    Email: 'joy.chakraborty@gov.in',
    Phone: ['24610366'],
  },
  {
    Name: 'Ms. Chetna Chaudhray',
    Designation: 'Steno to Dir. (JC)',
    Email: 'chetna.chaudhary196@gov.in',
    Phone: ['24610366'],
  },
  {
    Name: 'Shri. Shankhesh Mehta',
    Designation: 'Director',
    Email: 'shankhesh.m@gov.in',
    Phone: ['24653750'],
  },
  {
    Name: 'Shri Kumar Saurabh Raj',
    Designation: 'Deputy Secretary',
    Email: 'saurabh.kumar11@ias.gov.in',
    Phone: ['24653565'],
  },
  {
    Name: 'Shri Ambuj Sharma',
    Designation: 'Deputy Secretary',
    Email: 'ambuj.sharma38@nic.in',
    Phone: ['24641912'],
  },
  {
    Name: 'Shri Brajesh Kumar Srivastava',
    Designation: 'Deputy Secretary',
    Email: 'brajesh.srivastava@gov.in',
    Phone: ['24619282'],
  },
  {
    Name: 'Shri Om Prakash Sharma',
    Designation: 'Director (OPS)',
    Email: 'opsharma@nic.in',
    Phone: ['24610987'],
  },
  {
    Name: 'Lt. Col. Manish Kumar Singh',
    Designation: 'Director(OL)',
    Email: 'manngh.043l@gov.in',
    Phone: ['24610370'],
  },
  {
    Name: 'Shri. Vinay Kumar',
    Designation: 'PA to Dir.(OL)',
    Email: 'vinay.moca@nic.in',
    Phone: ['24610370'],
  },
  {
    Name: 'Shri Manoj Kumar',
    Designation: 'Steno to DS. (AS)',
    Email: '',
    Phone: ['24641912'],
  },
  {
    Name: 'Ms. Anjali Rawat',
    Designation: 'PA to DS (BKS)',
    Email: '',
    Phone: [],
  },
  {
    Name: 'Shri Akash Kumar',
    Designation: 'Steno to DS (KSR)',
    Email: '',
    Phone: ['24653565'],
  },
  {
    Name: 'Shri Karan Sharma',
    Designation: 'Steno to Dir. (OPS)',
    Email: 'karan.sharma1628@gov.in',
    Phone: ['24619282'],
  },
  {
    Name: 'Shri Satish Chandra Tripathi',
    Designation: 'Deputy Director',
    Email: 'sctripathi.dgca@gov.in',
    Phone: ['24610386'],
  },
  {
    Name: 'Ms. Anju Rani',
    Designation: 'PA to Dir (SM)',
    Email: '',
    Phone: ['24653750'],
  },
  {
    Name: 'Ms. Nayonika Dutta',
    Designation: 'Joint Director',
    Email: 'nayonika.dutta@gov.in',
    Phone: ['24642145'],
  },
  {
    Name: 'Shri Vijayant Kumar Arora',
    Designation: 'Under Secretary(AP)',
    Email: 'vijayant_arora@nic.in',
    Phone: ['24617547'],
  },
  {
    Name: 'Shri Kameshwar Mishra',
    Designation: 'Under Secretary(KM)',
    Email: 'kameshwar.mishra@nic.in',
    Phone: ['24648983'],
  },
  {
    Name: 'Shri Vijay Pratap Singh',
    Designation: 'Under Secretary',
    Email: 'vijay.pratap@nic.in',
    Phone: ['24610364'],
  },
  {
    Name: 'Shri Suresh Babu',
    Designation: 'Under Secretary',
    Email: 'suresh.b67@gov.in',
    Phone: ['24640218'],
  },
  {
    Name: 'Shri Satay Prakash Ram Tripathi',
    Designation: 'Under Secretary',
    Email: 'spr.tripath@nic.in',
    Phone: ['24640217'],
  },
  {
    Name: 'Smt. Indu Butani',
    Designation: 'Under Secretary',
    Email: 'indubutani.moca@nic.in',
    Phone: ['24610374'],
  },
  {
    Name: 'Shri. Amit Kumar Jha',
    Designation: 'Under Secretary',
    Email: 'usad-moca@gov.in',
    Phone: ['24610361'],
  },
  {
    Name: 'Shri. Subir Narayan',
    Designation: 'Under Secretary',
    Email: '',
    Phone: ['24616025'],
  },
  {
    Name: 'Shri George Deepak Toppo',
    Designation: 'Under Secretary',
    Email: 'gd.toppo@nic.in',
    Phone: ['24342873'],
  },
  {
    Name: 'Shri Manoj Kumar Yadav',
    Designation: 'Under Secretary',
    Email: 'us.sdit-moca@gov.in',
    Phone: ['24640214'],
  },
  {
    Name: 'Shri Sunil Singh Rawat',
    Designation: 'Under Secretary',
    Email: 'sunils.rawat@nic.in',
    Phone: ['24610359'],
  },
  {
    Name: 'Ms. Padma Agnihotri',
    Designation: 'Under Secretary',
    Email: 'padma.agnihotri@gov.in',
    Phone: [],
  },
  {
    Name: 'Smt. Ritu Pathak',
    Designation: 'Finance officer/Sr.A.O',
    Email: 'budget-moca@gov.in',
    Phone: ['24632950', '24342864'],
  },
  {
    Name: 'Shri Yogesh Govil',
    Designation: 'Asstt. Dir.(OL)',
    Email: 'yogesh.govil66@nic.in',
    Phone: ['24610058'],
  },
  {
    Name: 'Shri Sumit Soni',
    Designation: 'S.O.(Admn)',
    Email: 'so-admn.moca@nic.in',
    Phone: ['24610060'],
  },
  {
    Name: 'Shri Ravinder Kumar Yadav',
    Designation: 'S.O.(DG)',
    Email: 'vesec.moca@nic.in',
    Phone: ['24695093'],
  },
  {
    Name: 'Shri Balram Yadav',
    Designation: 'S.O.(C&W)',
    Email: 'so-cw.moca@nic.in',
    Phone: ['24610378'],
  },
  {
    Name: 'Shri Neeraj Nara',
    Designation: 'S.O.(A)',
    Email: 'soa.moca@nic.in',
    Phone: ['24610375'],
  },
  {
    Name: 'Shri Punit',
    Designation: 'S.O.(A)',
    Email: 'soa.moca@nic.in',
    Phone: ['24610375'],
  },
  {
    Name: 'Shri Ashish Soni',
    Designation: 'S.O.(AI)',
    Email: 'so-ai.moca@nic.in',
    Phone: ['24640213'],
  },
  {
    Name: 'Shri Kaushal Upadhyay',
    Designation: 'S.O(AAI)',
    Email: 'soaai.moca@nic.in',
    Phone: ['24342865'],
  },
  {
    Name: 'Shri Deepak Kumar Yadav',
    Designation: 'S.O(AAI)',
    Email: 'soaai.moca@nic.in',
    Phone: ['24342865'],
  },
  {
    Name: 'Shri Krishna Kumar Singh',
    Designation: 'S.O.(AD)',
    Email: 'so-ad.moca@nic.in',
    Phone: ['24610057'],
  },
  {
    Name: 'Shri Krishan Swami',
    Designation: 'S.O.(ER)',
    Email: 'ersec.moca@nic.in',
    Phone: ['24618528'],
  },
  {
    Name: 'Ms. Manisha Arya',
    Designation: 'S.O.(Vig.)',
    Email: 'so-vig.moca@nic.in',
    Phone: ['24629323'],
  },
  {
    Name: 'Shri Yogesh',
    Designation: 'S.O.(AS)',
    Email: 'soas.moca@nic.in',
    Phone: ['24610059'],
  },
  {
    Name: 'Shri Jyoti Prakash Singh',
    Designation: 'S.O.(DT)',
    Email: 'dtsec.moca@nic.in',
    Phone: ['24610352'],
  },
  {
    Name: 'Shri Ashwani Kumar',
    Designation: 'S.O.(DT)',
    Email: 'dtsec.moca@nic.in',
    Phone: ['24610352'],
  },
  {
    Name: 'Shri Ajay Rana',
    Designation: 'S.O.(Cash)',
    Email: 'so.cash-moca@gov.in',
    Phone: ['24641674'],
  },
  {
    Name: 'Shri Surjeet Kumar Das',
    Designation: 'S.O.(Parliament)',
    Email: 'parli.moca@nic.in',
    Phone: ['24640215'],
  },
  {
    Name: 'Shri Ravi Raj',
    Designation: 'S.O (SDIT)',
    Email: 'raviraj.dgca@gov.in',
    Phone: ['24695094'],
  },
  {
    Name: 'Shri Anupam Raja',
    Designation: 'S.O. (Fin-1)',
    Email: 'sofin.moca@nic.in',
    Phone: ['24640218'],
  },
  {
    Name: 'Sh. Avtar Singh Sandhu',
    Designation: 'C.F.C.',
    Email: 'cfc.moca@gov.in',
    Phone: ['24629552'],
  },
  {
    Name: 'Shri. Sanjeev Kumar Singh',
    Designation: 'PA to CFC',
    Email: 'cfc.moca@gov.in',
    Phone: ['24621708'],
  },
  {
    Name: 'Shri. Manish Raut',
    Designation: 'Financial Controller',
    Email: 'manish.raut@gov.in',
    Phone: ['011-24654986'],
  },
  {
    Name: 'Shri Ajay Kumar Mishra',
    Designation: 'Sr AO (Sectt.)',
    Email: 'ajay.mishra77@gov.in',
    Phone: ['24610691'],
  },
  {
    Name: 'Shri Ram Krishna',
    Designation: 'AAO (Sectt.)',
    Email: 'ram.krishna@tgov.in',
    Phone: ['24610691'],
  },
  {
    Name: 'Smt. Punita Dogra',
    Designation: 'AAO (Sectt.)',
    Email: 'paosectt-moca@gov.in',
    Phone: ['24610691'],
  },
  {
    Name: 'Shri Durganath Swarnkar',
    Designation: 'Asst. Director (M&C)',
    Email: 'pibcivilaviation113@gmail.com',
    Phone: ['23488071'],
  },
  {
    Name: 'Shri Yogesh Baweja',
    Designation: 'Additional Director General (M&C)',
    Email: '',
    Phone: ['23383346'],
  },
];
