import { get } from "lodash";
import { DOCUMENT_UPLOAD } from "../apiConst";
import { postRequest } from "../Components/interceptor/interceptor";

class ImageUploadAdapter {
  constructor(loader) {
    this.loader = loader;
  }

  upload() {
    return this.loader.file.then((file) => {
      return new Promise((resolve, reject) => {
        // Replace the following code with your actual image upload logic

        const formData = new FormData();

        formData.append("file", file);

        postRequest(`${DOCUMENT_UPLOAD}`, formData, {
          "Content-Type": "multipart/form-data",
        })
          .then((res) => {
            console.log(res.data.data.url);
            resolve({
              default: get(res, "data.data.url"),
            });
          })
          .catch(() => {
            console.log("err");
          });

        // setTimeout(() => {
        //   const response = { default: "https://example.com/image.jpg" }; // Replace with the uploaded image URL

        // }, 2000);
      });
    });
  }
}

export default ImageUploadAdapter;
