const loginState = {
  isLoggedIn: false,
};

const userState = {
  firstName: null,
  lastName: null,
  userId: null,
  companyId: null,
  email: null,
  role: null,
  token: null,
};

export const loginReducer = (state = loginState, action) => {
  const { type, payload } = action;
  switch (type) {
    case 'LOGIN':
      return {
        ...state,
        ...payload,
      };
    case 'LOGOUT':
      return {
        ...state,
        isLoggedIn: false,
      };
    default:
      return { ...state };
  }
};

export const userReducer = (state = userState, action) => {
  const { type, payload } = action;
  switch (type) {
    case 'SET_USER':
      return {
        ...state,
        ...payload,
      };
    case 'GET_USER':
      return {
        ...payload,
      };
    case 'REMOVE_USER':
      return {
        ...state,
        firstName: null,
        lastName: null,
        userId: null,
        companyId: null,
        email: null,
        role: null,
        token: null,
      };
    default:
      return { ...state };
  }
};
