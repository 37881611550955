export const notesFields = [
  {
    fieldName: "note",
    fieldTitle: "Add Note",
    fieldType: "textarea",
    validationObj: {
      required: "Please Enter Note",
    },
  },
  {
    fieldName: "fileUpload",
    fieldTitle: "Upload File",
    fieldType: "upload",
  },
];
