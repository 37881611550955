import { combineReducers } from 'redux';
import { loginReducer, userReducer } from './login.reducer';
import trackingReducer from './trackingState.reducer';

const appReducer = combineReducers({
  login: loginReducer,
  user: userReducer,
  tracking: trackingReducer,
});

const rootReducer = (state, action) => {
  if (action.type === 'LOGOUT') {
    state = undefined;
  }

  return appReducer(state, action);
};

export default rootReducer;
