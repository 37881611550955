const ImageCard = ({ title, photo, description, goToLink }) => {
  return (
    <div
      className="flex flex-col bg-white border border-gray-200 rounded-lg shadow md:flex-row md:max-w-xl hover:bg-gray-100 cursor-pointer items-stretch w-full"
      onClick={goToLink}
    >
      <img
        className="object-cover w-full rounded-t-lg h-96 md:h-auto md:w-48 md:rounded-none md:rounded-s-lg"
        src={photo}
        alt=""
      />

      <div className="flex flex-col justify-between p-4 leading-normal">
        <h5 className="mb-2 text-base font-bold tracking-tight text-gray-900">
          {title}
        </h5>
        <p className="mb-3 font-normal text-gray-700">{description}</p>
      </div>
    </div>
  );
};
export default ImageCard;
