import { Card, Typography } from '@material-tailwind/react';
import { centralTeam, stateTeam } from '../../Fields/team';
import Navbar from '../Navbar/navbar';

function Team() {
  const TABLE_HEAD = ['Designation', 'Officer Name', 'Email', 'Telephone'];

  const TABLE_ROWS = centralTeam;

  const TABLE_STATE_ROWS = stateTeam;

  return (
    <>
      <Navbar />
      <div className="flex justify-center p-6 lg:p-12 flex-col mt-2 lg:mt-4 lg:mb-2 lg:col-span-5 font-manrope">
        <h2 className="text-2xl lg:text-4xl mb-4">Team</h2>
        <div className="mt-2 mb-2">
          <h2 className="text-xl lg:text-3xl mb-4">Minister's Office</h2>
          <Card className="h-full w-full overflow-scroll">
            <table className="w-full min-w-max table-auto text-left">
              <thead>
                <tr>
                  {TABLE_HEAD.map((head) => (
                    <th
                      key={head}
                      className="border-b border-blue-gray-100 bg-[#1c263c] p-4"
                    >
                      <Typography
                        variant="lead"
                        color="white"
                        className="font-normal leading-none"
                      >
                        {head}
                      </Typography>
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {TABLE_ROWS.map(
                  ({ Designation, Name, Email, Phone }, index) => (
                    <tr key={Email} className="even:bg-blue-gray-50/50">
                      <td className="p-4">
                        <Typography
                          variant="paragraph"
                          color="blue-gray"
                          className="font-normal"
                        >
                          {Designation}
                        </Typography>
                      </td>
                      <td className="p-4">
                        <Typography
                          variant="paragraph"
                          color="blue-gray"
                          className="font-normal"
                        >
                          {Name}
                        </Typography>
                      </td>
                      <td className="p-4">
                        <Typography
                          variant="paragraph"
                          color="blue-gray"
                          className="font-normal"
                        >
                          {Email}
                        </Typography>
                      </td>
                      <td className="p-4">
                        <Typography
                          variant="paragraph"
                          color="blue-gray"
                          className="font-normal"
                        >
                          {Phone.join(', ')}
                        </Typography>
                      </td>
                    </tr>
                  )
                )}
              </tbody>
            </table>
          </Card>
        </div>
        <div className="mt-2 mb-2">
          <h2 className="text-xl lg:text-3xl mb-4">Secretary's Office</h2>
          <Card className="h-full w-full overflow-scroll">
            <table className="w-full min-w-max table-auto text-left">
              <thead>
                <tr>
                  {TABLE_HEAD.map((head) => (
                    <th
                      key={head}
                      className="border-b border-blue-gray-100 bg-[#1c263c] p-4"
                    >
                      <Typography
                        variant="lead"
                        color="white"
                        className="font-normal leading-none"
                      >
                        {head}
                      </Typography>
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {TABLE_STATE_ROWS.map(
                  ({ Designation, Name, Email, Phone }, index) => (
                    <tr key={Email} className="even:bg-blue-gray-50/50">
                      <td className="p-4">
                        <Typography
                          variant="paragraph"
                          color="blue-gray"
                          className="font-normal"
                        >
                          {Designation}
                        </Typography>
                      </td>
                      <td className="p-4">
                        <Typography
                          variant="paragraph"
                          color="blue-gray"
                          className="font-normal"
                        >
                          {Name}
                        </Typography>
                      </td>
                      <td className="p-4">
                        <Typography
                          variant="paragraph"
                          color="blue-gray"
                          className="font-normal"
                        >
                          {Email}
                        </Typography>
                      </td>
                      <td className="p-4">
                        <Typography
                          variant="paragraph"
                          color="blue-gray"
                          className="font-normal"
                        >
                          {Phone.join(', ')}
                        </Typography>
                      </td>
                    </tr>
                  )
                )}
              </tbody>
            </table>
          </Card>
        </div>
      </div>
    </>
  );
}

export default Team;
