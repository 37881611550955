const intialState = {
  trackingState: 0,
  ticketDetails: [],
};

const trackingReducer = (state = intialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case "SET_TRACKING_STATE":
      return {
        ...state,
        trackingState: payload,
      };
    case "SET_TICKET_DETAILS":
      return {
        ...state,
        ticketDetails: { ...state.ticketDetails, ...payload },
      };

    default:
      return { ...state };
  }
};

export default trackingReducer;
