import { createStore, applyMiddleware, compose } from 'redux';
import { thunk } from 'redux-thunk';
import persistState from 'redux-localstorage';
import rootReducer from './reducers';

const createStoreWithMiddleware = compose(
  persistState(),
  applyMiddleware(thunk),
  window.__REDUX_DEVTOOLS_EXTENSION__
    ? window.__REDUX_DEVTOOLS_EXTENSION__()
    : (f) => f
)(createStore);

const store = createStoreWithMiddleware(rootReducer);

export default store;
