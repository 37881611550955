import {
  generateOTPAction,
  verifyOTPAction,
  setLogin,
  setLogOut,
  removeUser,
} from '../actions/login.action';

const userLoginGenOp = (data) => async (dispatch) => {
  const response = await dispatch(generateOTPAction(data));
  return response.payload;
};
const removeUserOp = () => async (dispatch) => {
  await dispatch(removeUser());
};

const userLoginVerifyOp = (data) => async (dispatch) => {
  const response = await dispatch(verifyOTPAction(data));
  await dispatch(setLogin({ isLoggedIn: true }));
  return response.payload;
};

const userLogoutOp = () => async (dispatch) => {
  await dispatch(setLogOut({ isLoggedIn: false }));
  await dispatch(removeUser());
};

export { userLoginGenOp, userLoginVerifyOp, userLogoutOp, removeUserOp };
