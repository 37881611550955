import Sidebar from './Sidebar/Sidebar';

import { Link } from 'react-router-dom';
import moment from 'moment';

import { FaRegCalendarAlt } from 'react-icons/fa';

import Profile from './Profiles';
function RootLayout({ children }) {
  const todayDate = moment().format('LL');

  return (
    <div className="flex h-screen overflow-hidden font-manrope">
      <Sidebar />
      <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
        <main>
          <div className="bg-slate-800 flex items-center flex-row py-4 justify-between pl-4">
            <h2 className="text-md lg:text-xl text-gray-200 md:text-3xl lg:pl-12 flex items-center space-x-2">
              <FaRegCalendarAlt />
              <span>{todayDate}</span>
            </h2>
            <div className="text-xl md:text-2xl text-slate-200 font-bold lg:pr-12 flex relative">
              <div
                className="hidden w-full md:block md:w-auto"
                id="navbar-default"
              >
                <ul className="flex flex-col p-4 mt-4 border border-gray-100 rounded-lg md:flex-row md:space-x-8 md:mt-0 md:text-sm md:font-medium md:border-0 text-neutral-700">
                  <li>
                    <Link
                      to="/"
                      className="block py-2 pl-3 pr-4 text-white rounded md:border-0 md:p-0 font-manrope transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110 duration-300 font-semibold"
                    >
                      Home
                    </Link>
                  </li>
                </ul>
              </div>
              <Profile />
            </div>
          </div>
          <div className="m-2">{children}</div>
        </main>
      </div>
    </div>
  );
}

export default RootLayout;
