/* eslint-disable no-lone-blocks */
/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { get, isEmpty } from 'lodash';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import InputField from '../../Common/InputField';
import SelectDropdown from '../.././Common/SelectDropdown';
import {
  getRequest,
  postRequest,
  putRequest,
} from '../interceptor/interceptor';
import FullScreenLoader from '../Loader/FullScreenLoader';
import {
  ADD_NOTES_DETAILS,
  DOCUMENT_UPLOAD,
  EDIT_NOTES_DETAILS,
  GET_NOTES,
  GET_NOTES_DETAILS,
  GET_NOTES_BY_TASK,
  GET_PROJECT_DETAILS,
  GET_NOTES_BY_TICKET,
} from '../../apiConst';
import { notesFields } from '../../Fields/notesFields';
import { useLocation, useNavigate } from 'react-router';
import NotesDisplay from './NotesDisplay';
import UploadField from '../../Common/UploadField';
import { useParams } from 'react-router';
import {
  Card,
  CardContent,
  CardHeader,
  Typography,
  Button,
  IconButton,
  Tooltip,
  Grid,
} from '@mui/material';
import CustomModal from '../../Common/CustomModal';

const TicketCharter = ({ ticketNo, isView, tabValue }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [modal, setModal] = useState(false);
  const [editNote, setEditNote] = useState(false);
  const [allNotes, setAllNotes] = useState([]);
  const [ticket, setTicket] = useState(ticketNo);
  const [notesData, setNotesData] = useState({});
  const fields = notesFields;

  const navigate = useNavigate();
  // const params = useParams();
  // const location = useLocation();

  const {
    register,
    handleSubmit,
    reset,
    getValues,
    setValue,
    formState: { errors },
  } = useForm({});

  useEffect(() => {
    setTicket(ticketNo);
    if (!modal) {
      fetchListData();
    }
  }, [modal]);

  const fetchListData = (pg = 1, size = 50, search = '', searchKey = null) => {
    setIsLoading(true);

    let payload = {
      filter: {
        ticketNo: ticket,
      },
      sortOrder: -1,
      page: pg,
      pageSize: size,
    };

    // const { searchField } = getValues();
    // const key = searchKey ? searchKey : searchField;
    // if (key && search) {
    //   payload.filter[key] = search;
    // }
    postRequest(GET_NOTES, payload)
      .then((res) => {
        console.log('res', res);
        const { result, totalCount } = res.data;
        console.log('result', result);
        setAllNotes(result);
        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
      });
  };
  const onSubmit = (data) => {
    console.log('onSubmit', data);

    const payload = {
      comment: data.note,
      attachment: [{ url: data.uploadFile, fileName: data.uploadFileName }],
      ticketNo: ticketNo,
      status: data.status,
    };
    let url = '';
    if (editNote) {
      const payload = {
        comment: data.note,
        attachment: [
          { url: data.uploadFile, fileName: data.uploadFileName },
        ] || [
          {
            url: data.attachment[0].url,
            fileName: data.attachment[0].fileName,
          },
        ],
        ticketNo: ticketNo,
        noteId: data.noteId,
        status: data.Status,
      };

      url = putRequest(`${EDIT_NOTES_DETAILS}`, payload);
    } else {
      url = postRequest(`${ADD_NOTES_DETAILS}`, payload);
    }
    url
      .then((res) => {
        setIsLoading(false);

        reset({ note: '', uploadFile: '', uploadFileName: '' });
        setEditNote(false);
        setModal(false);
        fetchListData();
      })
      .catch(() => {
        setIsLoading(false);
        setModal(false);
      });
  };

  const onEditNote = (e, note) => {
    console.log('note: ', note);
    reset(note);
    setValue('note', note.comment);
    setValue('uploadFile', note.attachment[0]);
    setValue('uploadFileName', note.attachment[0]);
    setValue('status', note.status);
    setEditNote(true);
    setModal(true);

    // reset(note);
  };

  const onUpload = (e) => {
    e.preventDefault();
    setIsLoading(true);
    const file = e.target.files[0];
    const formData = new FormData();
    formData.append('file', file);
    setValue('uploadFile', '');
    setValue('uploadFileName', '');

    postRequest(`${DOCUMENT_UPLOAD}`, formData, {
      'Content-Type': 'multipart/form-data',
    })
      .then((res) => {
        console.log('fileurl:--->', res);
        setValue('uploadFile', res.data.data[0].url);
        setValue('uploadFileName', res.data.data[0].fileName);
        setTimeout(() => {
          setIsLoading(false);
        }, 100);
      })
      .catch(() => {
        setIsLoading(false);
      });
  };

  const onRemoveFile = (e) => {
    console.log('removed cliked');
    setValue('uploadFileName', '');
    setValue('uploadFile', '');
    reset({ uploadFile: '', uploadFileName: '' });
  };

  const openModal = () => {
    setModal(!modal);
  };

  const pressCloseButton = () => {
    setModal(!modal);
    reset({ note: '', uploadFile: '', uploadFileName: '', status: '' });
    editNote && setEditNote(false);
  };

  const ModalBody = () => {
    return (
      <>
        {isLoading && <FullScreenLoader />}
        <form className="w-full flex justify-center items-center flex-col p-5">
          <div className="w-full grid md:grid-cols-1 gap-6">
            {fields.map((field) => {
              const {
                fieldTitle,
                fieldName,
                fieldType,
                validationObj,
                disabled,
                options,
              } = field;
              return (
                <div className="flex flex-col w-full" key={fieldName}>
                  <label className="block text-gray-800 font-bold mb-3 pr-4">
                    {fieldTitle}
                  </label>
                  <div className="w-full flex items-center">
                    <div className="w-full">
                      {fieldType === 'upload' ? (
                        <UploadField
                          fieldName={fieldName}
                          validationObj={validationObj}
                          register={register}
                          errors={errors}
                          fieldType={fieldType}
                          onChange={onUpload}
                          onRemoveFile={onRemoveFile}
                          valueClassName="flex m-2 items-center"
                          value={getValues('uploadFileName') || ''}
                          placeholder={fieldTitle}
                        />
                      ) : fieldType === 'select' ? (
                        <>
                         
                          <SelectDropdown
                            fieldName={fieldName}
                            options={options}
                            register={register}
                            validationObj={{
                              required: 'Please Select Note Type',
                            }}
                            errors={errors}
                            disabled={disabled}
                          />
                        </>
                      ) : (
                        <InputField
                          fieldName={fieldName}
                          validationObj={validationObj}
                          register={register}
                          errors={errors}
                          fieldType={fieldType}
                          disabled={disabled}
                          placeholder={fieldTitle}
                          className="border border-gray-300 rounded-md p-2 w-full focus:outline-none focus:ring focus:ring-primary-300"
                        />
                      )}
                    </div>
                  </div>
                </div>
              );
            })}
            <div className="flex justify-center">
              <Button
                type="button"
                variant="contained"
                className="mt-4 px-6 py-2 rounded-md bg-success-700 text-white transition duration-150 hover:bg-success-600"
                onClick={handleSubmit(onSubmit)}
              >
                {editNote ? 'Edit' : 'Submit'}
              </Button>
            </div>
          </div>
        </form>
      </>
    );
  };
  return (
    <Card className="p-4 mt-4">
      {isLoading && <FullScreenLoader />}
      <div className="flex flex-col lg:flex-row justify-between">
        <Typography className="text-2xl font-bold text-primary-600">
          Notes
        </Typography>

        {!isView && (
          <div className="m-4 mt-0">
            <Button
              type="button"
              onClick={() => openModal()}
              color="primary"
              variant="contained"
            >
              Add Note
            </Button>
          </div>
        )}
      </div>
      <CustomModal
        modal={modal}
        body={<ModalBody />}
        heading="Note"
        pressCloseButton={pressCloseButton}
      />

      <div className="m-4 p-4 max-h-[500px] overflow-hidden overflow-y-auto border-2 border-[#eee] rounded-xl pr-6 pl-2 shadow-md">
        <NotesDisplay
          notesData={allNotes}
          onEditNote={onEditNote}
          isView={isView}
        />
      </div>
    </Card>
  );
};

export default TicketCharter;
