import { useTranslation } from 'react-i18next';
const About = () => {
  const { t } = useTranslation();
  return (
    <div
      id="about"
      className="flex justify-center p-4 lg:p-12 flex-col mt-2 lg:mt-4 lg:mb-10 lg:col-span-5 bg-[#1c263c] font-manrope"
    >
      <h2 className="text-2xl lg:text-4xl text-white mb-4">
        About
      </h2>
      <div className="flex flex-col justify-between lg:grid lg:grid-cols-2 lg:gap-2">
        <img
          src="https://taskresultspro.s3.amazonaws.com/slide4.png"
          alt="ram"
        />
        <p className="text-xl text-white text-justify lg:pl-4 leading-relaxed">
          {t('about')}
        </p>
      </div>
    </div>
  );
};
export default About;
