import BannerCarousel from '../BannerCarousel/BannerCarousel';

const Gallery = () => {
  return (
    <div
      id="gallery"
      className="p-4 lg:pl-8 lg:pt-4 lg:pr-8 lg:pb-4 mb-4 lg:mb-10"
    >
      <h2 className="text-2xl lg:text-4xl text-white mt-2 mb-2">Gallery</h2>
      <BannerCarousel />
    </div>
  );
};
export default Gallery;
