export const setTrackingStateIndex = (index) => {
  return (dispatch) => {
    dispatch({ type: "SET_TRACKING_STATE", payload: index });
  };
};

export const setTicketDetails = (data) => {
  return (dispatch) => {
    dispatch({ type: "SET_TICKET_DETAILS", payload: data });
  };
};
