import React from 'react';

function Footer() {
  const date = new Date();
  return (
    <div className="bg-blueBg text-white flex flex-col font-manrope">
      <div className="md:ml-4 pl-48 ml-4 mr-4 md:mr-4 lg:py-4 py-2">
        <p className="text-sm">&copy; SEVA {date.getFullYear()}</p>
      </div>
    </div>
  );
}

export default Footer;
