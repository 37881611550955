import { useState, useEffect, useRef } from 'react';
import Sidebar from './Sidebar/Sidebar';

import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';
import { userLogoutOp } from '../redux/operations/user.operations';
import { Link } from 'react-router-dom';
import moment from 'moment';

import { FaRegCalendarAlt } from 'react-icons/fa';

import Profile from './Profiles';

function RootLayout({ children }) {
  const [showMenu, setShowMenu] = useState(false);
  console.log(
    '🚀 ~ file: RootLayout.jsx:16 ~ RootLayout ~ showMenu:',
    showMenu
  );
  const { user } = useSelector((state) => state || {});
  const todayDate = moment().format('LL');

  const menuRef = useRef(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleProfileClick = () => {
    setShowMenu((prev) => !prev);
  };

  const handleClickOutside = (event) => {
    if (menuRef.current && !menuRef.current.contains(event.target)) {
      setShowMenu(false);
    }
  };

  useEffect(() => {
    if (showMenu) {
      document.addEventListener('click', handleClickOutside);
    } else {
      document.removeEventListener('click', handleClickOutside);
    }

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [showMenu]);

  const handleLogout = () => {
    dispatch(userLogoutOp());
    localStorage.removeItem('userAuthToken');
    localStorage.removeItem('locationIp');
    navigate('/login');
  };

  return (
    <div className="flex h-screen overflow-hidden font-manrope">
      <Sidebar />
      <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
        <main>
          <div className="bg-secondary-900 flex items-center flex-row py-4 justify-between pl-4">
            <h2 className="text-md text-white lg:text-xl md:text-3xl lg:pl-12 flex items-center space-x-2">
              <FaRegCalendarAlt />
              <span>{todayDate}</span>
            </h2>
            <div className="text-xl md:text-2xl text-white font-bold lg:pr-12 flex relative">
              <div
                className="hidden w-full md:block md:w-auto"
                id="navbar-default"
              >
                <ul className="flex flex-col p-4 mt-4 border border-gray-100 rounded-lg md:flex-row md:space-x-8 md:mt-0 md:text-sm md:font-medium md:border-0 text-neutral-700">
                  <li>
                    <Link
                      to="/"
                      className="block py-2 pl-3 pr-4 text-white rounded md:border-0 md:p-0 font-manrope transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110 duration-300 font-semibold"
                    >
                      Home
                    </Link>
                  </li>
                  <li>
                    <span ref={menuRef}>
                      <span
                        onClick={handleProfileClick}
                        className="block cursor-pointer py-2 pl-3 pr-4 text-white rounded md:border-0 md:p-0 font-manrope transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110 duration-300 font-semibold"
                      >
                        Current User: {user?.email}
                      </span>
                    </span>
                  </li>
                </ul>
              </div>
              {showMenu && (
                <div
                  className="origin-top-right z-50 absolute right-0 mt-2 w-64 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
                  role="menu"
                  aria-orientation="vertical"
                  aria-labelledby="user-menu-button"
                  tabIndex="-1"
                >
                  <div className="py-4 text-center border-b border-gray-200">
                    <p className="text-sm text-gray-500 py-2">{user?.email}</p>
                    <p className="text-sm text-gray-500">{user?.role}</p>
                  </div>
                  <div className="py-1 flex justify-center items-center">
                    <button
                      className="block px-4 py-2 bg-red-600 text-sm text-gray-100 rounded-md text-center"
                      onClick={handleLogout}
                    >
                      Sign out
                    </button>
                  </div>
                </div>
              )}
              {/* <Profile /> */}
            </div>
          </div>
          <div className="m-2">{children}</div>
        </main>
      </div>
    </div>
  );
}

export default RootLayout;
