import { useEffect, useState } from 'react';
import { get } from 'lodash';
import { Controller, useForm } from 'react-hook-form';
import InputField from '../.././Common/InputField';
import CheckboxField from '../.././Common/CheckboxField';
import { Grid, Button } from '@mui/material';
import TicketCharter from '.././Ticket-Notes/TicketCharter';
import CustomModal from '../../Common/CustomModal';
import DateField from '../../Common/DateField';
import PhoneInput from 'react-phone-number-input/react-hook-form-input';
import { isValidPhoneNumber } from 'react-phone-number-input';
import {
  postRequest,
  putRequest,
  getRequest,
} from '.././interceptor/interceptor';
import FullScreenLoader from '.././Loader/FullScreenLoader';
import {
  UPDATE_TICKET,
  ADD_TICKET,
  UNIQUE_ID,
  REFERRAL_DATA_BY_CONTACT,
  ASSIGNED_TO,
  TTD_DATES,
} from '../.././apiConst';
import { State, City } from 'country-state-city';
import ApplicationTabs from './ApplicationTabs';
import { useLocation, useNavigate } from 'react-router';
import SelectDropdown from '../.././Common/SelectDropdown';
import {
  grievanceStepperFields,
  referalFields,
  grievanceStepperTicketFields,
  applicantDetailsFields,
  grievanceRequestFields,
  approvalActionFields,
  followUpFields,
} from '../.././Fields/grievanceFields';
import CkEditorComponent from '../.././Common/CkEditor';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import {
  setTicketDetails,
  setTrackingStateIndex,
} from '../../redux/actions/trackingState.action';
import { FaChevronUp, FaChevronDown } from 'react-icons/fa';
import { toast } from 'react-toastify';

const Form = ({ disabled }) => {
  console.log('disabled', disabled);
  const trackingState = useSelector((state) => state.tracking.trackingState);
  const ticketDetails = useSelector((state) => state.tracking.ticketDetails);
  const dispatch = useDispatch();
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(false);
  const fields = grievanceStepperFields;
  const ticketFields = grievanceStepperTicketFields;
  const [id, setId] = useState(null);
  const [isDisabledTicket, setIsDisabledTicket] = useState(false);
  const [ticketNo, setTicketNo] = useState(
    location?.state?.data?.task?.ticketNo || null
  );
  const [isEdit, setIsEdit] = useState(null);
  const [requestDetails, setRequestDetails] = useState(false);
  const [contactDetails, setContactDetails] = useState(false);
  const [detailsKnown, setDetailsKnown] = useState(false);
  const [other, setOther] = useState('');
  const [qualification, setQualification] = useState('');
  const [currentLocation, setCurrentLocation] = useState('');
  const [requestedLocation, setRequestedLocation] = useState('');
  const [referral, setReferral] = useState(false);
  const [ticket, setTicket] = useState(null);
  const [disableAllFields, setDisableAllFields] = useState(false);
  const [formStatus, setFormStatus] = useState('');
  const [assignedTo, setAssignedTo] = useState([]);
  const [assignedToValue, setAssignedToValue] = useState('');
  const [ttdDatesApproved, setTtdDatesApproved] = useState([]);
  const [ttdDatesInProgress, setTtdDatesInProgress] = useState([]);
  const [minDate, setMinDate] = useState('');
  const [followUpDisabled, setFollowUpDisabled] = useState(false);

  const [isTicketToggle, setIsTicketToggle] = useState(true); // State to toggle the accordion
  const [isApplicantToggle, setIsApplicantToggle] = useState(false); // State to toggle the accordion
  const [isRequestToggle, setIsRequestToggle] = useState(false); // State to toggle the accordion
  const [isRefferalToggle, setIsRefferalToggle] = useState(false); // State to toggle the accordion
  const [isAssignedToggle, setIsAssignedToggle] = useState(false);

  const ticketToggleAccordion = () => {
    setIsTicketToggle(!isTicketToggle);
    setIsApplicantToggle(false);
    setIsRequestToggle(false);
    setIsRefferalToggle(false);
    setIsAssignedToggle(false);
  };
  const applicantToggleAccordion = () => {
    setIsApplicantToggle(!isApplicantToggle);
    setIsRequestToggle(false);
    setIsRefferalToggle(false);
    setIsTicketToggle(false);
    setIsAssignedToggle(false);
  };
  const requestToggleAccordion = () => {
    setIsRequestToggle(!isRequestToggle);
    setIsRefferalToggle(false);
    setIsTicketToggle(false);
    setIsApplicantToggle(false);
    setIsAssignedToggle(false);
  };
  const refferalToggleAccordion = () => {
    setIsRefferalToggle(!isRefferalToggle);
    setIsApplicantToggle(false);
    setIsRequestToggle(false);
    setIsTicketToggle(false);
    setIsAssignedToggle(false);
  };

  const assignedToggleAccordion = () => {
    setIsAssignedToggle(!isAssignedToggle);
    setIsRefferalToggle(false);
    setIsTicketToggle(false);
    setIsApplicantToggle(false);
    setIsRequestToggle(false);
  };

  const [referralType, setRefferalType] = useState([
    { label: 'Self', value: 'self' },
    { label: 'TDP Cadre', value: 'tdpCadre' },
    { label: 'Janasena Cadre', value: 'janasenaCadre' },
    { label: 'Family', value: 'family' },
    { label: 'Friends', value: 'friends' },
    { label: 'Other', value: 'other' },
  ]);
  const [priority, setPriority] = useState([
    { label: 'High', value: 'high' },
    { label: 'Low', value: 'low' },
    { label: 'Medium', value: 'medium' },
  ]);
  const [referralMode, setRefferalMode] = useState([
    { label: 'Letter', value: 'letter' },
    { label: 'Phone Call', value: 'phoneCall' },
    { label: 'Text Message', value: 'textMessage' },
    { label: 'In Person', value: 'inPerson' },
  ]);

  const [requestType, setRequestType] = useState('');
  const [personalRequestType, setPersonalRequestType] = useState('');
  const [department, setDepartment] = useState('');
  const [ttdAvailableSlot, setTtdAvailableSlot] = useState('');
  const [medicalRequestType, setMedicalRequestType] = useState('');
  const [modal, setModal] = useState(false);
  const [communityRequestDepartment, setCommunityRequestDepartment] =
    useState('');

  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);

  // const [states, setStates] = useState([
  //   { label: "Andhra Pradesh", value: "andhraPradesh" },
  //   { label: "Other", value: "other" },
  // ]);

  // const [districts, setDistricts] = useState([
  //   { label: "Srikakulam", value: "srikakulam" },
  //   { label: "Vizianagaram", value: "vizianagaram" },
  //   { label: "Visakhapatnam", value: "visakhapatnam" },
  //   { label: "Anakapalli", value: "anakapalli" },
  // ]);
  // const [constituencies, setConstituencies] = useState([
  //   { label: "Anakapalli", value: "anakapalli" },
  //   { label: "Chodavaram", value: "chodavaram" },
  //   { label: "Madugula", value: "madugula" },
  //   { label: "Narsipatnam", value: "narsipatnam" },
  // ]);
  // const [mandals, setMandals] = useState([
  //   { label: "Makavaripallem", value: "makavaripallem" },
  //   { label: "Narsipatnam", value: "narsipatnam" },
  //   { label: "Nathavaram", value: "nathavaram" },
  // ]);
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    setValue,
    reset,
    getValues,
    control,
    formState: { errors },
  } = useForm({});

  const { task } = location?.state?.data;

  useEffect(() => {
    const { task } = location?.state?.data;
    setFollowUpDisabled(task?.followup);
    console.log('task', task);
    // Set form fields using setValue
    fields.map((field) => setValue(field.fieldName, task[field.fieldName]));
    setTicketNo(task.ticketNo);
    setReferral(task.referral);
    setRequestDetails(task.requestDetails);
    // miscellaneous
    setOther(task.miscellaneous);
    //requestType
    setRequestType(task.requestType);
    // personalRequestType
    setPersonalRequestType(task.personalRequestType);
    setDepartment(task.issueDepartment);
    setTtdAvailableSlot(task.ttdAvailableSlot);

    // medicalRequestType
    setMedicalRequestType(task.medicalRequest);

    // Set individual form fields
    setValue('state', task?.state);
    setValue('district', task?.district);
    setValue('constituency', task?.constituency);
    setValue('mandal', task?.mandal);
    setValue('issuePOC', task?.issuePOC);
    setValue('issueContact', task?.issueContact);
    setValue('issueDepartment', task?.issueDepartment);
    setValue('referralType', task?.referralType);
    setValue('referralContact', task?.referralContact);
    setValue('referralName', task?.referralName);
    setValue('referralDesignation', task?.referralDesignation);
    setValue('referralMode', task?.referralMode);
    setValue('requestDetails', task?.requestDetails);
    setValue('medicalRequestType', task?.medicalRequestType);
    setValue('personalRequestType', task?.personalRequestType);
    setValue(
      'ttdAvailableSlot',
      task?.ttdAvailableSlot === '' ? new Date() : task?.ttdAvailableSlot
    );
    setValue('miscellaneous', task?.miscellaneous);
    setValue('ticketStatus', task?.ticketStatus);
    setValue('stage', task?.stage);
    setValue('assignedTo', task?.assignedTo);
    setValue('priority', task?.priority);
    setValue('qualification', task?.qualification);
    setValue('currentLocation', task?.currentLocation);
    setValue('requestedLocation', task?.requestedLocation);
    setValue('contactDetails', task?.contactDetails);
    setValue('detailsKnown', task?.detailsKnown);
    setValue('contactName', task?.contactName);
    setValue('contactNumber', task?.contactNumber);
    setValue('designation', task?.designation);
    setValue('hospitalName', task?.hospitalName);
    setValue('typeOfIllness', task?.typeOfIllness);
    setValue('estimateAmount', task?.estimateAmount);
    setValue('cost', task?.cost);
    setValue('grant', task?.grant);
    setValue('communityRequestDepartment', task?.communityRequestDepartment);
    setValue('department', task?.department);
    setValue('requestMode', task?.requestMode);

    if (task?.ticketStatus === 'new') {
      dispatch(setTrackingStateIndex(Number(0)));
    } else if (task?.ticketStatus === 'inprogress') {
      dispatch(setTrackingStateIndex(Number(1)));
    } else if (task?.ticketStatus === 'approved') {
      dispatch(setTrackingStateIndex(Number(2)));
    } else if (task?.ticketStatus === 'rejected') {
      dispatch(setTrackingStateIndex(Number(2)));
    } else {
      dispatch(setTrackingStateIndex(Number(3)));
    }
  }, []);

  useEffect(() => {
    fetchAssignedTo();
    let today = new Date();
    setMinDate(new Date(today.setDate(today.getDate() + 1)));
    const indiaStates = State.getStatesOfCountry('IN');
    const indiaCities = City.getCitiesOfCountry('IN');
    setStates(indiaStates);
    setCities(indiaCities);
  }, []);

  useEffect(() => {
    getExcludedDates();
  }, []);

  const getExcludedDates = () => {
    let url = '';
    url = getRequest(`${TTD_DATES}`);
    url
      .then((res) => {
        setTtdDatesApproved(res.approved);
        setTtdDatesInProgress(res.inprogress);
      })
      .catch((error) => {
        console.log('error ttd', error);
      });
  };

  const fetchAssignedTo = async () => {
    await getRequest(`${ASSIGNED_TO}`).then((res) => {
      if (res) {
        const options = [];
        res.forEach((item, index) => {
          options.push({
            label: item.email,
            value: item.email,
          });
        });
        setAssignedTo(options);
      }
    });
  };

  const onSubmit = (data, e) => {
    let referalNumber = data?.referralContact;

    console.log('referalNumber', referalNumber);

    if (referalNumber) {
      const isReferralContact = isValidPhoneNumber(referalNumber);

      if (!isReferralContact) {
        setIsLoading(false);
        return toast.error('Enter a valid referral phone number!');
      }
    }

    setIsLoading(true);

    if (data?.assignedTo === '') {
      setIsLoading(false);
      return toast.error('Assigned to is required!');
    }

    if (data?.priority === '') {
      setIsLoading(false);
      return toast.error('Priority is required!');
    }

    if (e.target.value === 'approved') {
      data = {
        ...data,
        assignedTo: data?.assignedTo,
        priority: data?.priority,
        ticketStatus: 'approved',
        ttdAvailableSlot:
          data?.personalRequestType !== 'TTD Darshan'
            ? ''
            : data?.ttdAvailableSlot,
      };
      let url = '';
      url = putRequest(`${UPDATE_TICKET}`, data);
      url
        .then((res) => {
          setIsLoading(false);
          navigate('/gms');
        })
        .catch((res) => {
          setIsLoading(false);
        });
      setIsLoading(false);
    } else if (e.target.value === 'rejected') {
      data = {
        ...data,
        assignedTo: data?.assignedTo,
        priority: data?.priority,
        ticketStatus: 'rejected',
        ttdAvailableSlot:
          data?.personalRequestType !== 'TTD Darshan'
            ? ''
            : data?.ttdAvailableSlot,
      };
      let url = '';
      url = putRequest(`${UPDATE_TICKET}`, data);
      url
        .then((res) => {
          setIsLoading(false);
          navigate('/gms');
        })
        .catch(() => {
          setIsLoading(false);
        });
      setIsLoading(false);
    } else if (e.target.value === 'resolved') {
      data = {
        ...data,
        assignedTo: data?.assignedTo,
        priority: data?.priority,
        ticketStatus: 'resolved',
        ttdAvailableSlot:
          data?.personalRequestType !== 'TTD Darshan'
            ? ''
            : data?.ttdAvailableSlot,
      };
      let url = '';
      url = putRequest(`${UPDATE_TICKET}`, data);
      url
        .then((res) => {
          setIsLoading(false);
          navigate('/gms');
        })
        .catch(() => {
          setIsLoading(false);
        });
      setIsLoading(false);
    } else if (e.target.value === 'submit') {
      data = {
        ...data,
        assignedTo: data?.assignedTo,
        priority: data?.priority,
        ticketStatus: 'inprogress',
        ttdAvailableSlot:
          data?.personalRequestType !== 'TTD Darshan'
            ? ''
            : data?.ttdAvailableSlot,
      };
      let url = '';
      url = putRequest(`${UPDATE_TICKET}`, data);
      url
        .then((res) => {
          setIsLoading(false);
          navigate('/gms');
        })
        .catch(() => {
          setIsLoading(false);
        });
      setIsLoading(false);
    } else if (e.target.value === 'followUp') {
      setFollowUpDisabled(true);
      data = {
        ...data,
        assignedTo: data?.assignedTo,
        priority: data?.priority,
        ttdAvailableSlot:
          data?.personalRequestType !== 'TTD Darshan'
            ? ''
            : data?.ttdAvailableSlot,
        followup: true,
        followupData: {
          date: data.followUpDate,
          comment: data.followUpNote,
          email: data.followUpEmail,
        },
      };
      delete data.followUpDate;
      delete data.followUpNote;
      delete data.followUpEmail;
      let url = '';
      url = putRequest(`${UPDATE_TICKET}`, data);
      url
        .then((res) => {
          setIsLoading(false);
          setModal(false);
        })
        .catch(() => {
          setIsLoading(false);
        });
      setIsLoading(false);
    } else {
      data = {
        ...data,
        assignedTo: data?.assignedTo,
        priority: data?.priority,
        ticketStatus: task?.ticketStatus,
        ttdAvailableSlot:
          data?.personalRequestType !== 'TTD Darshan'
            ? ''
            : data?.ttdAvailableSlot,
      };
      let url = '';

      console.log('data', data);
      url = putRequest(`${UPDATE_TICKET}`, data);
      url
        .then((res) => {
          setIsLoading(false);
          navigate('/gms');
        })
        .catch(() => {
          setIsLoading(false);
        });
      setIsLoading(false);
    }
    setIsLoading(false);
  };

  const conditionalFields = [
    {
      fieldName: 'personalRequestType',
      fieldTitle: 'Personal Request Type',
      validationObj: {
        required: 'Please Select Request Type',
      },
      fieldType: 'select',
      options: [
        {
          label: 'Employment Request',
          value: 'Employment Request',
        },
        {
          label: 'Transfer Request',
          value: 'Transfer Request',
        },
        {
          label: 'TTD Darshan',
          value: 'TTD Darshan',
        },
        {
          label: 'Other',
          value: 'other',
        },
      ], // Populate with request type options
    },
    {
      fieldName: 'department',
      fieldTitle: 'Department',
      validationObj: {
        required: 'Please Select Department',
      },
      fieldType: 'select',
      options: [
        {
          label: 'R&B',
          value: 'R&B',
        },
        {
          label: 'Housing',
          value: 'Housing',
        },
        {
          label: 'PR',
          value: 'PR',
        },
        {
          label: 'Agriculture',
          value: 'Agriculture',
        },
        {
          label: 'Police',
          value: 'Police',
        },
        {
          label: 'Fisheries',
          value: 'Fisheries',
        },
        {
          label: 'Forest',
          value: 'Forest',
        },
      ], // Populate with request type options
    },
    {
      fieldName: 'medicalRequestType',
      fieldTitle: 'Medical Request Type',
      validationObj: {
        required: 'Please Select Request Type',
      },
      fieldType: 'select',
      options: [
        {
          label: 'CMRF',
          value: 'CMRF',
        },
        {
          label: 'PMRF',
          value: 'PMRF',
        },
        {
          label: 'Medical Aid',
          value: 'Medical Aid',
        },
      ], // Populate with request type options
    },
    {
      fieldName: 'ttdAvailableSlot',
      fieldTitle: 'TTD Available Slot',
      fieldType: 'date',
    },
    {
      fieldName: 'miscellaneous',
      fieldTitle: 'Miscellaneous',
      fieldType: 'text',
    },
    {
      fieldName: 'qualification',
      fieldTitle: 'Qualification',
      fieldType: 'text',
    },
    {
      fieldName: 'department',
      fieldTitle: 'Department',
      fieldType: 'text',
    },
    {
      fieldName: 'currentLocation',
      fieldTitle: 'Current Location',
      fieldType: 'text',
    },
    {
      fieldName: 'requestedLocation',
      fieldTitle: 'Requested Location',
      fieldType: 'text',
    },
    {
      fieldName: 'communityRequestDepartment',
      fieldTitle: 'Community Request Department',
      validationObj: {
        required: 'Please Select Department',
      },
      fieldType: 'select',
      options: [
        {
          label: 'Education',
          value: 'Education',
        },
        {
          label: 'R&B',
          value: 'R&B',
        },
        {
          label: 'Housing',
          value: 'Housing',
        },
        {
          label: 'PR',
          value: 'PR',
        },
        {
          label: 'Agriculture',
          value: 'Agriculture',
        },
        {
          label: 'Police',
          value: 'Police',
        },
        {
          label: 'Fisheries',
          value: 'Fisheries',
        },
        {
          label: 'Forest',
          value: 'Forest',
        },
      ], // Populate with request type options
    },
    {
      fieldName: 'contactDetails',
      fieldTitle: 'Contact Details',
      fieldType: 'checkbox', // This seems like a checkbox based on the image
    },
    {
      fieldName: 'detailsKnown',
      fieldTitle: 'Details Known',
      fieldType: 'checkbox', // This seems like a checkbox based on the image
    },
  ];

  const transferContactDetails = [
    {
      fieldTitle: 'Contact Name',
      fieldName: 'contactName',
      fieldType: 'text',
    },
    {
      fieldTitle: 'Contact Number',
      fieldName: 'contactNumber',
      fieldType: 'text',
    },
    {
      fieldTitle: 'Designation',
      fieldName: 'designation',
      fieldType: 'text',
    },
  ];

  const communityContactDetails = [
    {
      fieldTitle: 'Cost',
      fieldName: 'cost',
      fieldType: 'text',
    },
    {
      fieldTitle: 'Grant',
      fieldName: 'grant',
      fieldType: 'text',
    },
    {
      fieldTitle: 'Contact Name',
      fieldName: 'contactName',
      fieldType: 'text',
    },
    {
      fieldTitle: 'Contact Number',
      fieldName: 'contactNumber',
      fieldType: 'text',
    },
  ];

  const officialContactDetails = [
    {
      fieldTitle: 'Contact Name',
      fieldName: 'contactName',
      fieldType: 'text',
    },
    {
      fieldTitle: 'Contact Number',
      fieldName: 'contactNumber',
      fieldType: 'text',
    },
  ];

  const medicalDetails = [
    {
      fieldTitle: 'Hospital Name',
      fieldName: 'hospitalName',
      fieldType: 'text',
    },
    {
      fieldTitle: 'Type Of Illness',
      fieldName: 'typeOfIllness',
      fieldType: 'text',
    },
    {
      fieldTitle: 'Estimate Amount',
      fieldName: 'estimateAmount',
      fieldType: 'text',
    },
  ];

  const pressCloseButton = () => {
    // reset();
    setModal(!modal);
  };
  const handleOpenModal = () => {
    // reset({followUpEmail:'',followUpNote:""})
    setModal(!modal);
  };

  const submitBtns = () => {
    return (
      <div className="flex flex-col lg:flex-row items-center justify-start space-x-4 mb-4">
        {trackingState === 0 ? (
          <>
            <Grid item>
              <Button
                variant="contained"
                color="secondary"
                value="save"
                onClick={handleSubmit(onSubmit)}
                className="mt-5"
              >
                Save
              </Button>
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                color="primary"
                value="submit"
                onClick={handleSubmit(onSubmit)}
                className="mt-5"
              >
                Save & Submit
              </Button>
            </Grid>
          </>
        ) : trackingState === 1 ? (
          <>
            <Grid item>
              <Button
                variant="contained"
                color="secondary"
                value="save"
                onClick={handleSubmit(onSubmit)}
                className="mt-5"
              >
                Save
              </Button>
            </Grid>
            <Grid item>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                className="mt-5"
                value="approved"
                onClick={handleSubmit(onSubmit)}
              >
                Approve
              </Button>
            </Grid>
            <Grid item>
              <Button
                type="submiit"
                variant="contained"
                color="error"
                className="mt-5"
                value="rejected"
                onClick={handleSubmit(onSubmit)}
              >
                Reject
              </Button>
            </Grid>
            <Grid item>
              <Button
                type="submit"
                variant="contained"
                color="success"
                className="mt-5"
                value="resolved"
                onClick={handleSubmit(onSubmit)}
              >
                Mark As Resolved
              </Button>
            </Grid>
          </>
        ) : trackingState === 2 && task?.ticketStatus === 'approved' ? (
          <>
            <Grid item>
              <Button
                type="submit"
                variant="contained"
                color="success"
                className="mt-5"
                value="resolved"
                onClick={handleSubmit(onSubmit)}
              >
                Mark As Resolved
              </Button>
            </Grid>
            <Grid item>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                className="mt-5"
                value="submit"
                onClick={handleSubmit(onSubmit)}
              >
                Re-Open
              </Button>
            </Grid>
            <Grid item>
              <Button
                // type="submit"
                variant="contained"
                color="secondary"
                className="mt-5"
                value="followUp"
                onClick={handleOpenModal}
                disabled={followUpDisabled}
              >
                Follow-Up
              </Button>
            </Grid>
          </>
        ) : trackingState === 2 && task?.ticketStatus === 'rejected' ? (
          <Grid item>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              className="mt-5"
              value="submit"
              onClick={handleSubmit(onSubmit)}
            >
              Re-Open
            </Button>
          </Grid>
        ) : null}
      </div>
    );
  };

  const fetchReferralDetails = (contactNumber) => {
    setIsLoading(true);
    setIsLoading(true);
    getRequest(`${REFERRAL_DATA_BY_CONTACT}${contactNumber}`)
      .then((res) => {
        if (res) {
          // Assuming the API response contains `referralName` and `referralDesignation`
          setValue('referralName', res.referralName || '');
          setValue('referralDesignation', res.referralDesignation || '');
          setValue('referralMode', res.referralMode || '');
          setValue('referralType', res.referralType || '');
          setIsLoading(false);
        } else {
          setIsLoading(true);
        }
      })
      .catch(() => {
        setIsLoading(false);
      });
  };

  const handleBlur = (e) => {
    const contactNumber = '+91' + e.target.value.replace(/[^\d\+]/g, '');
    if (contactNumber) {
      fetchReferralDetails(contactNumber);
    }
  };

  const ModalBody = () => {
    return (
      <>
        {isLoading && <FullScreenLoader />}
        <form className="w-full flex justify-center items-center flex-col p-5">
          <div className="w-full grid md:grid-cols-1 gap-6">
            {followUpFields?.map((field) => {
              const {
                fieldTitle,
                fieldName,
                fieldType,
                validationObj,
                disabled,
              } = field;
              return (
                <div className="flex flex-col w-full" key={fieldName}>
                  <label className="block text-gray-800 font-bold mb-3 pr-4">
                    {fieldTitle}
                  </label>
                  <div className="w-full flex items-center">
                    <div className="w-full">
                      {fieldType === 'date' ? (
                        <>
                          <DateField
                            fieldName={fieldName}
                            validationObj={validationObj}
                            control={control}
                            Controller={Controller}
                            minDate={minDate}
                            errors={errors}
                            defaultValue={minDate}
                          />
                        </>
                      ) : (
                        <InputField
                          fieldName={fieldName}
                          validationObj={validationObj}
                          register={register}
                          errors={errors}
                          fieldType={fieldType}
                          disabled={disabled}
                          placeholder={fieldTitle}
                          className="border border-gray-300 rounded-md p-2 w-full focus:outline-none focus:ring focus:ring-primary-300"
                        />
                      )}
                    </div>
                  </div>
                </div>
              );
            })}
            <div className="flex justify-center">
              <Button
                type="button"
                variant="contained"
                color="error"
                className="mt-4 px-6 py-2 rounded-md mr-2 text-white transition duration-150"
                onClick={pressCloseButton}
              >
                Cancel
              </Button>
              <Button
                type="button"
                variant="contained"
                color="primary"
                value="followUp"
                className="mt-4 px-6 py-2 rounded-md ml-2 text-white transition duration-150 "
                onClick={handleSubmit(onSubmit)}
              >
                Submit
              </Button>
            </div>
          </div>
        </form>
      </>
    );
  };

  return (
    <>
      <CustomModal
        modal={modal}
        body={<ModalBody />}
        heading="Follow-Up"
        pressCloseButton={pressCloseButton}
      />
      {isLoading && <FullScreenLoader />}
      <div className="block rounded-lg bg-white p-4 shadow-[0_2px_15px_-3px_rgba(0,0,0,0.07),0_10px_20px_-2px_rgba(0,0,0,0.04)] m-4 overflow-auto">
        {/* <div className="flex mb-2 text-xl font-extrabold text-primary-600">
          {isEdit ? "Edit" : "Add"} Ticket
        </div> */}
        {task?.ticketStatus === 'approved' ||
        task?.ticketStatus === 'rejected' ? (
          <p className="text-2xl mb-2 mt-2">
            Status:{' '}
            <span
              className={`color: ${
                task?.ticketStatus === 'approved'
                  ? 'text-green-500'
                  : 'text-red-500'
              } capitalize`}
            >
              {task?.ticketStatus}
            </span>
          </p>
        ) : null}
        <form onSubmit={handleSubmit(onSubmit)}>
          {!location?.state?.isView ? submitBtns() : null}
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <div
                className="flex justify-between items-center text-white rounded-md bg-gray-700 p-2 mb-1 cursor-pointer"
                onClick={ticketToggleAccordion} // Toggle on click
              >
                <h2>Ticket Information</h2>
                {/* Render the icon based on the toggle state */}
                {isTicketToggle ? <FaChevronUp /> : <FaChevronDown />}
              </div>
            </Grid>
            {isTicketToggle &&
              ticketFields.map((field) => {
                const {
                  fieldTitle,
                  fieldName,
                  fieldType,
                  validationObj,
                  options,
                } = field;

                return (
                  <Grid
                    item
                    xs={12}
                    lg={
                      fieldName === 'additionalInformation' ||
                      fieldName === 'requestDescription'
                        ? 6
                        : 2
                    }
                    key={fieldName}
                  >
                    {fieldType === 'date' ? (
                      <>
                        <label className="block text-gray-800 font-bold mb-3 pr-4">
                          {fieldTitle}
                        </label>
                        <DateField
                          fieldName={fieldName}
                          validationObj={validationObj}
                          control={control}
                          Controller={Controller}
                          minDate={new Date()}
                          errors={errors}
                          defaultValue={getValues(fieldName)}
                          disabled={
                            task?.ticketStatus === 'rejected'
                              ? true
                              : location?.state?.isView || disabled
                          }
                        />
                      </>
                    ) : fieldType === 'editor' ? (
                      <>
                        <label className="block text-gray-800 font-bold mb-3 pr-4">
                          {fieldTitle}
                        </label>
                        <CkEditorComponent
                          register={register}
                          fieldName={fieldName}
                          control={control}
                          data={getValues(fieldName) || ''}
                          setValue={setValue}
                          disabled={
                            task?.ticketStatus === 'rejected'
                              ? true
                              : location?.state?.isView || disabled
                          }
                        />
                      </>
                    ) : fieldType === 'select' ? (
                      fieldName === 'state' ? (
                        <>
                          <label className="block text-gray-800 font-bold mb-3 pr-4">
                            {fieldTitle}
                          </label>
                          <SelectDropdown
                            fieldName="state"
                            options={states.map((state) => ({
                              label: state.name,
                              value: state.isoCode,
                            }))}
                            register={register}
                            validationObj={{ required: 'Please Select State' }}
                            errors={errors}
                            val={getValues(fieldName)}
                            disabled={
                              task?.ticketStatus === 'rejected'
                                ? true
                                : location?.state?.isView || disabled
                            }
                          />
                        </>
                      ) : fieldName === 'district' ? (
                        <>
                          <label className="block text-gray-800 font-bold mb-3 pr-4">
                            {fieldTitle}
                          </label>
                          <SelectDropdown
                            fieldName="district"
                            options={cities.map((city) => ({
                              label: city.name,
                              value: city.name,
                            }))}
                            register={register}
                            validationObj={{
                              required: 'Please Select District',
                            }}
                            errors={errors}
                            val={getValues(fieldName)}
                            disabled={
                              task?.ticketStatus === 'rejected'
                                ? true
                                : location?.state?.isView || disabled
                            }
                          />
                        </>
                      ) : fieldName === 'requestMode' ? (
                        <>
                          <label className="block text-gray-800 font-bold mb-3 pr-4">
                            {fieldTitle}
                          </label>
                          <SelectDropdown
                            fieldName={fieldName}
                            options={options}
                            register={register}
                            validationObj={{
                              required: 'Please Select Request Node',
                            }}
                            errors={errors}
                            val={getValues(fieldName)}
                            disabled={
                              task?.ticketStatus === 'rejected'
                                ? true
                                : location?.state?.isView || disabled
                            }
                          />
                        </>
                      ) : fieldName === 'requestType' ? (
                        <>
                          <label className="block text-gray-800 font-bold mb-3 pr-4">
                            {fieldTitle}
                          </label>
                          <SelectDropdown
                            fieldName={fieldName}
                            options={options}
                            register={register}
                            validationObj={{
                              required: 'Please Select Request Type',
                            }}
                            errors={errors}
                            onChange={(e) => {
                              setRequestType(e.target.value);
                              setPersonalRequestType('');
                            }}
                            val={getValues(fieldName)}
                            disabled={
                              task?.ticketStatus === 'rejected'
                                ? true
                                : location?.state?.isView || disabled
                            }
                          />
                          <>
                            {requestType === 'personal' && (
                              <Grid item xs={12}>
                                <label className="block text-gray-800 font-bold mb-3 pr-4">
                                  {conditionalFields[0].fieldTitle}
                                </label>
                                <SelectDropdown
                                  fieldName={conditionalFields[0].fieldName}
                                  options={conditionalFields[0]?.options}
                                  register={register}
                                  validationObj={{
                                    required: 'Please Select Request Type',
                                  }}
                                  errors={errors}
                                  onChange={(e) =>
                                    setPersonalRequestType(e.target.value)
                                  }
                                  val={getValues(
                                    conditionalFields[0].fieldName
                                  )}
                                  disabled={
                                    task?.ticketStatus === 'rejected'
                                      ? true
                                      : location?.state?.isView || disabled
                                  }
                                />
                              </Grid>
                            )}
                            {requestType === 'personal' &&
                              personalRequestType === 'TTD Darshan' && (
                                <Grid item xs={12}>
                                  <>
                                    <label className="block text-gray-800 font-bold mb-3 pr-4">
                                      {conditionalFields[3].fieldTitle}
                                    </label>
                                    <DateField
                                      fieldName={conditionalFields[3].fieldName}
                                      validationObj={validationObj}
                                      control={control}
                                      Controller={Controller}
                                      minDate={minDate}
                                      disabled={
                                        task?.ticketStatus === 'rejected'
                                          ? true
                                          : location?.state?.isView || disabled
                                      }
                                      onChange={(e) =>
                                        setTtdAvailableSlot(e.target.value)
                                      }
                                      excluded={ttdDatesApproved}
                                      highlighted={ttdDatesInProgress}
                                      errors={errors}
                                      defaultValue={getValues(
                                        conditionalFields[3].fieldName
                                      )}
                                    />
                                  </>
                                </Grid>
                              )}
                            {requestType === 'personal' &&
                              personalRequestType === 'other' && (
                                <Grid item xs={12}>
                                  <>
                                    <label className="block text-gray-800 font-bold mb-3 pr-4">
                                      {conditionalFields[4].fieldTitle}
                                    </label>
                                    <InputField
                                      fieldName={conditionalFields[4].fieldName}
                                      validationObj={validationObj}
                                      register={register}
                                      errors={errors}
                                      fieldType={conditionalFields[4].fieldType}
                                      onChange={(e) => setOther(e.target.value)}
                                      val={getValues(
                                        conditionalFields[4].fieldName
                                      )}
                                      disabled={
                                        task?.ticketStatus === 'rejected'
                                          ? true
                                          : location?.state?.isView || disabled
                                      }
                                    />
                                  </>
                                </Grid>
                              )}
                            {requestType === 'personal' &&
                              personalRequestType === 'Employment Request' && (
                                <Grid item xs={12}>
                                  <>
                                    <label className="block text-gray-800 font-bold mb-3 pr-4">
                                      {conditionalFields[5].fieldTitle}
                                    </label>
                                    <InputField
                                      fieldName={conditionalFields[5].fieldName}
                                      validationObj={validationObj}
                                      register={register}
                                      errors={errors}
                                      fieldType={conditionalFields[5].fieldType}
                                      onChange={(e) =>
                                        setQualification(e.target.value)
                                      }
                                      val={getValues(
                                        conditionalFields[5].fieldName
                                      )}
                                      disabled={
                                        task?.ticketStatus === 'rejected'
                                          ? true
                                          : location?.state?.isView || disabled
                                      }
                                    />
                                  </>
                                </Grid>
                              )}
                            {requestType === 'personal' &&
                              personalRequestType === 'Transfer Request' && (
                                <Grid item xs={12}>
                                  <>
                                    <label className="block text-gray-800 font-bold mb-3 pr-4">
                                      {conditionalFields[6].fieldTitle}
                                    </label>
                                    <InputField
                                      fieldName={conditionalFields[6].fieldName}
                                      validationObj={validationObj}
                                      register={register}
                                      errors={errors}
                                      fieldType={conditionalFields[6].fieldType}
                                      onChange={(e) =>
                                        setDepartment(e.target.value)
                                      }
                                      val={getValues(
                                        conditionalFields[6].fieldName
                                      )}
                                      disabled={
                                        task?.ticketStatus === 'rejected'
                                          ? true
                                          : location?.state?.isView || disabled
                                      }
                                    />
                                    <label className="block text-gray-800 font-bold mb-3 pr-4">
                                      {conditionalFields[7].fieldTitle}
                                    </label>
                                    <InputField
                                      fieldName={conditionalFields[7].fieldName}
                                      validationObj={validationObj}
                                      register={register}
                                      errors={errors}
                                      fieldType={conditionalFields[7].fieldType}
                                      onChange={(e) =>
                                        setCurrentLocation(e.target.value)
                                      }
                                      val={getValues(
                                        conditionalFields[7].fieldName
                                      )}
                                      disabled={
                                        task?.ticketStatus === 'rejected'
                                          ? true
                                          : location?.state?.isView || disabled
                                      }
                                    />
                                    <label className="block text-gray-800 font-bold mb-3 pr-4">
                                      {conditionalFields[8].fieldTitle}
                                    </label>
                                    <InputField
                                      fieldName={conditionalFields[8].fieldName}
                                      validationObj={validationObj}
                                      register={register}
                                      errors={errors}
                                      fieldType={conditionalFields[8].fieldType}
                                      onChange={(e) =>
                                        setRequestedLocation(e.target.value)
                                      }
                                      val={getValues(
                                        conditionalFields[8].fieldName
                                      )}
                                      disabled={
                                        task?.ticketStatus === 'rejected'
                                          ? true
                                          : location?.state?.isView || disabled
                                      }
                                    />
                                    <label className="block text-gray-800 font-bold mb-3 pr-4">
                                      {conditionalFields[10].fieldTitle}
                                    </label>
                                    <CheckboxField
                                      fieldName={
                                        conditionalFields[10].fieldName
                                      }
                                      register={register}
                                      validationObj={validationObj}
                                      onChange={(checked) =>
                                        setContactDetails(checked)
                                      }
                                      errors={errors}
                                      disabled={
                                        task?.ticketStatus === 'rejected'
                                          ? true
                                          : location?.state?.isView || disabled
                                      }
                                    />
                                  </>
                                  <>
                                    {contactDetails && (
                                      <>
                                        {transferContactDetails.map(
                                          (details) => (
                                            <Grid item xs={12}>
                                              <label className="block text-gray-800 font-bold mb-3 pr-4">
                                                {details.fieldTitle}
                                              </label>
                                              <InputField
                                                fieldName={details.fieldName}
                                                validationObj={{
                                                  required: false,
                                                }}
                                                register={register}
                                                errors={errors}
                                                value={getValues(
                                                  details.fieldName
                                                )}
                                                disabled={
                                                  task?.ticketStatus ===
                                                  'rejected'
                                                    ? true
                                                    : location?.state?.isView ||
                                                      disabled
                                                }
                                              />
                                            </Grid>
                                          )
                                        )}
                                      </>
                                    )}
                                  </>
                                </Grid>
                              )}
                          </>
                          <>
                            {requestType === 'official' && (
                              <Grid item xs={12}>
                                <label className="block text-gray-800 font-bold mb-3 pr-4">
                                  {conditionalFields[1].fieldTitle}
                                </label>
                                <SelectDropdown
                                  fieldName={conditionalFields[1].fieldName}
                                  options={conditionalFields[1]?.options}
                                  register={register}
                                  validationObj={{
                                    required: 'Please Select Request Type',
                                  }}
                                  errors={errors}
                                  onChange={(e) => {
                                    setDepartment(e.target.value);
                                  }}
                                  val={getValues(
                                    conditionalFields[1].fieldName
                                  )}
                                  disabled={
                                    task?.ticketStatus === 'rejected'
                                      ? true
                                      : location?.state?.isView || disabled
                                  }
                                />
                              </Grid>
                            )}
                            {requestType === 'official' &&
                              officialContactDetails.map((details) => (
                                <Grid item xs={12}>
                                  <>
                                    <label className="block text-gray-800 font-bold mb-3 pr-4">
                                      {details.fieldTitle}
                                    </label>
                                    <InputField
                                      fieldName={details.fieldName}
                                      validationObj={validationObj}
                                      register={register}
                                      errors={errors}
                                      fieldType={details.fieldType}
                                      val={getValues(details.fieldName)}
                                      disabled={
                                        task?.ticketStatus === 'rejected'
                                          ? true
                                          : location?.state?.isView || disabled
                                      }
                                    />
                                  </>
                                </Grid>
                              ))}
                          </>
                          <>
                            {requestType === 'community' && (
                              <Grid item xs={12}>
                                <label className="block text-gray-800 font-bold mb-3 pr-4">
                                  {conditionalFields[1].fieldTitle}
                                </label>
                                <SelectDropdown
                                  fieldName={conditionalFields[9].fieldName}
                                  options={conditionalFields[9]?.options}
                                  register={register}
                                  validationObj={{
                                    required: 'Please Select Request Type',
                                  }}
                                  errors={errors}
                                  onChange={(e) => {
                                    setCommunityRequestDepartment(
                                      e.target.value
                                    );
                                  }}
                                  val={getValues(
                                    conditionalFields[9].fieldName
                                  )}
                                  disabled={
                                    task?.ticketStatus === 'rejected'
                                      ? true
                                      : location?.state?.isView || disabled
                                  }
                                />
                                <label className="block text-gray-800 font-bold mb-3 pr-4">
                                  {conditionalFields[11].fieldTitle}
                                </label>
                                <CheckboxField
                                  fieldName={conditionalFields[11].fieldName}
                                  register={register}
                                  validationObj={validationObj}
                                  onChange={(checked) =>
                                    setDetailsKnown(checked)
                                  }
                                  errors={errors}
                                  disabled={
                                    task?.ticketStatus === 'rejected'
                                      ? true
                                      : location?.state?.isView || disabled
                                  }
                                />
                              </Grid>
                            )}

                            {requestType === 'community' &&
                              detailsKnown &&
                              communityContactDetails.map((details) => (
                                <Grid item xs={12}>
                                  <>
                                    <label className="block text-gray-800 font-bold mb-3 pr-4">
                                      {details.fieldTitle}
                                    </label>
                                    <InputField
                                      fieldName={details.fieldName}
                                      validationObj={validationObj}
                                      register={register}
                                      errors={errors}
                                      fieldType={details.fieldType}
                                      val={getValues(details.fieldName)}
                                      disabled={
                                        task?.ticketStatus === 'rejected'
                                          ? true
                                          : location?.state?.isView || disabled
                                      }
                                    />
                                  </>
                                </Grid>
                              ))}
                          </>
                          <>
                            {requestType === 'medical' && (
                              <Grid item xs={12}>
                                <label className="block text-gray-800 font-bold mb-3 pr-4">
                                  {conditionalFields[2].fieldTitle}
                                </label>
                                <SelectDropdown
                                  fieldName={conditionalFields[2].fieldName}
                                  options={conditionalFields[2]?.options}
                                  register={register}
                                  validationObj={{
                                    required: 'Please Select Request Type',
                                  }}
                                  errors={errors}
                                  onChange={(e) => {
                                    setMedicalRequestType(e.target.value);
                                    setPersonalRequestType('');
                                  }}
                                  val={getValues(
                                    conditionalFields[2].fieldName
                                  )}
                                  disabled={
                                    task?.ticketStatus === 'rejected'
                                      ? true
                                      : location?.state?.isView || disabled
                                  }
                                />
                                {medicalDetails.map((details) => (
                                  <>
                                    <label className="block text-gray-800 font-bold mb-3 pr-4">
                                      {details.fieldTitle}
                                    </label>
                                    <InputField
                                      fieldName={details.fieldName}
                                      validationObj={validationObj}
                                      register={register}
                                      errors={errors}
                                      fieldType={details.fieldType}
                                      val={getValues(details.fieldName)}
                                      disabled={
                                        task?.ticketStatus === 'rejected'
                                          ? true
                                          : location?.state?.isView || disabled
                                      }
                                    />
                                  </>
                                ))}
                              </Grid>
                            )}
                          </>
                          {requestType === 'other' && (
                            <Grid item xs={12}>
                              <>
                                <label className="block text-gray-800 font-bold mb-3 pr-4">
                                  {conditionalFields[4].fieldTitle}
                                </label>
                                <InputField
                                  fieldName={conditionalFields[4].fieldName}
                                  validationObj={validationObj}
                                  register={register}
                                  errors={errors}
                                  fieldType={conditionalFields[4].fieldType}
                                  val={getValues(
                                    conditionalFields[4].fieldName
                                  )}
                                  disabled={
                                    task?.ticketStatus === 'rejected'
                                      ? true
                                      : location?.state?.isView || disabled
                                  }
                                />
                              </>
                            </Grid>
                          )}
                        </>
                      ) : null
                    ) : fieldType === 'checkbox' &&
                      fieldName === 'requestDetails' ? (
                      <>
                        <label className="block text-gray-800 font-bold mb-3 pr-4">
                          {fieldTitle}
                        </label>
                        <CheckboxField
                          fieldName={fieldName}
                          register={register}
                          validationObj={validationObj}
                          onChange={(checked) => setRequestDetails(checked)}
                          errors={errors}
                          disabled={
                            task?.ticketStatus === 'rejected'
                              ? true
                              : location?.state?.isView || disabled
                          }
                        />
                        {requestDetails && (
                          <>
                            <Grid item xs={12}>
                              <label className="block text-gray-800 font-bold mb-3 pr-4">
                                Issue POC Details
                              </label>
                              <InputField
                                fieldName="issuePOC"
                                validationObj={{ required: false }}
                                register={register}
                                errors={errors}
                                value={getValues('issuePOC')}
                                disabled={
                                  task?.ticketStatus === 'rejected'
                                    ? true
                                    : location?.state?.isView || disabled
                                }
                              />
                            </Grid>

                            <Grid item xs={12}>
                              <label className="block text-gray-800 font-bold mb-3 pr-4">
                                Issue Contact Number
                              </label>
                              <InputField
                                fieldName="issueContact"
                                validationObj={{ required: false }}
                                register={register}
                                errors={errors}
                                value={getValues('issueContact')}
                                disabled={
                                  task?.ticketStatus === 'rejected'
                                    ? true
                                    : location?.state?.isView || disabled
                                }
                              />
                            </Grid>
                            <Grid item xs={12}>
                              <label className="block text-gray-800 font-bold mb-3 pr-4">
                                Issue Department
                              </label>
                              <InputField
                                fieldName="issueDepartment"
                                validationObj={{ required: false }}
                                register={register}
                                errors={errors}
                                value={getValues('issueDepartment')}
                                disabled={
                                  task?.ticketStatus === 'rejected'
                                    ? true
                                    : location?.state?.isView || disabled
                                }
                              />
                            </Grid>
                          </>
                        )}
                      </>
                    ) : (
                      <>
                        <label className="block text-gray-800 font-bold mb-3 pr-4">
                          {fieldTitle}
                        </label>
                        <InputField
                          fieldName={fieldName}
                          validationObj={validationObj}
                          register={register}
                          disabled={
                            fieldName === 'ticketNo'
                              ? true
                              : task?.ticketStatus === 'rejected'
                              ? true
                              : location?.state?.isView || disabled
                          }
                          errors={errors}
                          fieldType={fieldType}
                        />
                      </>
                    )}
                  </Grid>
                );
              })}
          </Grid>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <div
                className="flex justify-between items-center text-white rounded-md bg-gray-700 p-2 mb-1 cursor-pointer"
                onClick={requestToggleAccordion} // Toggle on click
              >
                <h2> Request Details</h2>
                {/* Render the icon based on the toggle state */}
                {isRequestToggle ? <FaChevronUp /> : <FaChevronDown />}
              </div>
            </Grid>
            {isRequestToggle &&
              grievanceRequestFields.map((field) => {
                const {
                  fieldTitle,
                  fieldName,
                  fieldType,
                  validationObj,
                  options,
                } = field;

                return (
                  <Grid
                    item
                    xs={12}
                    lg={
                      fieldName === 'additionalInformation' ||
                      fieldName === 'requestDescription'
                        ? 6
                        : 2
                    }
                    key={fieldName}
                  >
                    {fieldType === 'date' ? (
                      <>
                        <label className="block text-sm text-gray-800 font-bold mb-3 pr-4">
                          {fieldTitle}
                        </label>
                        <DateField
                          fieldName={fieldName}
                          validationObj={validationObj}
                          control={control}
                          Controller={Controller}
                          minDate={new Date()}
                          errors={errors}
                          defaultValue={getValues(fieldName)}
                          disabled={location?.state?.isView}
                        />
                      </>
                    ) : fieldType === 'editor' ? (
                      <>
                        <label className="block text-sm text-gray-800 font-bold mb-3 pr-4">
                          {fieldTitle}
                        </label>
                        <CkEditorComponent
                          register={register}
                          fieldName={fieldName}
                          control={control}
                          data={getValues(fieldName) || ''}
                          setValue={setValue}
                          disabled={location?.state?.isView}
                        />
                      </>
                    ) : fieldType === 'select' ? (
                      fieldName === 'requestMode' ? (
                        <>
                          <label className="block text-sm text-gray-800 font-bold mb-3 pr-4">
                            {fieldTitle}
                          </label>
                          <SelectDropdown
                            fieldName={fieldName}
                            options={options}
                            register={register}
                            validationObj={{
                              required: 'Please Select Request Node',
                            }}
                            errors={errors}
                            val={getValues(fieldName)}
                            disabled={location?.state?.isView}
                          />
                        </>
                      ) : fieldName === 'requestType' ? (
                        <>
                          <label className="block text-sm text-gray-800 font-bold mb-3 pr-4">
                            {fieldTitle}
                          </label>
                          <SelectDropdown
                            fieldName={fieldName}
                            options={options}
                            register={register}
                            validationObj={{
                              required: 'Please Select Request Type',
                            }}
                            errors={errors}
                            onChange={(e) => {
                              setRequestType(e.target.value);
                              setPersonalRequestType('');
                            }}
                            val={getValues(fieldName)}
                            disabled={location?.state?.isView}
                          />
                          <>
                            {requestType === 'personal' && (
                              <Grid item xs={12}>
                                <label className="block text-sm text-gray-800 font-bold mb-3 pr-4">
                                  {conditionalFields[0].fieldTitle}
                                </label>
                                <SelectDropdown
                                  fieldName={conditionalFields[0].fieldName}
                                  options={conditionalFields[0]?.options}
                                  register={register}
                                  validationObj={{
                                    required: 'Please Select Request Type',
                                  }}
                                  errors={errors}
                                  onChange={(e) =>
                                    setPersonalRequestType(e.target.value)
                                  }
                                  val={getValues(
                                    conditionalFields[0].fieldName
                                  )}
                                  disabled={location?.state?.isView}
                                />
                              </Grid>
                            )}
                            {requestType === 'personal' &&
                              personalRequestType === 'TTD Darshan' && (
                                <Grid item xs={12}>
                                  <>
                                    <label className="block text-sm text-gray-800 font-bold mb-3 pr-4">
                                      {conditionalFields[3].fieldTitle}
                                    </label>
                                    <DateField
                                      fieldName={conditionalFields[3].fieldName}
                                      validationObj={validationObj}
                                      control={control}
                                      Controller={Controller}
                                      minDate={new Date()}
                                      disabled={location?.state?.isView}
                                      onChange={(e) =>
                                        setTtdAvailableSlot(
                                          moment(e.target.value).format(
                                            'YYYY-MM-DD'
                                          )
                                        )
                                      }
                                      excluded={ttdDatesApproved}
                                      highlighted={ttdDatesInProgress}
                                      errors={errors}
                                      defaultValue={getValues(
                                        conditionalFields[3].fieldName
                                      )}
                                    />
                                  </>
                                </Grid>
                              )}
                            {requestType === 'personal' &&
                              personalRequestType === 'other' && (
                                <Grid item xs={12}>
                                  <>
                                    <label className="block text-sm text-gray-800 font-bold mb-3 pr-4">
                                      {conditionalFields[4].fieldTitle}
                                    </label>
                                    <InputField
                                      fieldName={conditionalFields[4].fieldName}
                                      validationObj={validationObj}
                                      register={register}
                                      errors={errors}
                                      fieldType={conditionalFields[4].fieldType}
                                      onChange={(e) => setOther(e.target.value)}
                                      val={getValues(
                                        conditionalFields[4].fieldName
                                      )}
                                      disabled={location?.state?.isView}
                                    />
                                  </>
                                </Grid>
                              )}
                            {requestType === 'personal' &&
                              personalRequestType === 'Employment Request' && (
                                <Grid item xs={12}>
                                  <>
                                    <label className="block text-sm text-gray-800 font-bold mb-3 pr-4">
                                      {conditionalFields[5].fieldTitle}
                                    </label>
                                    <InputField
                                      fieldName={conditionalFields[5].fieldName}
                                      validationObj={validationObj}
                                      register={register}
                                      errors={errors}
                                      fieldType={conditionalFields[5].fieldType}
                                      onChange={(e) =>
                                        setQualification(e.target.value)
                                      }
                                      val={getValues(
                                        conditionalFields[5].fieldName
                                      )}
                                      disabled={location?.state?.isView}
                                    />
                                  </>
                                </Grid>
                              )}
                            {requestType === 'personal' &&
                              personalRequestType === 'Transfer Request' && (
                                <Grid item xs={12}>
                                  <>
                                    <label className="block text-sm text-gray-800 font-bold mb-3 pr-4">
                                      {conditionalFields[6].fieldTitle}
                                    </label>
                                    <InputField
                                      fieldName={conditionalFields[6].fieldName}
                                      validationObj={validationObj}
                                      register={register}
                                      errors={errors}
                                      fieldType={conditionalFields[6].fieldType}
                                      onChange={(e) =>
                                        setDepartment(e.target.value)
                                      }
                                      val={getValues(
                                        conditionalFields[6].fieldName
                                      )}
                                      disabled={location?.state?.isView}
                                    />
                                    <label className="block text-sm text-gray-800 font-bold mb-3 pr-4">
                                      {conditionalFields[7].fieldTitle}
                                    </label>
                                    <InputField
                                      fieldName={conditionalFields[7].fieldName}
                                      validationObj={validationObj}
                                      register={register}
                                      errors={errors}
                                      fieldType={conditionalFields[7].fieldType}
                                      onChange={(e) =>
                                        setCurrentLocation(e.target.value)
                                      }
                                      val={getValues(
                                        conditionalFields[7].fieldName
                                      )}
                                      disabled={location?.state?.isView}
                                    />
                                    <label className="block text-sm text-gray-800 font-bold mb-3 pr-4">
                                      {conditionalFields[8].fieldTitle}
                                    </label>
                                    <InputField
                                      fieldName={conditionalFields[8].fieldName}
                                      validationObj={validationObj}
                                      register={register}
                                      errors={errors}
                                      fieldType={conditionalFields[8].fieldType}
                                      onChange={(e) =>
                                        setRequestedLocation(e.target.value)
                                      }
                                      val={getValues(
                                        conditionalFields[8].fieldName
                                      )}
                                      disabled={location?.state?.isView}
                                    />
                                    <label className="block text-sm text-gray-800 font-bold mb-3 pr-4">
                                      {conditionalFields[10].fieldTitle}
                                    </label>
                                    <CheckboxField
                                      fieldName={
                                        conditionalFields[10].fieldName
                                      }
                                      register={register}
                                      validationObj={validationObj}
                                      onChange={(checked) =>
                                        setContactDetails(checked)
                                      }
                                      errors={errors}
                                      disabled={location?.state?.isView}
                                    />
                                  </>
                                  <>
                                    {contactDetails && (
                                      <>
                                        {transferContactDetails.map(
                                          (details) => (
                                            <Grid item xs={12}>
                                              <label className="block text-sm text-gray-800 font-bold mb-3 pr-4">
                                                {details.fieldTitle}
                                              </label>
                                              <InputField
                                                fieldName={details.fieldName}
                                                validationObj={{
                                                  required: false,
                                                }}
                                                register={register}
                                                errors={errors}
                                                value={getValues(
                                                  details.fieldName
                                                )}
                                                disabled={
                                                  location?.state?.isView
                                                }
                                              />
                                            </Grid>
                                          )
                                        )}
                                      </>
                                    )}
                                  </>
                                </Grid>
                              )}
                          </>
                          <>
                            {requestType === 'official' && (
                              <Grid item xs={12}>
                                <label className="block text-gray-800 text-sm font-bold mb-3 pr-4">
                                  {conditionalFields[1].fieldTitle}
                                </label>
                                <SelectDropdown
                                  fieldName={conditionalFields[1].fieldName}
                                  options={conditionalFields[1]?.options}
                                  register={register}
                                  validationObj={{
                                    required: 'Please Select Request Type',
                                  }}
                                  errors={errors}
                                  onChange={(e) => {
                                    setDepartment(e.target.value);
                                  }}
                                  val={getValues(
                                    conditionalFields[1].fieldName
                                  )}
                                  disabled={location?.state?.isView}
                                />
                              </Grid>
                            )}

                            {requestType === 'official' &&
                              officialContactDetails.map((details) => (
                                <Grid item xs={12}>
                                  <>
                                    <label className="block text-sm text-gray-800 font-bold mb-3 pr-4">
                                      {details.fieldTitle}
                                    </label>
                                    <InputField
                                      fieldName={details.fieldName}
                                      validationObj={validationObj}
                                      register={register}
                                      errors={errors}
                                      fieldType={details.fieldType}
                                      val={getValues(details.fieldName)}
                                      disabled={location?.state?.isView}
                                    />
                                  </>
                                </Grid>
                              ))}
                          </>
                          <>
                            {requestType === 'community' && (
                              <Grid item xs={12}>
                                <label className="block text-sm text-gray-800 font-bold mb-3 pr-4">
                                  {conditionalFields[1].fieldTitle}
                                </label>
                                <SelectDropdown
                                  fieldName={conditionalFields[9].fieldName}
                                  options={conditionalFields[9]?.options}
                                  register={register}
                                  validationObj={{
                                    required: 'Please Select Request Type',
                                  }}
                                  errors={errors}
                                  onChange={(e) => {
                                    setCommunityRequestDepartment(
                                      e.target.value
                                    );
                                  }}
                                  val={getValues(
                                    conditionalFields[9].fieldName
                                  )}
                                  disabled={location?.state?.isView}
                                />
                                <label className="block text-gray-800 text-sm font-bold mb-3 pr-4">
                                  {conditionalFields[11].fieldTitle}
                                </label>
                                <CheckboxField
                                  fieldName={conditionalFields[11].fieldName}
                                  register={register}
                                  validationObj={validationObj}
                                  onChange={(checked) =>
                                    setDetailsKnown(checked)
                                  }
                                  errors={errors}
                                  disabled={location?.state?.isView}
                                />
                              </Grid>
                            )}

                            {requestType === 'community' &&
                              detailsKnown &&
                              communityContactDetails.map((details) => (
                                <Grid item xs={12}>
                                  <>
                                    <label className="block text-sm text-gray-800 font-bold mb-3 pr-4">
                                      {details.fieldTitle}
                                    </label>
                                    <InputField
                                      fieldName={details.fieldName}
                                      validationObj={validationObj}
                                      register={register}
                                      errors={errors}
                                      fieldType={details.fieldType}
                                      val={getValues(details.fieldName)}
                                      disabled={location?.state?.isView}
                                    />
                                  </>
                                </Grid>
                              ))}
                          </>
                          <>
                            {requestType === 'medical' && (
                              <Grid item xs={12}>
                                <label className="block text-sm text-gray-800 font-bold mb-3 pr-4">
                                  {conditionalFields[2].fieldTitle}
                                </label>
                                <SelectDropdown
                                  fieldName={conditionalFields[2].fieldName}
                                  options={conditionalFields[2]?.options}
                                  register={register}
                                  validationObj={{
                                    required: 'Please Select Request Type',
                                  }}
                                  errors={errors}
                                  onChange={(e) => {
                                    setMedicalRequestType(e.target.value);
                                    setPersonalRequestType('');
                                  }}
                                  val={getValues(
                                    conditionalFields[2].fieldName
                                  )}
                                  disabled={location?.state?.isView}
                                />

                                {medicalDetails.map((details) => (
                                  <>
                                    <label className="block text-sm text-gray-800 font-bold mb-3 pr-4">
                                      {details.fieldTitle}
                                    </label>
                                    <InputField
                                      fieldName={details.fieldName}
                                      validationObj={validationObj}
                                      register={register}
                                      errors={errors}
                                      fieldType={details.fieldType}
                                      val={getValues(details.fieldName)}
                                      disabled={location?.state?.isView}
                                    />
                                  </>
                                ))}
                              </Grid>
                            )}
                          </>
                          {requestType === 'other' && (
                            <Grid item xs={12}>
                              <>
                                <label className="block text-sm text-gray-800 font-bold mb-3 pr-4">
                                  {conditionalFields[4].fieldTitle}
                                </label>
                                <InputField
                                  fieldName={conditionalFields[4].fieldName}
                                  validationObj={validationObj}
                                  register={register}
                                  errors={errors}
                                  fieldType={conditionalFields[4].fieldType}
                                  val={getValues(
                                    conditionalFields[4].fieldName
                                  )}
                                  disabled={location?.state?.isView}
                                />
                              </>
                            </Grid>
                          )}
                        </>
                      ) : null
                    ) : fieldType === 'checkbox' &&
                      fieldName === 'requestDetails' ? (
                      <>
                        <label className="block text-sm text-gray-800 font-bold mb-3 pr-4">
                          {fieldTitle}
                        </label>
                        <CheckboxField
                          fieldName={fieldName}
                          register={register}
                          validationObj={validationObj}
                          onChange={(checked) => setRequestDetails(checked)}
                          errors={errors}
                          disabled={location?.state?.isView}
                        />
                        {requestDetails && (
                          <>
                            <Grid item xs={12}>
                              <label className="block text-sm text-gray-800 font-bold mb-3 pr-4">
                                Issue POC Details
                              </label>
                              <InputField
                                fieldName="issuePOC"
                                validationObj={{ required: false }}
                                register={register}
                                errors={errors}
                                value={getValues('issuePOC')}
                                disabled={location?.state?.isView}
                              />
                            </Grid>

                            <Grid item xs={12}>
                              <label className="block text-sm text-gray-800 font-bold mb-3 pr-4">
                                Issue Contact Number
                              </label>
                              <InputField
                                fieldName="issueContact"
                                validationObj={{ required: false }}
                                register={register}
                                errors={errors}
                                value={getValues('issueContact')}
                                disabled={location?.state?.isView}
                              />
                            </Grid>
                            <Grid item xs={12}>
                              <label className="block text-sm text-gray-800 font-bold mb-3 pr-4">
                                Issue Department
                              </label>
                              <InputField
                                fieldName="issueDepartment"
                                validationObj={{ required: false }}
                                register={register}
                                errors={errors}
                                value={getValues('issueDepartment')}
                                disabled={location?.state?.isView}
                              />
                            </Grid>
                          </>
                        )}
                      </>
                    ) : (
                      <>
                        <label className="block text-sm text-gray-800 font-bold mb-3 pr-4">
                          {fieldTitle}
                        </label>
                        <InputField
                          fieldName={fieldName}
                          validationObj={validationObj}
                          register={register}
                          disabled={
                            fieldName === 'ticketNo'
                              ? true
                              : location?.state?.isView
                          }
                          errors={errors}
                          fieldType={fieldType}
                        />
                      </>
                    )}
                  </Grid>
                );
              })}
          </Grid>

          <Grid container spacing={3}>
            <Grid item xs={12}>
              <div
                className="flex justify-between items-center text-white rounded-md bg-gray-700 p-2 mb-1 cursor-pointer"
                onClick={refferalToggleAccordion} // Toggle on click
              >
                <h2> Refferal Details</h2>
                {/* Render the icon based on the toggle state */}
                {isRefferalToggle ? <FaChevronUp /> : <FaChevronDown />}
              </div>
            </Grid>

            {isRefferalToggle &&
              referalFields.map((field) => {
                const {
                  fieldTitle,
                  fieldName,
                  fieldType,
                  validationObj,
                  options,
                } = field;

                return (
                  <Grid
                    item
                    xs={12}
                    key={fieldName}
                    lg={
                      fieldName === 'additionalInformation' ||
                      fieldName === 'requestDescription'
                        ? 6
                        : 2
                    }
                  >
                    <label className="block text-sm text-gray-800 font-bold mb-3 pr-4">
                      {fieldTitle}
                    </label>

                    {fieldType === 'select' ? (
                      <SelectDropdown
                        fieldName={fieldName}
                        register={register}
                        options={
                          fieldName === 'referralType'
                            ? referralType
                            : referralMode
                        }
                        validationObj={validationObj}
                        errors={errors}
                        disabled={location?.state?.isView}
                        val={getValues(
                          fieldName === 'referralType'
                            ? 'referralType'
                            : 'referralMode'
                        )}
                      />
                    ) : fieldName === 'referralContact' ? (
                      <>
                        {/* <label className="block text-gray-800 text-sm font-bold mb-3 pr-4">
                          {fieldTitle}
                        </label> */}
                        <PhoneInput
                          className="shadow appearance-none border border-solid border-gray-700 rounded-xl w-full py-2.5 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-blue-500 disabled:bg-gray-200"
                          country="IN"
                          name={fieldName}
                          defaultValue={getValues(fieldName)}
                          control={control}
                          placeholder="Ref Phone Number"
                          onBlur={handleBlur}
                        />
                      </>
                    ) : (
                      <InputField
                        fieldName={fieldName}
                        validationObj={validationObj}
                        register={register}
                        errors={errors}
                        fieldType={fieldType}
                        disabled={location?.state?.isView}
                        value={getValues(fieldName)}
                      />
                    )}
                  </Grid>
                );
              })}
          </Grid>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <div
                className="flex justify-between items-center text-white rounded-md bg-gray-700 p-2 mb-1 cursor-pointer"
                onClick={assignedToggleAccordion} // Toggle on click
              >
                <h2>Assigned To</h2>
                {/* Render the icon based on the toggle state */}
                {isAssignedToggle ? <FaChevronUp /> : <FaChevronDown />}
              </div>
            </Grid>
            {isAssignedToggle &&
              approvalActionFields.map((field) => {
                const {
                  fieldTitle,
                  fieldName,
                  fieldType,
                  validationObj,
                  options,
                } = field;

                return (
                  <Grid item xs={6} key={fieldName}>
                    <label className="block text-gray-800 font-bold mb-3 pr-4">
                      {fieldTitle}
                    </label>

                    {fieldType === 'select' ? (
                      <SelectDropdown
                        fieldName={fieldName}
                        register={register}
                        options={
                          fieldName === 'priority' ? priority : assignedTo
                        }
                        validationObj={validationObj}
                        errors={errors}
                        disabled={
                          task?.ticketStatus === 'rejected'
                            ? true
                            : location?.state?.isView || disabled
                        }
                        val={getValues(fieldName) || assignedToValue}
                        onChange={(e) => setAssignedToValue(e.target.value)}
                      />
                    ) : (
                      <InputField
                        fieldName={fieldName}
                        validationObj={validationObj}
                        register={register}
                        errors={errors}
                        fieldType={fieldType}
                        disabled={
                          task?.ticketStatus === 'rejected'
                            ? true
                            : true || disabled
                        }
                        value={getValues(fieldName)}
                      />
                    )}
                  </Grid>
                );
              })}
          </Grid>
          <ApplicationTabs
            taskId={id}
            ticketNo={ticketNo}
            isView={
              task?.ticketStatus === 'rejected'
                ? true
                : location?.state?.isView || disabled
            }
            task={task}
          />
          {!location?.state?.isView ? submitBtns() : null}
        </form>
      </div>
    </>
  );
};

export default Form;
