import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';


function NavigationTab({ heading, navTabs, tab_1, tab_2, tab_3 }) {
  const [toggleState, setToggleState] = useState(1);
  const navigate = useNavigate();

  const toggleTab = (index) => {
    setToggleState(index);
  };

  return (
    <div className="block rounded-lg bg-white p-6 shadow-[0_2px_15px_-3px_rgba(0,0,0,0.07),0_10px_20px_-2px_rgba(0,0,0,0.04)] overflow-y-auto">
      {/* <h5 className='mb-2 text-xl font-bold leading-tight text-neutral-800 dark:text-neutral-50'>
        {heading}
      </h5> */}
      <div className="flex text-[8px] md:text-xs">
        {navTabs &&
          navTabs.map((tab) => (
            <button
              key={tab.index}
              className={
                toggleState === tab.index ? 'tabs active-tabs' : 'tabs'
              }
              onClick={() => toggleTab(tab.index)}
            >
              {tab.title}
            </button>
          ))}
      </div>
      <div className="content-tabs">
        <div
          className={toggleState === 1 ? 'content  active-content' : 'content'}
        >
          {tab_1}
        </div>
        <div
          className={toggleState === 2 ? 'content  active-content' : 'content'}
        >
          {tab_2}
        </div>
        <div
          className={toggleState === 3 ? 'content  active-content' : 'content'}
        >
          {tab_3}
        </div>
      </div>
    </div>
  );
}

export default NavigationTab;
