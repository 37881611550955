import React from "react";
import { FaRegTrashAlt } from "react-icons/fa";

const UploadField = ({
  fieldId,
  fieldName,
  register,
  validationObj,
  placeholder,
  onChange,
  onBlur,
  className = "appearance-none rounded w-full py-2 px-4 text-gray-700 border-primary-300 leading-tight focus:outline-none focus:bg-white focus:border-primary-300 disabled:bg-gray-200",
  valueClassName = "flex justify-around items-center",
  errors,
  disabled,
  value,
  onRemoveFile,
}) => {
  console.log("fileValue", value);
  return (
    <>
      <input
        id={fieldId}
        name={fieldName}
        {...register(fieldName, {
          onChange: (e) => onChange && onChange(e),
          onBlur: (e) => onBlur && onBlur(e),
          ...validationObj,
        })}
        type="file"
        className={className}
        placeholder={placeholder}
        disabled={disabled}
      />
      {value && (
        <div className={valueClassName}>
          <div>{value}</div>
          <div
            className="cursor-pointer hover:text-danger ml-2"
            onClick={onRemoveFile}
          >
            <FaRegTrashAlt />
          </div>
        </div>
      )}

      <p className="py-2 text-danger">
        {errors[fieldName] && errors[fieldName].message}
      </p>
    </>
  );
};

export default UploadField;
