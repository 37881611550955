export const notesFields = [
  {
    fieldName: 'note',
    fieldTitle: 'Add Note',
    fieldType: 'textarea',
    validationObj: {
      required: 'Please Enter Note',
    },
  },
  {
    fieldName: 'status',
    fieldTitle: 'Note Type',
    fieldType: 'select',
    validationObj: {
      required: 'Please Select Note Type',
    },
    options: [
      { label: 'In Progress', value: 'inprogress' },
      { label: 'Approved', value: 'approved' },
      { label: 'Rejected', value: 'rejected' },
      { label: 'Resolved', value: 'resolved' },
    ],
  },
  {
    fieldName: 'fileUpload',
    fieldTitle: 'Upload File',
    fieldType: 'upload',
  },
];
